import React from 'react';
import { Country, parsePhoneNumber } from 'react-phone-number-input';

import { VendorReview } from '@models/vendor-review.model';

export interface Props {
  review: VendorReview;
  CSSBlock: string;
}

const ReviewCustomer: React.FC<Props> = ({ review, CSSBlock }) => {
  return (
    <div className={`${CSSBlock}__customer`}>
      <div className={`${CSSBlock}__title`}>Customer Information</div>
      <div className={`${CSSBlock}__customer-name`}>
        {review.customer.title} {review.customer.firstName}{' '}
        {review.customer.lastName}
      </div>
      <div className={`${CSSBlock}__customer-number`}>
        {parsePhoneNumber(
          review.customer.contactNumber,
          review.customer?.address?.countryCode as Country
        )?.formatInternational()}
      </div>
    </div>
  );
};

export default ReviewCustomer;
