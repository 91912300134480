import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';

import { EMPTY_CUSTOMER, FSUserDoc } from '@models/customer.model';
import { FSOrderReview, VendorReview } from '@models/vendor-review.model';

import { getDateFromFirestore } from '../networkService';

export const parseToVendorReview = (
  doc: QueryDocumentSnapshot<FSOrderReview>,
  customer: FSUserDoc
): VendorReview => {
  const review = doc.data();
  const timestamp = getDateFromFirestore(
    doc.data()['timestamp'] as DocumentData
  );
  return {
    ...review,
    id: doc.id,
    timestamp: timestamp,
    customer,
  };
};

export const getReviewCustomerFromRequests = (
  customerRequests: {
    [key: string]: Promise<FSUserDoc | undefined>;
  },
  customers: (FSUserDoc | undefined)[],
  customerID: string
): FSUserDoc => {
  const customerIndex = Object.keys(customerRequests).indexOf(customerID);
  return !!customers[customerIndex]
    ? customers[customerIndex]!
    : EMPTY_CUSTOMER;
};
