import { doc, query, where } from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';

export const refundRequestsQuery = (vendorID: string) =>
  query(FSCollections.RefundRequests, where('vendorID', '==', vendorID));

export const refundRequestQuery = (requestID: string) =>
  doc(FSCollections.RefundRequests, requestID);

export const hasRefundRequestQuery = (orderDocID: string, vendorID: string) =>
  query(
    FSCollections.RefundRequests,
    where('vendorID', '==', vendorID),
    where('orderDocID', '==', orderDocID)
  );
