import React from 'react';

import { updateOrderReviewResponse } from '@core/services/networkService/orders/orders';
import DemmiCard from '@demmi-ui/Card/Card';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { demmiRequest } from '@helpers/app.helper';
import {
  FSOrderReviewResponse,
  VendorReview,
} from '@models/vendor-review.model';
import { Vendor } from '@models/vendors.model';

import ReviewCustomer from './ReviewCustomer';
import ReviewResponse from './ReviewResponse';

export interface Props {
  vendor: Vendor;
  review: VendorReview;
  onUpdateReviewResponse: (
    reviewID: string,
    response: FSOrderReviewResponse
  ) => void;
}

const ReviewItem: React.FC<Props> = ({
  vendor,
  review,
  onUpdateReviewResponse,
}) => {
  const CSSBlock = 'review-item';

  const updateResponse = (response: string) => {
    const res: FSOrderReviewResponse = {
      body: response.split('\n'),
      timestamp: new Date(),
    };
    demmiRequest(updateOrderReviewResponse(vendor.id, review.id, res)).then(
      () => {
        onUpdateReviewResponse(review.id, res);
      }
    );
  };

  return (
    <div className={`${CSSBlock}__wrapper`}>
      <DemmiCard noPadding className={CSSBlock}>
        <div className={`${CSSBlock}__header`}>
          <div className={`${CSSBlock}__timestamp`}>
            Reviewed on:
            <span>{review.timestamp?.toDateString()}</span>
          </div>

          <div className={`${CSSBlock}__rating`}>
            {[...Array(5)].map((_, i) => (
              <FontAwesomeIcon
                icon={
                  review!.rating < i
                    ? icon({ style: 'light', name: 'star' })
                    : icon({ style: 'solid', name: 'star' })
                }
              />
            ))}
          </div>
        </div>

        <div className={`${CSSBlock}__review`}>
          <div className={`${CSSBlock}__review-title`}>{review.title}</div>
          <div className={`${CSSBlock}__review-description`}>
            {review.body.map((paragraph, i) => (
              <div
                className={`${CSSBlock}__review-description-paragraph`}
                key={i}>
                {paragraph}
              </div>
            ))}
          </div>
        </div>

        <ReviewCustomer CSSBlock={CSSBlock} review={review} />
        <ReviewResponse
          CSSBlock={CSSBlock}
          review={review}
          onUpdateResponse={response => updateResponse(response)}
        />
      </DemmiCard>
    </div>
  );
};

export default ReviewItem;
