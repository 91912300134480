import React from 'react';

import { getCurrencySymbolFromCurrencyCode } from '@helpers/currency.helper';
import { RefundType } from '@helpers/refundRequest.helper';
import { RefundRequest } from '@models/refundRequest.model';

interface Props {
  CSSBlock: string;
  productIDs: string[];
  refundRequest: RefundRequest;
}

const OrderItemTabRefundCost: React.FC<Props> = ({
  CSSBlock,
  productIDs,
  refundRequest,
}) => {
  return (
    <div className={`${CSSBlock}__costing`}>
      <div className={`${CSSBlock}__key-val`}>
        <div className={`${CSSBlock}__key`}>
          {refundRequest.type === RefundType.PARTIAL_VENDOR
            ? `${productIDs.length} product${productIDs.length > 1 ? 's' : ''}`
            : 'All products'}{' '}
          included
        </div>
        <div className={`${CSSBlock}__value`}>
          {getCurrencySymbolFromCurrencyCode(
            refundRequest.costEstimate.currencyCode
          )}
          {Number(refundRequest.costEstimate.productsCost).toFixed(2)}
        </div>
      </div>
      {!!refundRequest.costEstimate.couponDeduction && (
        <div className={`${CSSBlock}__key-val`}>
          <div className={`${CSSBlock}__key`}>Coupon deductions</div>
          <div className={`${CSSBlock}__value`}>
            {getCurrencySymbolFromCurrencyCode(
              refundRequest.costEstimate.currencyCode
            )}
            {Number(refundRequest.costEstimate.couponDeduction).toFixed(2)}
          </div>
        </div>
      )}
      <div className={`${CSSBlock}__key-val`}>
        <div className={`${CSSBlock}__key`}>Total refund value</div>
        <div className={`${CSSBlock}__value`}>
          {getCurrencySymbolFromCurrencyCode(
            refundRequest.costEstimate.currencyCode
          )}
          {Number(refundRequest.costEstimate.totalRefund).toFixed(2)}
        </div>
      </div>
    </div>
  );
};

export default OrderItemTabRefundCost;
