import React from 'react';

import DemmiInput from '@demmi-ui/Input';

interface Props {
  CSSBlock: string;
  currencySymbol: string;
  minSpend: number;
  setMinSpend: React.Dispatch<React.SetStateAction<number>>;
  discount: number;
  setDiscount: React.Dispatch<React.SetStateAction<number>>;
}

const ModalCreateCouponContentFixed: React.FC<Props> = ({
  CSSBlock,
  currencySymbol,
  minSpend,
  setMinSpend,
  discount,
  setDiscount,
}) => {
  return (
    <>
      <div className={`${CSSBlock}__details-item`}>
        <div className={`${CSSBlock}__details-title`}>Minimum spend</div>
        <div className={`${CSSBlock}__details-input`}>
          <div className={`${CSSBlock}__details-text`}>{currencySymbol}</div>
          <DemmiInput
            type="number"
            placeholder={`e.g. ${currencySymbol}20.00`}
            value={minSpend.toString()}
            onChangeValue={val => setMinSpend(parseInt(val))}
          />
        </div>
      </div>
      <div className={`${CSSBlock}__details-item`}>
        <div className={`${CSSBlock}__details-title`}>Discount amount</div>
        <div className={`${CSSBlock}__details-input`}>
          <div className={`${CSSBlock}__details-text`}>{currencySymbol}</div>
          <DemmiInput
            type="number"
            placeholder={`e.g. ${currencySymbol}4.00`}
            min="0"
            max="50"
            value={discount.toString()}
            onChangeValue={val => setDiscount(parseInt(val))}
          />
        </div>
      </div>
    </>
  );
};

export default ModalCreateCouponContentFixed;
