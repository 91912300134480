import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { getCurrencySymbolFromCurrencyCode } from '@helpers/currency.helper';
import { formatCollectionDate, timeToString } from '@helpers/date.helper';
import { getStatusFromOrder } from '@helpers/orderStatus.helper';
import { getTotalOrderCost } from '@helpers/orders.helper';
import { Order } from '@models/orders.model';

interface Props {
  order: Order;
  active: boolean;
  onClick: (order: Order) => void;
}

const OrderListItem: React.FC<Props> = ({ order, active, onClick }) => {
  const CSSBlock = 'order-list-item';
  const vendor = useAppSelector(selectVendor);
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (vendor) setStatus(getStatusFromOrder(vendor.id, order));
  }, [vendor, order]);

  return (
    <>
      {order && (
        <button
          className={`${CSSBlock} ${active ? `${CSSBlock}--active` : ''}`}
          onClick={() => onClick(order)}>
          {<div className={`${CSSBlock}__status`}>{status}</div>}
          <div className={`${CSSBlock}__key-val-row`}>
            <div className={`${CSSBlock}__key-val`}>
              <div className={`${CSSBlock}__key`}>Collection Date</div>
              <div className={`${CSSBlock}__value`}>
                {order.vendor.collectionDate
                  ? formatCollectionDate(order.vendor.collectionDate)
                  : 'No date given'}
              </div>
            </div>
            <div className={`${CSSBlock}__key-val`}>
              <div className={`${CSSBlock}__key`}>Collection Time</div>
              <div className={`${CSSBlock}__value`}>
                {order.vendor.collectionTime
                  ? `${timeToString(
                      order.vendor.collectionTime
                    )} - ${timeToString(order.vendor.collectionTime + 30)}`
                  : 'No time given'}
              </div>
            </div>
          </div>
          <div className={`${CSSBlock}__key-val-row`}>
            <div className={`${CSSBlock}__key-val`}>
              <div className={`${CSSBlock}__key`}>Order Date</div>
              <div className={`${CSSBlock}__value`}>
                {format(order.orderDate, 'dd MMM yyyy, HH:mm:ss')}
              </div>
            </div>
            <div className={`${CSSBlock}__key-val`}>
              <div className={`${CSSBlock}__key`}>Total Order Cost</div>
              <div className={`${CSSBlock}__value`}>
                {getCurrencySymbolFromCurrencyCode(
                  order.vendor.cost.currencyCode
                )}
                {Number(getTotalOrderCost(order.vendor)).toFixed(2)}
              </div>
            </div>
          </div>
        </button>
      )}
    </>
  );
};

export default OrderListItem;
