import React, { HTMLProps, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren, HTMLProps<HTMLDivElement> {
  isDark?: boolean;
}

const DemmiModalArea: React.FC<Props> = ({ children, isDark, ...props }) => {
  const CSSBlock = 'demmi-ui-modal';

  return (
    <div
      {...props}
      className={`${CSSBlock}__area ${
        isDark ? `${CSSBlock}__area--dark` : `${CSSBlock}__area--light`
      } ${props.className ? props.className : ``}`}>
      {children}
    </div>
  );
};

export default DemmiModalArea;
