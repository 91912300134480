/**
 * Notification Type
 */
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FSNotificationDoc } from '@models/notifications.model';

export enum NotificationType {
  ORDER_NEW = 'order-new',
  ORDER_UPDATE = 'order-update',
  REFUND_REQUEST = 'refund-request',
  REFUND_UPDATE = 'refund-update',
  UNKNOWN = '',
}

export const getNotificationType = (type: string): NotificationType => {
  const match = Object.entries(NotificationType).find(
    ([_, val]) => val === type
  );
  return match ? match[1] : NotificationType.UNKNOWN;
};

export const getNotificationTypeText = (type: NotificationType): string => {
  switch (type) {
    case NotificationType.ORDER_NEW:
      return 'New Order';
    case NotificationType.ORDER_UPDATE:
      return 'Order Update';
    case NotificationType.REFUND_REQUEST:
      return 'Refund Request';
    case NotificationType.REFUND_UPDATE:
      return 'Refund Update';
    default:
      return '-';
  }
};
export const getNotificationTypeIcon = (
  type: NotificationType
): IconDefinition => {
  switch (type) {
    case NotificationType.ORDER_NEW:
      return icon({ style: 'light', name: 'basket-shopping' });
    case NotificationType.ORDER_UPDATE:
      return icon({ style: 'light', name: 'money-bill-transfer' });
    case NotificationType.REFUND_REQUEST:
      return icon({ style: 'light', name: 'money-bill-transfer' });
    case NotificationType.REFUND_UPDATE:
      return icon({ style: 'light', name: 'money-bill-transfer' });
    default:
      return icon({ style: 'light', name: 'money-bill-transfer' });
  }
};

export const notificationToToast = (
  notification: FSNotificationDoc
): { title: string; body: string } | undefined => {
  switch (notification.type) {
    case NotificationType.REFUND_REQUEST:
      return { title: `New Refund Request`, body: notification.message };
    case NotificationType.REFUND_UPDATE:
      return {
        title: `Update available for refund request`,
        body: notification.message,
      };
    case NotificationType.ORDER_NEW:
      return { title: `Incoming Order!`, body: notification.message };
    case NotificationType.ORDER_UPDATE:
      return { title: `Order Update!`, body: notification.message };
    default:
      return;
  }
};
