import {
  DocumentChange,
  QueryDocumentSnapshot,
  Unsubscribe,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';

import {
  FSNotificationDoc,
  FSNotificationModel,
} from '@models/notifications.model';
import { FSCollections } from '@providers/firestoreProvider';
import { DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { parseToNotification } from './_helper';
import {
  notificationQuery,
  notificationsQuery,
  toastNotificationsQuery,
} from './_queries';

export const listenToNotifications = async (
  vendorID: string,
  callback: (notifications: FSNotificationDoc[]) => void
): Promise<Unsubscribe> => {
  Logger({ objs: { vendorID } }, listenToNotifications);
  return onSnapshot(notificationsQuery(vendorID), querySnapshot => {
    let notifications: FSNotificationDoc[] = [];
    querySnapshot.forEach((doc: QueryDocumentSnapshot<FSNotificationModel>) => {
      notifications.push(parseToNotification(doc));
    });
    callback(notifications);
  });
};

export const listenToToastNotifications = async (
  vendorID: string,
  currentTimestamp: Date,
  callback: (notifications: FSNotificationDoc[]) => void
): Promise<Unsubscribe> => {
  return onSnapshot(
    toastNotificationsQuery(vendorID, currentTimestamp),
    querySnapshot => {
      let newNotifications: FSNotificationDoc[] = [];
      querySnapshot
        .docChanges()
        .forEach((change: DocumentChange<FSNotificationModel>) => {
          if (change.type === 'added') {
            const notification = parseToNotification(change.doc);
            newNotifications.push(notification);
          }
        });
      callback(newNotifications);
    }
  );
};

export const updateNotificationReadStatus = async (
  notificationID: string,
  read: boolean
): Promise<void> => {
  const notification = await getDoc(notificationQuery(notificationID));
  if (!notification) {
    Logger(
      {
        messages: ['Failed to find notification to push read update.'],
        objs: { notificationID, read },
        type: DemmiLogType.error,
      },
      updateNotificationReadStatus
    );
    return;
  }

  const docRef = doc(FSCollections.Notifications, notificationID);
  return updateDoc(docRef, {
    read: read,
  });
};
export const updateNotificationDismissedStatus = async (
  notificationID: string,
  dismissed: boolean
): Promise<void> => {
  const notification = await getDoc(notificationQuery(notificationID));
  if (!notification) {
    Logger(
      {
        messages: ['Failed to find notification to push dismissed update.'],
        objs: { notificationID, dismissed },
        type: DemmiLogType.error,
      },
      updateNotificationDismissedStatus
    );
    return;
  }

  const docRef = doc(FSCollections.Notifications, notificationID);
  return updateDoc(docRef, {
    dismissed: dismissed,
  });
};
