import React from 'react';

import { DemmiSupportChat } from '@models/demmiSupport.model';

import DemmiSupportPanelListItem from './_listItem';

interface Props {
  selectedChatID?: string;
  chats: DemmiSupportChat[];
  onClickChat: (chat: DemmiSupportChat) => void;
}

const DemmiSupportPanelList: React.FC<Props> = ({
  selectedChatID,
  chats,
  onClickChat,
}) => {
  const CSSBlock = 'demmi-support-panel-list';

  return (
    <div className={`${CSSBlock}`}>
      {chats.map((chat, i) => (
        <DemmiSupportPanelListItem
          CSSBlock={CSSBlock}
          active={chat.id === selectedChatID}
          chat={chat}
          key={i}
          onClickChat={onClickChat}
          lastMessage={chat.lastMessage}
        />
      ))}
    </div>
  );
};

export default DemmiSupportPanelList;
