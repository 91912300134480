import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { updateVendorHours } from '@core/services/networkService/functions/_vendors';
import { selectVendor, setVendor } from '@core/store/appSlice/appSlice';
import DemmiButton from '@demmi-ui/Button';
import { demmiRequest } from '@helpers/app.helper';
import {
  isVendorOpeningHoursValid,
  vendorOpeningHoursHasChanges,
} from '@helpers/vendors.helper';
import {
  EmptyOpeningHours,
  Vendor,
  VendorOpeningHours,
} from '@models/vendors.model';

import { CSSBLOCK_SETTINGS } from '../Settings';
import Hours from './Hours';

interface Props {}

const SettingsOpeningHours: React.FC<Props> = ({}) => {
  const CSSBlock = 'settings-opening-hours';
  const dispatch = useAppDispatch();
  const vendor = useAppSelector(selectVendor);
  const [hours, setHours] = useState<VendorOpeningHours>(EmptyOpeningHours);
  const [detectedChanges, setDetectedChanges] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const setValues = (vendor: Vendor) => {
    setHours(vendor.shop?.openingHours);
  };

  useEffect(() => {
    if (vendor) setValues(vendor);
  }, [vendor]);

  useEffect(() => {
    if (vendor) {
      setDetectedChanges(
        vendorOpeningHoursHasChanges(vendor.shop.openingHours, hours)
      );
      setIsValid(isVendorOpeningHoursValid(hours));
    }
  }, [hours]);

  const discardChanges = () => {
    if (vendor) setValues(vendor);
  };

  const saveChanges = async () => {
    if (isValid && vendor) {
      const f: Vendor = {
        ...vendor,
        shop: {
          ...vendor.shop,
          openingHours: hours,
        },
      };
      demmiRequest(updateVendorHours(f)).then(() => dispatch(setVendor(f)));
    }
  };

  return (
    <div className={`${CSSBLOCK_SETTINGS}__content-wrapper ${CSSBlock}`}>
      <Hours CSSBlock={CSSBlock} hours={hours} setHours={setHours} />

      <div className={`${CSSBlock}__buttons`}>
        <span style={{ alignSelf: 'center', display: 'flex', gap: '1rem' }}>
          <DemmiButton
            text={'Discard Changes'}
            faIcon="fa-ban"
            disabled={!detectedChanges}
            onClick={() => discardChanges()}
          />
          <DemmiButton
            text={'Save Changes'}
            faIcon="fa-regular fa-floppy-disk"
            disabled={!detectedChanges || !isValid}
            onClick={() => saveChanges()}
          />
        </span>
      </div>
    </div>
  );
};

export default SettingsOpeningHours;
