import React, { useState } from 'react';

import { updateOrderReviewResponse } from '@core/services/networkService/orders/orders';
import DemmiButton from '@demmi-ui/Button';
import DemmiIconButton from '@demmi-ui/IconButton';
import DemmiTextArea from '@demmi-ui/TextArea';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { demmiRequest } from '@helpers/app.helper';
import {
  FSOrderReview,
  FSOrderReviewResponse,
} from '@models/vendor-review.model';

interface Props {
  CSSBlock: string;
  review?: FSOrderReview;
  vendorID: string;
}

const OrderItemTabReview: React.FC<Props> = ({
  CSSBlock,
  review,
  vendorID,
}) => {
  const [response, setResponse] = useState<string>(
    review?.response ? review.response.body.join('\n') : ''
  );
  const [editMode, setEditMode] = useState(false);

  const updateResponse = (reviewID: string, response: string) => {
    const res: FSOrderReviewResponse = {
      body: response.split('\n'),
      timestamp: new Date(),
    };
    demmiRequest(updateOrderReviewResponse(vendorID, reviewID, res)).then(
      () => {}
    );
  };

  return review ? (
    <div className={`${CSSBlock}__review`}>
      <div className={`${CSSBlock}__review-timestamp`}>
        Reviewed on:
        <span>{review.timestamp?.toDateString()}</span>
      </div>
      <div className={`${CSSBlock}__review-title`}>{review.title}</div>
      <div className={`${CSSBlock}__review-description`}>
        {review.body.map((paragraph, i) => (
          <div className={`${CSSBlock}__review-description-paragraph`} key={i}>
            {paragraph}
          </div>
        ))}
      </div>
      <div className={`${CSSBlock}__review-rating`}>
        {[...Array(5)].map((_, i) => (
          <FontAwesomeIcon
            icon={
              review!.rating < i
                ? icon({ style: 'light', name: 'star' })
                : icon({ style: 'solid', name: 'star' })
            }
          />
        ))}
      </div>
      <div className={`${CSSBlock}__review-response`}>
        {editMode ? (
          <>
            <div className={`${CSSBlock}__review-response-title`}>
              Your response
              <div className={`${CSSBlock}__review-response-title-buttons`}>
                <DemmiIconButton
                  variant="delete"
                  icon={icon({ style: 'regular', name: 'cancel' })}
                  onClick={() => {
                    setResponse(review.response?.body.join('\n') ?? '');
                    setEditMode(false);
                  }}
                />
                <DemmiIconButton
                  variant="save"
                  icon={icon({ style: 'regular', name: 'save' })}
                  onClick={() => {
                    updateResponse(review.id, response);
                    setEditMode(false);
                  }}
                />
              </div>
            </div>
            <DemmiTextArea
              placeholder="Your response"
              value={response}
              maxLength={1000}
              maxHeight={24}
              onChange={val => setResponse(val)}
            />
          </>
        ) : review.response ? (
          <>
            <div className={`${CSSBlock}__review-response-title`}>
              Your response
              <DemmiIconButton
                icon={icon({ style: 'regular', name: 'edit' })}
                onClick={() => setEditMode(!editMode)}
              />
            </div>
            {review.response.body.map((paragraph, i) => (
              <div className={`${CSSBlock}__review-description-paragraph`}>
                {paragraph}
              </div>
            ))}
          </>
        ) : (
          <div className={`${CSSBlock}__review-no-response`}>
            You've not yet responded to this review.
            <DemmiButton
              text="Respond now"
              faIcon="fa-edit"
              onClick={() => setEditMode(true)}
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className={`${CSSBlock}__review-none`}>
      Not yet reviewed by customer
    </div>
  );
};

export default OrderItemTabReview;
