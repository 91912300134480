import React, {
  PropsWithChildren,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import ReactPortal from '../ReactPortal';

const CSSBlock = 'demmi-ui-modal';

export type DemmiModalCoreRef = {
  gracefulClose: (postExit: () => void) => void;
};

interface DemmiModalCoreProps extends PropsWithChildren {
  isOpen: boolean;
  onExited: () => void;
  allowBackdropClose?: boolean;
  contentClassName?: string;
}

const DemmiModalCore = React.forwardRef<DemmiModalCoreRef, DemmiModalCoreProps>(
  (
    { children, isOpen, onExited, contentClassName, allowBackdropClose = true },
    ref
  ) => {
    if (!isOpen) return null;
    const [modalClass, setModalClass] = useState('');

    useImperativeHandle(ref, () => ({
      gracefulClose(postExit: () => void) {
        setModalClass('');
        setTimeout(() => postExit(), 300);
      },
    }));

    const onClose = () => {
      setModalClass('');
      setTimeout(() => onExited(), 300);
    };

    useEffect(() => {
      requestAnimationFrame(() => setModalClass(`${CSSBlock}--visible`));
    }, []);

    useEffect(() => {
      const onKeyDown = (e: globalThis.KeyboardEvent) => {
        if (e.key === 'Escape') onClose();
      };
      document.addEventListener('keydown', onKeyDown);
      return () => document.removeEventListener('keydown', onKeyDown);
    }, []);

    return (
      <ReactPortal wrapperId="modal-container">
        <div className={`${CSSBlock} ${modalClass}`}>
          <div
            className={`${CSSBlock}__backdrop`}
            onClick={() => {
              if (allowBackdropClose) onClose();
            }}></div>
          <div className={`${CSSBlock}__content`}>
            <div
              className={`${CSSBlock}__content-wrapper ${
                contentClassName ? contentClassName : ''
              }`}>
              {children}
            </div>
          </div>
        </div>
      </ReactPortal>
    );
  }
);

export default DemmiModalCore;
