import React, { useEffect, useState } from 'react';

interface Props {
  app: number;
  setApp: React.Dispatch<React.SetStateAction<number>>;
}

const FakePhoneAppSwitcher: React.FC<Props> = ({ app, setApp }) => {
  const CSSBlock = 'app-switcher';

  return (
    <div className="app-switcher">
      <div
        className={`app-switcher__button ${
          app == 0 ? `app-switcher__button--active` : ''
        }`}
        onClick={() => setApp(0)}>
        <span className={`app-switcher__icon`}>
          <i className={`fa-solid fa-shop`}></i>
        </span>
        Your Shop
      </div>
      <div
        className={`app-switcher__button ${
          app == 1 ? `app-switcher__button--active` : ''
        }`}
        onClick={() => setApp(1)}>
        <span className={`app-switcher__icon`}>
          <i className={`fa-solid fa-tag`}></i>
        </span>
        Product Page
      </div>
    </div>
  );
};

export default FakePhoneAppSwitcher;
