import React, { useEffect, useState } from 'react';

import Logo from '../../assets/demmi-logo.svg';

interface LoadingIntroProps {
  onAnimStart?: () => void;
  onAnimEnd: () => void;
}

const LoadingIntro: React.FC<LoadingIntroProps> = ({
  onAnimStart,
  onAnimEnd,
}) => {
  const CSSBlock = 'loading-overlay';
  const [hide, setHide] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      onAnimStart && onAnimStart();
      setHide(true);
      setTimeout(() => onAnimEnd(), 800);
    }, 1200);
  }, []);

  return (
    <div
      className={`${CSSBlock} ${CSSBlock}--animated ${
        hide ? `${CSSBlock}--hide` : ''
      }`}>
      <div className={`${CSSBlock}__logo`}>
        <div className={`${CSSBlock}__loading-indicator`}></div>
        <div className={`${CSSBlock}__title`}>
          <img src={Logo} />
        </div>
        <div className={`${CSSBlock}__subtitle`}>
          <strong>The</strong> Natural Marketplace
        </div>
      </div>
    </div>
  );
};

export default LoadingIntro;
