import React, { useEffect, useState } from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import { RegionAddress } from '@helpers/address.helper';
import { DefaultLatLng, mapPlaceToAddress } from '@helpers/maps.helper';
import { VendorAddress } from '@models/vendors.model';
import { DemmiLogType, Logger } from '@subhanhabib/demmilib';
import {
  APIProvider,
  ControlPosition,
  Map,
  Marker,
} from '@vis.gl/react-google-maps';

import { CustomMapControl } from './Maps/MapControl';
import MapHandler from './Maps/MapHandler';

interface Props {
  CSSBlock: string;
  regionAddress: RegionAddress;
  setVendorAddress: React.Dispatch<
    React.SetStateAction<VendorAddress | undefined>
  >;
}

const GeoLocationMap: React.FC<Props> = ({
  CSSBlock,
  regionAddress,
  setVendorAddress,
}) => {
  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);

  useEffect(() => {
    applyPlace();
  }, [selectedPlace]);

  const applyPlace = () => {
    if (!selectedPlace) return;

    const updatedVendorAddress = mapPlaceToAddress(
      regionAddress.countryCode,
      selectedPlace
    );
    if (!updatedVendorAddress) {
      Logger(
        {
          messages: ['Failed to get updatedVendorAddress'],
          objs: { selectedPlace },
          type: DemmiLogType.error,
        },
        applyPlace
      );
      return;
    }

    Logger({ objs: { updatedVendorAddress } }, applyPlace);
    setVendorAddress(updatedVendorAddress);
  };

  return (
    <DemmiCard title="Map Location" className={`${CSSBlock}__geo`}>
      <APIProvider apiKey={process.env.DEMMI_GC_MAPS_API_KEY}>
        <Map
          style={{ borderRadius: '10px', overflow: 'hidden' }}
          defaultCenter={{ lat: DefaultLatLng[0], lng: DefaultLatLng[1] }}
          defaultZoom={14}
          gestureHandling={'greedy'}
          disableDefaultUI={true}>
          <CustomMapControl
            controlPosition={ControlPosition.TOP_LEFT}
            onPlaceSelect={setSelectedPlace}
          />
          {selectedPlace && selectedPlace.geometry?.location && (
            <Marker
              position={{
                lat: selectedPlace.geometry.location.lat(),
                lng: selectedPlace.geometry.location.lng(),
              }}
            />
          )}
        </Map>
        <MapHandler place={selectedPlace} />
      </APIProvider>
    </DemmiCard>
  );
};

export default GeoLocationMap;
