import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import DemmiCardDivider from '@demmi-ui/Card/CardDivider';
import DemmiInput from '@demmi-ui/Input';
import DemmiModalArea from '@demmi-ui/Modal/ModalArea';
import DemmiToggle from '@demmi-ui/Toggle';
import { isCouponDatesValid } from '@helpers/coupon.helper';
import { Coupon } from '@models/coupons.model';

interface Props {
  coupon: Coupon;
  state: number;
  CSSBlock: string;
  setCoupon: React.Dispatch<React.SetStateAction<Coupon | undefined>>;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalEditCouponContent: React.FC<Props> = ({
  coupon,
  state,
  CSSBlock,
  setCoupon,
  setIsValid,
}) => {
  const [hasStartDate, setHasStartDate] = useState(!!coupon.startDate);
  const [hasExpirationDate, setHasExpirationDate] = useState(
    !!coupon.expirationDate
  );
  const [startDate, setStartDate] = useState<string>(
    coupon.startDate
      ? DateTime.fromJSDate(coupon.startDate).toFormat('yyyy-MM-dd')
      : ''
  );
  const [expirationDate, setExpirationDate] = useState<string>(
    coupon.expirationDate
      ? DateTime.fromJSDate(coupon.expirationDate).toFormat('yyyy-MM-dd')
      : ''
  );

  useEffect(() => {
    setHasStartDate(!!startDate);
    setHasExpirationDate(!!expirationDate);
  }, [startDate, expirationDate]);

  useEffect(() => {
    setIsValid(
      isCouponDatesValid(
        hasStartDate ? startDate : undefined,
        hasExpirationDate ? expirationDate : undefined
      )
    );
    setCoupon(updatedCoupon());
  }, [hasStartDate, startDate, hasExpirationDate, expirationDate]);

  const updatedCoupon = (): Coupon => ({
    ...coupon,
    startDate: hasStartDate ? new Date(`${startDate} 00:00:00`) : undefined,
    expirationDate: hasExpirationDate
      ? new Date(`${expirationDate} 23:59:59`)
      : undefined,
  });

  return (
    <>
      <DemmiModalArea isDark>
        <div className={`${CSSBlock}__title`}>Modify your coupon dates</div>
        <div className={`${CSSBlock}__content`}>
          <DemmiCard>
            <div className={`${CSSBlock}__code`}>{coupon.code}</div>

            {state === -1 && (
              <div className={`${CSSBlock}__error`}>
                Failed to update coupon.
              </div>
            )}

            <DemmiCardDivider />
            <div className={`${CSSBlock}__toggle-item`}>
              <DemmiToggle
                isActive={hasStartDate}
                text="Start Date"
                onToggle={v => setHasStartDate(v)}
              />
              {hasStartDate && (
                <>
                  <DemmiInput
                    type="date"
                    value={startDate}
                    onChangeValue={val => setStartDate(val)}
                  />
                  <br />
                  <br />
                </>
              )}
            </div>
            <div className={`${CSSBlock}__toggle-item`}>
              <DemmiToggle
                isActive={hasExpirationDate}
                text="Expiration Date?"
                onToggle={v => setHasExpirationDate(v)}
              />
              {hasExpirationDate && (
                <DemmiInput
                  type="date"
                  value={expirationDate}
                  onChangeValue={val => setExpirationDate(val)}
                />
              )}
            </div>
          </DemmiCard>
        </div>
      </DemmiModalArea>
    </>
  );
};

export default ModalEditCouponContent;
