export interface FSUserDoc extends FSUserModel {
  docID: string;
}

export interface FSUserModel {
  title: string;
  firstName: string;
  lastName: string;
  nickname: string;
  email?: string;
  contactNumber: string;
  gender: number;
  lastActive: Date;
  vendorFavourites: string[];
  address: { countryCode: string };
}

export const EMPTY_CUSTOMER: FSUserDoc = {
  docID: '',
  contactNumber: '',
  title: '',
  firstName: '',
  lastName: '',
  nickname: '',
  email: '',
  gender: 0,
  lastActive: new Date(),
  vendorFavourites: [],
  address: {
    countryCode: '',
  },
};
