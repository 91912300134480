import React from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import DemmiInput from '@demmi-ui/Input';
import Menu from '@demmi-ui/Menu/Menu';
import MenuTriggerButton from '@demmi-ui/Menu/MenuTriggerButton';
import { RegionAddress } from '@helpers/address.helper';
import { VendorAddress } from '@models/vendors.model';

interface Props {
  CSSBlock: string;
  regionAddress: RegionAddress;
  vendorAddress: VendorAddress | undefined;
  setVendorAddress: React.Dispatch<
    React.SetStateAction<VendorAddress | undefined>
  >;
}

const RegionAddressFields: React.FC<Props> = ({
  CSSBlock,
  regionAddress,
  vendorAddress,
  setVendorAddress,
}) => {
  const updateAddressField = (fieldKey: string, value: string) => {
    if (vendorAddress) {
      const updatedAddress: VendorAddress = {
        ...vendorAddress,
        address: { ...vendorAddress?.address, [fieldKey]: value },
      };
      setVendorAddress(updatedAddress);
    }
  };

  const getMenuContent = (items: string[]) => {
    return {
      sections: [
        {
          items: items.map(c => ({
            id: c.replaceAll(' ', ''),
            text: c,
            item: c,
          })),
        },
      ],
    };
  };

  return (
    <DemmiCard title="Vendor Address" className={`${CSSBlock}__address`}>
      <div className={`${CSSBlock}__inputs-area`}>
        {Object.entries(regionAddress.address!).map(([key, value]) => (
          <div className={`${CSSBlock}__details-item`}>
            <div className={`${CSSBlock}__details-title`}>{value.label}</div>
            {value.values ? (
              <Menu<string>
                content={getMenuContent(Object.values(value.values))}
                triggerEl={
                  <MenuTriggerButton
                    disabled
                    text={
                      (vendorAddress?.address && vendorAddress?.address[key]) ??
                      ''
                    }
                  />
                }
                onClick={val => updateAddressField(key, val)}
              />
            ) : (
              <DemmiInput
                type="text"
                disabled
                placeholder={value.hint}
                value={vendorAddress?.address ? vendorAddress.address[key] : ''}
                maxLength={value.maxLength}
                onChangeValue={val => updateAddressField(key, val)}
              />
            )}
          </div>
        ))}
      </div>
    </DemmiCard>
  );
};

export default RegionAddressFields;
