import React, {
  HTMLProps,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import DemmiIconButton from '@demmi-ui/IconButton';
import { DemmiLogType, Logger } from '@subhanhabib/demmilib';

import DemmiCardTabs from './CardTabs';
import DemmiCardTitleArea from './CardTitleArea';

interface Default extends Base {
  titleButtons?: JSX.Element[];
  titleExtras?: JSX.Element[];
}

interface EditableContent extends Base {
  editMode: boolean;
  onClickEdit: () => void;
  onCancel: () => void;
  onSave: () => void;
  isValid: boolean;
}

interface TabbedContent extends Base {
  tabs: string[];
  tabContents: JSX.Element[];
  onClickTab: (index: number) => void;
}

interface SelfManagedTabbedContent extends Base {
  activeTab: number;
  tabs: string[];
  tabContents: JSX.Element[];
  onClickTab: (index: number) => void;
}

interface Base extends PropsWithChildren, HTMLProps<HTMLDivElement> {
  rootClassName?: string;
  buttons?: JSX.Element[];
  title?: string;
  subtitle?: string;
  darkOnly?: boolean;
  isLoading?: boolean;
  collapsible?: boolean;
  collapsed?: boolean;
  noPadding?: boolean;
}

type Props =
  | Default
  | EditableContent
  | SelfManagedTabbedContent
  | TabbedContent;

const isEditableContent = (props: Props): props is EditableContent => {
  return 'editMode' in props;
};
const isTabbedContent = (props: Props): props is TabbedContent => {
  return (
    !('activeTab' in props) &&
    'tabs' in props &&
    'tabContents' in props &&
    'onClickTab' in props
  );
};
const isSelfManagedTabbedContent = (
  props: Props
): props is SelfManagedTabbedContent => {
  return (
    'activeTab' in props &&
    'tabs' in props &&
    'tabContents' in props &&
    'onClickTab' in props
  );
};

const DemmiCard: React.FC<Props> = ({
  rootClassName,
  children,
  buttons,
  title,
  subtitle,
  darkOnly,
  isLoading,
  collapsible,
  noPadding,
  collapsed: startCollapsed = false,
  ...props
}) => {
  const CSSBlock = 'demmi-ui-card';
  const [collapsed, setCollapsed] = useState(startCollapsed);
  const [activeTab, setActiveTab] = isSelfManagedTabbedContent(props)
    ? [props.activeTab, () => {}]
    : useState(0);

  const hasTabs =
    (isTabbedContent(props) || isSelfManagedTabbedContent(props)) &&
    props.tabs.length > 0;
  const titleButtons = isEditableContent(props)
    ? !props.editMode
      ? [
          <DemmiIconButton
            key={'edit'}
            faIcon="fa-pencil"
            onClick={props.onClickEdit}
          />,
        ]
      : [
          <DemmiIconButton
            key={'cancel'}
            faIcon="fa-cancel"
            onClick={props.onCancel}
          />,
          <DemmiIconButton
            key={'save'}
            disabled={!props.isValid}
            faIcon="fa-save"
            onClick={props.onSave}
          />,
        ]
    : (props as Default).titleButtons;
  const titleExtras = isEditableContent(props)
    ? undefined
    : (props as Default).titleExtras;

  if (hasTabs && props.tabs.length !== props.tabContents.length) {
    Logger(
      {
        messages: ['Tabs !== TabContents length.'],
        objs: { tabs: props.tabs, tabContents: props.tabContents },
        type: DemmiLogType.error,
      },
      DemmiCard
    );
    return null;
  }

  // Only allow spreading for props that won't cause console errors
  let spreadableProps = {};
  if (isEditableContent(props)) {
    const { editMode, onClickEdit, onCancel, onSave, isValid, ...remainder } =
      props;
    spreadableProps = remainder;
  } else if (isTabbedContent(props)) {
    const { tabs, tabContents, onClickTab, ...remainder } = props;
    spreadableProps = remainder;
  } else if (isSelfManagedTabbedContent(props)) {
    const { activeTab, tabs, tabContents, onClickTab, ...remainder } = props;
    spreadableProps = remainder;
  } else {
    const { titleButtons, titleExtras, ...remainder } = props as Default;
    spreadableProps = remainder;
  }

  return (
    <div
      {...spreadableProps}
      className={`${CSSBlock} ${darkOnly ? `${CSSBlock}--dark-only` : ''} ${
        rootClassName ? rootClassName : ''
      } ${collapsed ? `${CSSBlock}--collapsed` : ''} ${
        isLoading ? `${CSSBlock}--loading` : ``
      } ${noPadding ? `${CSSBlock}--no-padding` : ``} ${
        hasTabs ? `${CSSBlock}--has-tabs` : ``
      }`}>
      {hasTabs && (
        <DemmiCardTabs
          tabs={props.tabs}
          activeTab={activeTab}
          setActiveTab={
            isSelfManagedTabbedContent(props) ? props.onClickTab : setActiveTab
          }
        />
      )}
      <span className={`${CSSBlock}__content`}>
        {title && (
          <DemmiCardTitleArea
            collapsible={!!collapsible}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            title={title}
            subtitle={subtitle}
            titleButtons={titleButtons}
            titleExtras={titleExtras}
          />
        )}
        <div
          className={`${CSSBlock}__main-content ${
            props.className ? props.className : ``
          }`}>
          {hasTabs ? props.tabContents[activeTab] : children}
        </div>
        <ElementLoadingIndicator isLoading={isLoading ?? false} />
      </span>
    </div>
  );
};

export default DemmiCard;
