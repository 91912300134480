import { GeoPoint } from 'firebase/firestore';

import { DemmiImageResource } from './demmiImageResource.model';

export interface VendorShop {
  openingHours: VendorOpeningHours;
}

export const EmptyOpeningHours: VendorOpeningHours = {
  mon: {
    open: 0,
    close: 0,
  },
  tue: {
    open: 0,
    close: 0,
  },
  wed: {
    open: 0,
    close: 0,
  },
  thu: {
    open: 0,
    close: 0,
  },
  fri: {
    open: 0,
    close: 0,
  },
  sat: {
    open: 0,
    close: 0,
  },
  sun: {
    open: 0,
    close: 0,
  },
};

export interface VendorOpeningHours {
  mon: OpeningHours;
  tue: OpeningHours;
  wed: OpeningHours;
  thu: OpeningHours;
  fri: OpeningHours;
  sat: OpeningHours;
  sun: OpeningHours;
}

export interface OpeningHours {
  open: number;
  close: number;
}

/**
 * Address
 */
export interface VendorGeo {
  geohash: string;
  geopoint: GeoPoint;
}
export interface VendorAddress {
  geo?: VendorGeo;
  countryCode: string;
  address: { [key: string]: string };
}

export interface FSVendors {
  name: string;
  shortDescription: string;
  longDescription: string[];
  themeHue: number;
  images: DemmiImageResource[];
  contactNumber: string;
  address: VendorAddress;
  shop: VendorShop;
}

export interface Vendor extends FSVendors {
  id: string;
}
