import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch } from '@core/hooks/appHooks';
import {
  RoutingViews,
  getViewFromPath,
  routingPaths,
} from '@core/services/routingService';
import { navigateTransitioned } from '@helpers/app.helper';
import { useAuth } from '@providers/authProvider';

interface Props {}

const Toolbar: React.FC<Props> = ({}) => {
  const CSSBlock = 'toolbar';
  const location = useLocation();
  const { logOut } = useAuth();
  const view = getViewFromPath(location.pathname, false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className={`${CSSBlock}__wrapper`}>
      <div className={`${CSSBlock}`}>
        <div
          className={`${CSSBlock}__item ${
            view === RoutingViews.HOME ? `${CSSBlock}__item--active` : ''
          }`}
          onClick={() =>
            navigateTransitioned({ dispatch, navigate, to: routingPaths.home })
          }>
          <span className={`${CSSBlock}__item-icon`}>
            <i className={'fa-solid fa-house'}></i>
          </span>
          <div className={`${CSSBlock}__item-text`}>Home</div>
        </div>

        <div
          className={`${CSSBlock}__item ${
            view === RoutingViews.INVENTORY ? `${CSSBlock}__item--active` : ''
          }`}
          onClick={() =>
            navigateTransitioned({
              dispatch,
              navigate,
              to: routingPaths.inventory,
            })
          }>
          <span className={`${CSSBlock}__item-icon`}>
            <i className={'fa-solid fa-warehouse'}></i>
          </span>
          <div className={`${CSSBlock}__item-text`}>Inventory</div>
        </div>

        <div
          className={`${CSSBlock}__item ${
            view === RoutingViews.ANALYTICS ? `${CSSBlock}__item--active` : ''
          }`}
          onClick={() =>
            navigateTransitioned({
              dispatch,
              navigate,
              to: routingPaths.analytics,
            })
          }>
          <span className={`${CSSBlock}__item-icon`}>
            <i className={'fa-solid fa-chart-mixed-up-circle-dollar'}></i>
          </span>
          <div className={`${CSSBlock}__item-text`}>Analytics</div>
        </div>

        <div
          className={`${CSSBlock}__item ${
            view === RoutingViews.ORDERS ? `${CSSBlock}__item--active` : ''
          }`}
          onClick={() =>
            navigateTransitioned({
              dispatch,
              navigate,
              to: routingPaths[RoutingViews.ORDERS_INCOMING],
            })
          }>
          <span className={`${CSSBlock}__item-icon`}>
            <i className={'fa-solid fa-basket-shopping'}></i>
          </span>
          <div className={`${CSSBlock}__item-text`}>Orders</div>
        </div>

        <div
          className={`${CSSBlock}__item ${
            view === RoutingViews.REVIEWS ? `${CSSBlock}__item--active` : ''
          }`}
          onClick={() =>
            navigateTransitioned({
              dispatch,
              navigate,
              to: routingPaths.reviews,
            })
          }>
          <span className={`${CSSBlock}__item-icon`}>
            <i className={'fa-solid fa-comment'}></i>
          </span>
          <div className={`${CSSBlock}__item-text`}>Reviews</div>
        </div>

        <div
          className={`${CSSBlock}__item ${
            view === RoutingViews.COUPONS ? `${CSSBlock}__item--active` : ''
          }`}
          onClick={() =>
            navigateTransitioned({
              dispatch,
              navigate,
              to: routingPaths.coupons,
            })
          }>
          <span className={`${CSSBlock}__item-icon`}>
            <i className={'fa-solid fa-ticket'}></i>
          </span>
          <div className={`${CSSBlock}__item-text`}>Coupons</div>
        </div>
      </div>

      <div className={`${CSSBlock}`}>
        <div
          className={`${CSSBlock}__item ${
            view === RoutingViews.SETTINGS ? `${CSSBlock}__item--active` : ''
          }`}
          onClick={() =>
            navigateTransitioned({
              dispatch,
              navigate,
              to: routingPaths[RoutingViews.SETTINGS_DETAILS],
            })
          }>
          <span className={`${CSSBlock}__item-icon`}>
            <i className={'fa-solid fa-gear'}></i>
          </span>
        </div>
        <div
          className={`${CSSBlock}__item`}
          onClick={async () => {
            logOut(() => dispatch({ type: 'LOGOUT' }));
          }}>
          <span className={`${CSSBlock}__item-icon`}>
            <i className={'fa-solid fa-arrow-right-from-bracket'}></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
