import { Product, ProductVariant } from '@models/product.model';

import { WeightUnit } from './units.helper';

export const NEW_PRODUCT_TEMP_ID = 'TEMP';
export const NEW_PRODUCT_VARIANT_TEMP_ID = 'TEMP';

export const createNewProduct = (vendorID: string): Product => {
  return {
    id: NEW_PRODUCT_TEMP_ID,
    vendorID,
    name: '',
    categoryID: '',
    shortDescription: '',
    longDescription: [],
    images: [],
    tags: [],
  };
};

export const createNewProductVariant = (productID: string): ProductVariant => {
  return {
    id: NEW_PRODUCT_VARIANT_TEMP_ID,
    productID,
    name: '',
    minQuantity: 0,
    availableQuantity: 0,
    pricePerUnit: 0,
    increments: 1,
    unit: WeightUnit.kilogram,
    unitPerItem: WeightUnit.undefined,
  };
};

export const productHasChanges = (
  product: Product,
  updated: Omit<Product, 'id' | 'vendorID'>
) => {
  return !(
    product.name === updated.name &&
    product.categoryID === updated.categoryID &&
    product.shortDescription === updated.shortDescription &&
    product.longDescription.join('\n') === updated.longDescription.join('\n') &&
    product.images.join() === updated.images.join() &&
    product.tags.join() === updated.tags.join()
  );
};

export const isProductDetailsValid = (
  product: Omit<Product, 'id' | 'vendorID'>
) => {
  return (
    product.name.length > 0 &&
    product.categoryID.length > 0 &&
    product.shortDescription.length > 0 &&
    product.categoryID.length > 0 &&
    product.longDescription.join().replaceAll(' ', '').length > 0 &&
    product.images.find(image => image.main.length === 0) === undefined &&
    product.tags.find(tag => tag.length === 0) === undefined
  );
};

export const variantHasChanges = (
  variant: ProductVariant,
  updated: Omit<ProductVariant, 'id' | 'productID'>
) => {
  return (
    variant.name === updated.name &&
    variant.availableQuantity === updated.availableQuantity &&
    variant.minQuantity === updated.minQuantity &&
    variant.increments === updated.increments &&
    variant.pricePerUnit === updated.pricePerUnit &&
    variant.unit === updated.unit &&
    variant.weightPerItem === updated.weightPerItem &&
    variant.unitPerItem === updated.unitPerItem
  );
};

export const isProductVariantValid = (
  variant: Omit<ProductVariant, 'id' | 'productID'>
): boolean => {
  return variant.name.length > 0 &&
    variant.minQuantity > 0 &&
    variant.increments > 0 &&
    variant.pricePerUnit > 0 &&
    variant.unit.length > 0 &&
    variant.unit === WeightUnit.each
    ? !!(
        variant.unitPerItem &&
        variant.unitPerItem.length > 0 &&
        variant.weightPerItem &&
        variant.weightPerItem > 0
      )
    : true;
};
