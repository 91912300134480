import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import {
  getProductImage,
  getVendorImage,
} from '@core/services/networkService/functions/_storage';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { demmiRequest } from '@helpers/app.helper';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  src: string;
  isVendor?: boolean;
}

const AsyncImage: React.FC<Props> = ({ src, isVendor, ...props }) => {
  const CSSBlock = 'async-image';
  const vendor = useAppSelector(selectVendor);
  const [loadedSrc, setLoadedSrc] = useState<string>();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    setLoadedSrc(undefined);
    if (src && vendor) {
      demmiRequest(
        isVendor
          ? getVendorImage(vendor.id, src)
          : getProductImage(vendor.id, src)
      ).then(url => {
        const handleLoad = () => setLoadedSrc(url);
        const image = new Image();
        image.addEventListener('load', handleLoad);
        image.src = url;
        image.complete;
        return () => {
          image.removeEventListener('load', handleLoad);
        };
      });
    }
  }, [src, vendor]);

  const onImageLoaded = () => {
    setHasLoaded(true);
    setTimeout(() => {
      setCompleted(true);
    }, 200);
  };

  return (
    <span className={`${CSSBlock} ${hasLoaded ? `${CSSBlock}--loaded` : ``}`}>
      {loadedSrc && <img src={loadedSrc} {...props} onLoad={onImageLoaded} />}

      {!completed && (
        <div className={`${CSSBlock}__loading-indicator-wrapper`}>
          <div className={`${CSSBlock}__loading-indicator`} />
        </div>
      )}
    </span>
  );
};

export default AsyncImage;
