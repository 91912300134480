import React from 'react';

interface DemmiTextAreaProps {
  placeholder?: string;
  value?: string;
  name?: string;
  maxLength?: number;
  maxHeight?: number;
  onChange: (val: string) => void;
}

const DemmiTextArea: React.FC<DemmiTextAreaProps> = ({
  placeholder,
  value,
  name,
  maxLength,
  maxHeight,
  onChange,
}) => {
  const CSSBlock = 'demmi-ui-input';

  return (
    <span className={`${CSSBlock}__wrapper`}>
      <textarea
        className={CSSBlock}
        placeholder={placeholder}
        value={value}
        name={name}
        maxLength={maxLength}
        style={maxHeight ? { maxHeight: `${maxHeight}rem` } : {}}
        onChange={e => onChange(e.target.value)}
      />
      {maxLength && (
        <div className={`${CSSBlock}__max-length`}>
          {value ? value?.length : 0} / {maxLength.toString()} characters
        </div>
      )}
    </span>
  );
};

export default DemmiTextArea;
