export const areObjectsIdentical = (obj1: any, obj2: any): boolean => {
  // Check if both parameters are objects
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return false;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Iterate through keys and recursively check values
  for (const key of keys1) {
    // Check if the key exists in both objects
    if (!keys2.includes(key)) {
      return false;
    }

    // Recursively check values if they are objects
    if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
      if (!areObjectsIdentical(obj1[key], obj2[key])) {
        return false;
      }
    } else {
      // Check if values are equal
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  }

  // If all checks pass, the objects are identical
  return true;
};
