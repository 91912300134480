import React from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { updateOrderStatus } from '@core/services/networkService/orders/status';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import Menu from '@demmi-ui/Menu/Menu';
import { MenuContent, MenuItem } from '@demmi-ui/Menu/MenuTypes';
import { demmiRequest } from '@helpers/app.helper';
import {
  OrderVendorStatus,
  getOrderVendorStatusText,
} from '@helpers/orderStatus.helper';
import { OrderTimelineUpdatedBy } from '@helpers/orderTimeline.helper';
import { Order } from '@models/orders.model';

interface Props {
  order: Order;
  status: string;
}

const OrderItemHeader: React.FC<Props> = ({ order, status }) => {
  const CSSBlock = 'order-item-header';
  const vendor = useAppSelector(selectVendor);

  const updateStatus = (status: OrderVendorStatus) => {
    if (vendor && order)
      demmiRequest(
        updateOrderStatus(order.id, {
          vendorID: vendor.id,
          timestamp: new Date(),
          statusUpdate: status,
          updatedBy: OrderTimelineUpdatedBy.VENDOR_USER,
        })
      );
  };

  const content: MenuContent<OrderVendorStatus> = {
    sections: [
      {
        items: [
          {
            id: OrderVendorStatus.IN_PROGRESS,
            text: getOrderVendorStatusText(OrderVendorStatus.IN_PROGRESS),
            item: OrderVendorStatus.IN_PROGRESS,
          } as MenuItem<OrderVendorStatus>,
          {
            id: OrderVendorStatus.READY_FOR_COLLECTION,
            text: getOrderVendorStatusText(
              OrderVendorStatus.READY_FOR_COLLECTION
            ),
            item: OrderVendorStatus.READY_FOR_COLLECTION,
          } as MenuItem<OrderVendorStatus>,
          {
            id: OrderVendorStatus.COLLECTED,
            text: getOrderVendorStatusText(OrderVendorStatus.COLLECTED),
            item: OrderVendorStatus.COLLECTED,
          } as MenuItem<OrderVendorStatus>,
        ],
      },
    ],
  };

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__id`}>{`Order #${order.orderID}`}</div>
      <div className={`${CSSBlock}__status`}>
        <div className={`${CSSBlock}__status-badge`}>{status}</div>
        <Menu<OrderVendorStatus>
          content={content}
          triggerEl={
            <DemmiIconButton
              size={IconButtonSize.MINI}
              faIcon="fa-caret-down"
            />
          }
          onClick={updateStatus}
        />
      </div>
    </div>
  );
};

export default OrderItemHeader;
