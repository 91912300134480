import { QueryDocumentSnapshot } from 'firebase/firestore';

import { getCouponType } from '@helpers/coupon.helper';
import {
  getOrderStatus,
  getOrderVendorStatus,
} from '@helpers/orderStatus.helper';
import { getOrderTimelineUpdatedBy } from '@helpers/orderTimeline.helper';
import { getWeightUnit } from '@helpers/units.helper';
import { FSOrder, FSOrderVendorCoupon, Order } from '@models/orders.model';

import { getDateFromFirestore } from '../networkService';

export const parseToOrder = (
  vendorID: string,
  doc: QueryDocumentSnapshot<FSOrder>
): Order => {
  const vendors = doc.data()['vendors'];
  const date = getDateFromFirestore(doc.data()['orderDate']);
  const status = getOrderStatus(doc.data().status);
  const coupon: FSOrderVendorCoupon | undefined =
    vendors[vendorID].coupon && vendors[vendorID].coupon?.type
      ? {
          ...vendors[vendorID].coupon!,
          type: getCouponType(vendors[vendorID].coupon!.type),
        }
      : undefined;
  const products = vendors[vendorID].products.map(p => ({
    ...p,
    variantUnit: getWeightUnit(p.variantUnit) ?? p.variantUnit,
  }));

  return {
    id: doc.id,
    ...(doc.data() as FSOrder),
    orderDate: date!,
    status: status,
    vendor: { ...vendors[vendorID], products, coupon },
    timeline: doc.data().timeline
      ? doc.data().timeline.map(update => ({
          ...update,
          timestamp: getDateFromFirestore(update.timestamp),
          statusUpdate: getOrderVendorStatus(update.statusUpdate),
          updatedBy: getOrderTimelineUpdatedBy(update.updatedBy),
        }))
      : [],
  };
};
