import React, { useState } from 'react';

import { useAppDispatch } from '@core/hooks/appHooks';
import { useAuth } from '@providers/authProvider';
import { useFirestore } from '@providers/firestoreProvider';
import { Logger } from '@subhanhabib/demmilib';

// import { FSIndex, FSUserModel } from '@storage/firebase/firestore';

interface LoadingIntroProps {}

const LoadingAuth: React.FC<LoadingIntroProps> = ({}) => {
  const CSSBlock = 'loading-intro';
  const dispatch = useAppDispatch();
  const [hide, setHide] = useState(false);

  const onLoad = () => {
    const { user } = useAuth();
    Logger({ messages: ['onLoad'], objs: { user } }, LoadingAuth);
    // const { usersCollection } = useFirestore();
    if (!user) return;

    // setTimeout(() => {
    //   setHide(true);
    //   setTimeout(() => onSuccess(), 500);
    // }, 500);
    // // Load user config into global app state.
    // FSUserModel.getUserDoc(user).then(doc => {
    //   if (doc) {
    //     setTimeout(() => {
    //       setHide(true);
    //       setTimeout(() => onSuccess(), 500);
    //     }, 500);
    //   } else {
    //     setTimeout(() => {
    //       setHide(true);
    //       setTimeout(() => onNewUser(), 500);
    //     }, 500);
    //   }
    // });
  };

  onLoad();

  return (
    <div
      className={`${CSSBlock} ${CSSBlock}--auth ${
        hide ? `${CSSBlock}--hide` : ''
      }`}>
      <div className={`${CSSBlock}__background`}></div>
      <div className={`${CSSBlock}__title`}>
        <strong>P</strong>roject <strong>D</strong>eme
      </div>
      <div className={`${CSSBlock}__subtitle`}>Checking User Data</div>
      <div className={`${CSSBlock}__loading-indicator`}></div>
    </div>
  );
};

export default LoadingAuth;
