/**
 * Order Timeline Updated By
 */

export enum OrderTimelineUpdatedBy {
  CUSTOMER = 'customer',
  VENDOR_USER = 'vendor',
  DEMMI = 'demmi',
  UNKNOWN = '',
}

export const getOrderTimelineUpdatedBy = (
  status?: string
): OrderTimelineUpdatedBy => {
  const match = Object.entries(OrderTimelineUpdatedBy).find(
    ([_, val]) => val === status
  );
  return match ? match[1] : OrderTimelineUpdatedBy.UNKNOWN;
};

export const getOrderTimelineUpdatedByText = (
  status: OrderTimelineUpdatedBy
): string => {
  switch (status) {
    case OrderTimelineUpdatedBy.CUSTOMER:
      return 'Customer';
    case OrderTimelineUpdatedBy.VENDOR_USER:
      return 'You';
    case OrderTimelineUpdatedBy.DEMMI:
      return 'Demmi';
    default:
      return 'Unknown';
  }
};
