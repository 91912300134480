import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import {
  createDemmiSupportChat,
  sendDemmiSupportMessage,
} from '@core/services/networkService/demmiSupport/demmiSupport';
import { updateVendorAddress } from '@core/services/networkService/functions/_vendors';
import {
  selectVendor,
  setDemmiSupportChatID,
  setDemmiSupportOpen,
  setVendor,
} from '@core/store/appSlice/appSlice';
import DemmiButton from '@demmi-ui/Button';
import { RegionAddress, getRegionAddress } from '@helpers/address.helper';
import { demmiRequest } from '@helpers/app.helper';
import {
  isVendorAddressValid,
  vendorAddressHasChanges,
} from '@helpers/vendors.helper';
import { FSDemmiSupportChat } from '@models/demmiSupport.model';
import { Vendor, VendorAddress } from '@models/vendors.model';

import { CSSBLOCK_SETTINGS } from '../Settings';
import GeoLocationMap from './GeoLocationMap';
import RegionAddressFields from './RegionAddressFields';

interface Props {}

const SettingsLocation: React.FC<Props> = ({}) => {
  const CSSBlock = 'settings-location';
  const dispatch = useAppDispatch();
  const vendor = useAppSelector(selectVendor);

  // Address
  const [address, setAddress] = useState<VendorAddress>();
  const [detectedChanges, setDetectedChanges] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isCreatingSupportTicket, setIsCreatingSupportTicket] = useState(false);

  const [regionAddress, setRegionAddress] = useState<RegionAddress | null>();

  useEffect(() => {
    if (vendor) {
      setAddress(vendor.address);
      setRegionAddress(getRegionAddress(vendor.address.countryCode));
    }
  }, [vendor]);

  useEffect(() => {
    if (vendor) {
      setDetectedChanges(
        !!address
          ? vendorAddressHasChanges(vendor?.address, address)
          : vendor.address === address
      );
      setIsValid(address ? isVendorAddressValid(address) : false);
    }
  }, [address]);

  const discardChanges = () => {
    if (vendor) setAddress(vendor.address);
  };

  const saveChanges = async () => {
    if (isValid && vendor && address) {
      const f: Vendor = {
        ...vendor,
        address,
      };
      demmiRequest(updateVendorAddress(vendor.id, address)).then(() => {
        dispatch(setVendor(f));
      });
    }
  };

  const invalidAddress = async () => {
    if (!vendor) return;
    setIsCreatingSupportTicket(true);
    const chat: FSDemmiSupportChat = {
      title: 'Unable to find correct vendor address.',
      vendorID: vendor.id,
    };

    const id = await createDemmiSupportChat(chat);
    await sendDemmiSupportMessage(id, {
      text: `${vendor.name} was unable to find the correct address for their location.`,
      timestamp: new Date(),
      isSenderDemmi: false,
      read: false,
    });

    dispatch(setDemmiSupportOpen(true));
    dispatch(setDemmiSupportChatID(id));
    setIsCreatingSupportTicket(false);
    //  Create support chat
    // Wait for request
    // Open support panel
  };

  return (
    <div className={`${CSSBLOCK_SETTINGS}__content-wrapper ${CSSBlock}`}>
      {regionAddress && (
        <>
          <GeoLocationMap
            CSSBlock={CSSBlock}
            regionAddress={regionAddress}
            setVendorAddress={setAddress}
          />
          <RegionAddressFields
            CSSBlock={CSSBlock}
            regionAddress={regionAddress}
            vendorAddress={address}
            setVendorAddress={setAddress}
          />
        </>
      )}
      <div className={`${CSSBlock}__button-not-found`}>
        <div className={`${CSSBlock}__button-not-found-text`}>
          Can't find your vendor? We'll sort that out for you!
        </div>
        <DemmiButton
          disabled={isCreatingSupportTicket}
          text={'Open Support Ticket'}
          faIcon="fa-message-bot"
          onClick={invalidAddress}
        />
      </div>
      <div className={`${CSSBlock}__buttons`}>
        <DemmiButton
          text={'Discard Changes'}
          faIcon="fa-ban"
          disabled={!detectedChanges}
          onClick={discardChanges}
        />
        <DemmiButton
          text={'Save Changes'}
          faIcon="fa-regular fa-floppy-disk"
          disabled={!detectedChanges || !isValid}
          onClick={saveChanges}
        />
      </div>
    </div>
  );
};

export default SettingsLocation;
