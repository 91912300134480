import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiCardDivider from '@demmi-ui/Card/CardDivider';
import DemmiInput from '@demmi-ui/Input';
import Menu from '@demmi-ui/Menu/Menu';
import MenuTriggerButton from '@demmi-ui/Menu/MenuTriggerButton';
import { MenuItem } from '@demmi-ui/Menu/MenuTypes';
import DemmiModalArea from '@demmi-ui/Modal/ModalArea';
import DemmiToggle from '@demmi-ui/Toggle';
import {
  CouponTypes,
  generateCouponCodePrefix,
  isCouponDetailsValid,
} from '@helpers/coupon.helper';
import { getCurrencySymbolFromCountryCode } from '@helpers/currency.helper';
import { Coupon } from '@models/coupons.model';

import ModalCreateCouponContentFixed from './ModalCreateCouponContentFixed';
import ModalCreateCouponContentPercentage from './ModalCreateCouponContentPercentage';

interface Props {
  state: number;
  CSSBlock: string;
  setCoupon: React.Dispatch<React.SetStateAction<Coupon | undefined>>;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  isDoingRequest: boolean;
}

const ModalCreateCouponContent: React.FC<Props> = ({
  state,
  CSSBlock,
  setCoupon,
  setIsValid,
  isDoingRequest,
}) => {
  const vendor = useAppSelector(selectVendor);
  const [type, setType] = useState<CouponTypes>(
    CouponTypes.coupon_discount_fixed
  );
  const [codePrefix, setCodePrefix] = useState('');
  const [code, setCode] = useState('');
  const [minSpend, setMinSpend] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [hasStartDate, setHasStartDate] = useState(false);
  const [hasExpirationDate, setHasExpirationDate] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [currencySymbol, setCurrencySymbol] = useState('');

  useEffect(() => {
    setIsValid(
      isCouponDetailsValid(
        code,
        discount,
        minSpend,
        type,
        hasStartDate ? startDate : undefined,
        hasExpirationDate ? expirationDate : undefined
      )
    );
    setCoupon(createCoupon());
  }, [
    code,
    minSpend,
    discount,
    type,
    hasStartDate,
    startDate,
    hasExpirationDate,
    expirationDate,
  ]);

  useEffect(() => {
    if (vendor) {
      setCodePrefix(generateCouponCodePrefix(vendor!));
      setCurrencySymbol(
        getCurrencySymbolFromCountryCode(vendor.address.countryCode)
      );
    }
  }, [vendor]);

  const createCoupon = (): Coupon => ({
    enabled: true,
    vendorID: vendor!.id,
    type: type,
    code: codePrefix + code,
    amount: discount,
    minSpend: minSpend,
    ...(hasStartDate ? { startDate: new Date(startDate) } : {}),
    ...(hasExpirationDate
      ? { expirationDate: new Date(`${expirationDate} 23:59:59`) }
      : {}),
  });

  const getMenuContent = () => {
    return {
      sections: [
        {
          items: [
            {
              id: CouponTypes.coupon_discount_fixed,
              text: CouponTypes.coupon_discount_fixed,
              item: CouponTypes.coupon_discount_fixed,
            } as MenuItem<CouponTypes>,
            {
              id: CouponTypes.coupon_discount_percent,
              text: CouponTypes.coupon_discount_percent,
              item: CouponTypes.coupon_discount_percent,
            } as MenuItem<CouponTypes>,
          ],
        },
      ],
    };
  };

  const regexCode = (val: string) => {
    const value = val.replace(/[^-_0-9a-zA-Z]+/gi, '').toUpperCase();
    if (val !== value) val = value;
    setCode(val);
  };

  return (
    <>
      <DemmiModalArea isDark>
        <div className={`${CSSBlock}__title`}>Create a new coupon</div>
        <div className={`${CSSBlock}__content`}>
          <DemmiCard>
            <Menu<CouponTypes>
              content={getMenuContent()}
              triggerEl={
                <MenuTriggerButton
                  text={type || '-'}
                  styles={{ width: '100%' }}
                />
              }
              onClick={cat => {
                setMinSpend(0);
                setDiscount(0);
                setType(cat);
              }}
            />
            <br />
            <div className={`${CSSBlock}__code-row`}>
              <div className={`${CSSBlock}__code-prefix`}>{codePrefix}</div>
              <DemmiInput
                type="text"
                placeholder="Coupon Code"
                value={code}
                onChangeValue={val => regexCode(val)}
              />
            </div>
            <DemmiCardDivider />
            {type === CouponTypes.coupon_discount_fixed ? (
              <ModalCreateCouponContentFixed
                CSSBlock={CSSBlock}
                currencySymbol={currencySymbol}
                minSpend={minSpend}
                setMinSpend={setMinSpend}
                discount={discount}
                setDiscount={setDiscount}
              />
            ) : type === CouponTypes.coupon_discount_percent ? (
              <ModalCreateCouponContentPercentage
                CSSBlock={CSSBlock}
                currencySymbol={currencySymbol}
                minSpend={minSpend}
                setMinSpend={setMinSpend}
                discount={discount}
                setDiscount={setDiscount}
              />
            ) : null}
            <DemmiCardDivider />
            <div className={`${CSSBlock}__toggle-item`}>
              <DemmiToggle
                isActive={hasStartDate}
                text="Start Date?"
                onToggle={v => setHasStartDate(v)}
              />
              {hasStartDate && (
                <>
                  <DemmiInput
                    type="date"
                    value={startDate}
                    onChangeValue={val => setStartDate(val)}
                  />
                  <br />
                  <br />
                </>
              )}
            </div>
            <div className={`${CSSBlock}__toggle-item`}>
              <DemmiToggle
                isActive={hasExpirationDate}
                text="Expiration Date?"
                onToggle={v => setHasExpirationDate(v)}
              />
              {hasExpirationDate && (
                <DemmiInput
                  type="date"
                  value={expirationDate}
                  onChangeValue={val => setExpirationDate(val)}
                />
              )}
            </div>
            {state === -1 && (
              <div className={`${CSSBlock}__error`}>
                Code already exists. Please try another.
              </div>
            )}
          </DemmiCard>
        </div>
      </DemmiModalArea>
    </>
  );
};

export default ModalCreateCouponContent;
