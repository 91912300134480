import React, { useState } from 'react';

import DemmiButton from '@demmi-ui/Button';
import DemmiIconButton from '@demmi-ui/IconButton';
import DemmiTextArea from '@demmi-ui/TextArea';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { VendorReview } from '@models/vendor-review.model';

export interface Props {
  review: VendorReview;
  CSSBlock: string;
  onUpdateResponse: (val: string) => void;
}

const ReviewResponse: React.FC<Props> = ({
  review,
  CSSBlock,
  onUpdateResponse,
}) => {
  const [response, setResponse] = useState<string>(
    review.response ? review.response.body.join('\n') : ''
  );
  const [editMode, setEditMode] = useState(false);

  return (
    <div className={`${CSSBlock}__review-response`}>
      {editMode ? (
        <>
          <div className={`${CSSBlock}__review-response-title`}>
            Your response
            <div className={`${CSSBlock}__review-response-title-buttons`}>
              <DemmiIconButton
                variant="delete"
                icon={icon({ style: 'regular', name: 'cancel' })}
                onClick={() => {
                  setResponse(review.response?.body.join('\n') ?? '');
                  setEditMode(false);
                }}
              />
              <DemmiIconButton
                variant="save"
                icon={icon({ style: 'regular', name: 'save' })}
                onClick={() => {
                  onUpdateResponse(response);
                  setEditMode(false);
                }}
              />
            </div>
          </div>
          <DemmiTextArea
            placeholder="Your response"
            value={response}
            maxLength={1000}
            maxHeight={24}
            onChange={val => setResponse(val)}
          />
        </>
      ) : review.response ? (
        <>
          <div className={`${CSSBlock}__review-response-title`}>
            Your response
            <DemmiIconButton
              icon={icon({ style: 'regular', name: 'edit' })}
              onClick={() => setEditMode(!editMode)}
            />
          </div>
          {review.response.body.map((paragraph, i) => (
            <div className={`${CSSBlock}__review-description-paragraph`}>
              {paragraph}
            </div>
          ))}
        </>
      ) : (
        <div className={`${CSSBlock}__review-no-response`}>
          You've not yet responded to this review.
          <DemmiButton
            text="Respond now"
            faIcon="fa-edit"
            onClick={() => setEditMode(true)}
          />
        </div>
      )}
    </div>
  );
};

export default ReviewResponse;
