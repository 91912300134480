import React from 'react';

export interface DemmiTimelineItem {
  title?: string;
  date?: Date;
  text?: string;
  textBadge?: string;
}

interface Props {
  items: DemmiTimelineItem[];
}

const DemmiTimeline: React.FC<Props> = ({ items }) => {
  const CSSBlock = 'demmi-ui-timeline';
  return (
    <div className={`${CSSBlock}`}>
      {items.map((item, i) => (
        <div className={`${CSSBlock}__item`} key={i}>
          {item.title && (
            <div className={`${CSSBlock}__item-title`}>{item.title}</div>
          )}
          {item.date && (
            <div className={`${CSSBlock}__item-date`}>
              {item.date.toLocaleString()}
            </div>
          )}
          {item.text && (
            <div className={`${CSSBlock}__item-text`}>
              {item.text}
              {item.textBadge && (
                <div className={`${CSSBlock}__item-text-badge`}>
                  {item.textBadge}
                </div>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default DemmiTimeline;
