import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { getFSDemmi } from '@core/services/networkService/functions/_demmi';
import { selectFSDemmi, setFSDemmi } from '@core/store/appSlice/appSlice';
import DemmiCard from '@demmi-ui/Card/Card';
import Menu from '@demmi-ui/Menu/Menu';
import MenuTriggerButton from '@demmi-ui/Menu/MenuTriggerButton';
import { demmiRequest } from '@helpers/app.helper';
import {
  CATEGORY_ID_OTHER,
  getCategoryOther,
  getMenuContent,
} from '@helpers/categories.helper';
import { Category, CategoryParent } from '@models/demmi.model';

interface Props {
  categoryID?: string;
  setCategoryID: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ProductCategory: React.FC<Props> = ({ categoryID, setCategoryID }) => {
  const CSSBlock = 'product-category';
  const [primaryCategory, setPrimaryCategory] = useState<CategoryParent>();
  const [secondaryCategory, setSecondaryCategory] = useState<Category>();

  const demmiFS = useAppSelector(selectFSDemmi);
  const [categories, setCategories] = useState<CategoryParent[]>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!demmiFS) demmiRequest(getFSDemmi()).then(o => dispatch(setFSDemmi(o)));
    else {
      const cat = demmiFS.constants.categories.find(c => {
        // if selected category is "Other" within parent category
        if (c.id === categoryID) {
          setSecondaryCategory(getCategoryOther(c.name));
          return true;
        }

        // if selected child category is specified
        return c.children.find((x: Category) => {
          if (x.id === categoryID) {
            setSecondaryCategory(x);
            return true;
          }
        });
      });
      setPrimaryCategory(cat);
      setCategories(demmiFS.constants.categories);
    }
  }, [demmiFS, categoryID]);

  return (
    <DemmiCard title="Category" className={CSSBlock}>
      <div className={`${CSSBlock}__list`}>
        <Menu<CategoryParent>
          content={getMenuContent<CategoryParent>(categories ?? [])}
          triggerEl={
            <MenuTriggerButton
              icon={primaryCategory?.icon}
              text={primaryCategory?.name || '-'}
            />
          }
          onClick={cat => {
            setPrimaryCategory(cat);
          }}
        />
        <Menu<Category>
          content={getMenuContent<Category>(primaryCategory?.children ?? [], [
            getCategoryOther(primaryCategory?.name ?? ''),
          ])}
          triggerEl={
            <MenuTriggerButton
              icon={secondaryCategory?.icon}
              text={secondaryCategory?.name || '-'}
            />
          }
          onClick={cat => {
            setSecondaryCategory(cat);
            cat.id === CATEGORY_ID_OTHER
              ? setCategoryID(primaryCategory!.id)
              : setCategoryID(cat.id);
          }}
        />
      </div>
    </DemmiCard>
  );
};

export default ProductCategory;
