import { format } from 'date-fns';
import React from 'react';

import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DemmiSupportMessage } from '@models/demmiSupport.model';

interface Props {
  message: DemmiSupportMessage;
}

const SupportChatItem: React.FC<Props> = ({ message }) => {
  const CSSBlock = 'support-chat-item';

  return (
    <div
      className={`${CSSBlock} ${
        message.isSenderDemmi ? `${CSSBlock}--demmi` : ``
      }`}>
      <div className={`${CSSBlock}__text`}>{message.text}</div>
      <div className={`${CSSBlock}__timestamp`}>
        <FontAwesomeIcon
          size={'lg'}
          icon={
            message.read
              ? icon({
                  style: 'regular',
                  name: 'check-double',
                })
              : icon({
                  style: 'regular',
                  name: 'check',
                })
          }
        />
        {message.timestamp
          ? format(message.timestamp, 'dd MMM yyyy, HH:mm:ss')
          : '-'}
      </div>
    </div>
  );
};

export default SupportChatItem;
