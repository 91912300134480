import React, { useEffect, useState } from 'react';
import { type Value, isPossiblePhoneNumber } from 'react-phone-number-input';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { updateVendorDetails } from '@core/services/networkService/functions/_vendors';
import { selectVendor, setVendor } from '@core/store/appSlice/appSlice';
import DemmiButton from '@demmi-ui/Button';
import { demmiRequest } from '@helpers/app.helper';
import {
  isVendorDetailsValid,
  vendorDetailsHasChanges,
} from '@helpers/vendors.helper';
import { DemmiImageResource } from '@models/demmiImageResource.model';
import { Vendor } from '@models/vendors.model';

import { CSSBLOCK_SETTINGS } from '../Settings';
import Description from './Description';

interface Props {}

const SettingsDetails: React.FC<Props> = ({}) => {
  const CSSBlock = 'settings-details';
  const dispatch = useAppDispatch();
  const vendor = useAppSelector(selectVendor);

  const [name, setName] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [description, setDescription] = useState('');
  const [contactNumber, setContactNumber] = useState<Value>();
  const [images, setImages] = useState<DemmiImageResource[]>([]);

  const [detectedChanges, setDetectedChanges] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const setValues = (vendor: Vendor) => {
    setName(vendor.name);
    setShortDescription(vendor.shortDescription);
    setDescription(vendor.longDescription.join('\n'));
    setContactNumber(vendor.contactNumber as Value);
    setImages(vendor.images);
  };

  useEffect(() => {
    if (vendor) setValues(vendor);
  }, [vendor]);

  useEffect(() => {
    if (vendor) {
      setDetectedChanges(
        vendorDetailsHasChanges(vendor, {
          name,
          shortDescription,
          longDescription: description.split('\n'),
          contactNumber: contactNumber?.toString() ?? '',
          images,
        })
      );
      setIsValid(
        isVendorDetailsValid({
          name,
          shortDescription,
          longDescription: description.split('\n'),
          contactNumber: contactNumber?.toString() ?? '',
          images,
        })
      );
    }
  }, [name, contactNumber, shortDescription, description, images, vendor]);

  const discardChanges = () => {
    if (vendor) setValues(vendor);
  };

  const saveChanges = async () => {
    if (isValid && vendor) {
      const f: Vendor = {
        ...vendor,
        name,
        images,
        shortDescription,
        longDescription: description
          .split('\n')
          .filter(value => value.length > 0),
      };
      demmiRequest(updateVendorDetails(f)).then(() => dispatch(setVendor(f)));
    }
  };

  return (
    <div className={`${CSSBLOCK_SETTINGS}__content-wrapper ${CSSBlock}`}>
      {vendor ? (
        <>
          <Description
            CSSBlock={CSSBlock}
            countryCode="GB"
            name={name}
            setName={setName}
            contactNumber={contactNumber}
            setContactNumber={setContactNumber}
            shortDescription={shortDescription}
            setShortDescription={setShortDescription}
            description={description}
            setDescription={setDescription}
          />

          <div className={`${CSSBlock}__buttons`}>
            <span style={{ alignSelf: 'center', display: 'flex', gap: '1rem' }}>
              <DemmiButton
                text={'Discard Changes'}
                faIcon="fa-ban"
                disabled={!detectedChanges}
                onClick={() => discardChanges()}
              />
              <DemmiButton
                text={'Save Changes'}
                faIcon="fa-regular fa-floppy-disk"
                disabled={!detectedChanges || !isValid}
                onClick={() => saveChanges()}
              />
            </span>
          </div>
        </>
      ) : (
        <div>No vendor selected</div>
      )}
    </div>
  );
};

export default SettingsDetails;
