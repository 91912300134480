import React from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import DemmiChip from '@demmi-ui/Chip';
import { FSDemmiTag } from '@models/demmi.model';

interface Props {
  demmiTags: FSDemmiTag[];
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
}

const Tags: React.FC<Props> = ({ demmiTags, tags, setTags }) => {
  const CSSBlock = 'product-tags';

  const onToggleTag = (id: string) => {
    const index = tags.findIndex(t => t === id);
    if (index < 0) {
      setTags([...tags, id]);
    } else {
      setTags([...tags.slice(0, index), ...tags.slice(index + 1)]);
    }
  };

  return (
    <DemmiCard title="Tags" className={CSSBlock}>
      <div className={`${CSSBlock}__list`}>
        {demmiTags.map((tag, i) => (
          <DemmiChip
            text={tag.name}
            key={i}
            isActive={!!tags.find(t => t === tag.id)}
            onToggle={() => onToggleTag(tag.id)}
          />
        ))}
      </div>
    </DemmiCard>
  );
};

export default Tags;
