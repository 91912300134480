import {
  DocumentData,
  QueryDocumentSnapshot,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';

import { getCouponType, getCouponTypeKey } from '@helpers/coupon.helper';
import { Coupon, FSCoupon } from '@models/coupons.model';
import { FSCollections } from '@providers/firestoreProvider';
import { DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { db } from '../../../../firebase';
import { FSCollectionNames, getDateFromFirestore } from '../networkService';

export const createCoupon = async (coupon: Coupon): Promise<boolean> => {
  Logger({ objs: { coupon } }, createCoupon);
  const { code, ...couponDetails } = coupon;
  const isAvailable = await checkCodeAvailability(code);
  if (isAvailable) {
    const docRef = await setDoc(doc(db, FSCollectionNames.COUPONS, code), {
      ...couponDetails,
      type: getCouponTypeKey(couponDetails.type),
    });
    Logger({ messages: ['New Coupon ID'], objs: { docRef } }, createCoupon);
    return true;
  } else {
    Logger(
      {
        messages: ['Coupon code already exists!'],
        objs: { coupon },
        type: DemmiLogType.error,
      },
      createCoupon
    );
    return false;
  }
};

export const checkCodeAvailability = async (
  couponCode: string
): Promise<boolean> => {
  Logger({ objs: { couponCode } }, checkCodeAvailability);
  const docSnap = await getDoc(doc(db, FSCollectionNames.COUPONS, couponCode));
  return !docSnap.exists();
};

export const getCoupons = async (vendorID: string): Promise<Coupon[]> => {
  Logger({ objs: { vendorID } }, getCoupons);
  const q = query(FSCollections.Coupons, where('vendorID', '==', vendorID));
  const querySnapshot = await getDocs(q);
  const coupons: Coupon[] = [];
  querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
    const c: FSCoupon = doc.data() as FSCoupon;
    if (c.expirationDate) {
      c.expirationDate = getDateFromFirestore(c.expirationDate);
    }
    if (c.startDate) {
      c.startDate = getDateFromFirestore(c.startDate);
    }
    c.type = getCouponType(c.type);
    coupons.push({
      code: doc.id,
      ...c,
    });
  });
  return coupons;
};

export const deleteCoupon = async (
  couponCode: string,
  vendorID: string
): Promise<any> => {
  Logger({ objs: { couponCode, vendorID } }, deleteCoupon);
  const docSnap = await getDoc(doc(db, FSCollectionNames.COUPONS, couponCode));
  if (docSnap.exists() && docSnap.data()['vendorID'] === vendorID) {
    const docRef = doc(FSCollections.Coupons, couponCode);
    return deleteDoc(docRef);
  }
};

export const updateCouponDates = async (coupon: Coupon): Promise<any> => {
  Logger({ objs: { coupon } }, updateCouponDates);
  const docRef = doc(db, FSCollectionNames.COUPONS, coupon.code);
  return updateDoc(docRef, {
    startDate: coupon.startDate ? coupon.startDate : null,
    expirationDate: coupon.expirationDate ? coupon.expirationDate : null,
  });
};
