import React from 'react';

import { NotificationType } from '@helpers/notification.helper';
import { FSNotificationDoc } from '@models/notifications.model';

import NotificationOrderNew from './partials/_notificationOrderNew';
import NotificationRefundRequest from './partials/_notificationRefundRequest';

interface Props {
  notifications: FSNotificationDoc[];
  onNavigate: () => void;
}

const NotificationsPanelContent: React.FC<Props> = ({
  notifications,
  onNavigate,
}) => {
  const CSSBlock = 'notifications-panel-content';

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__title`}>Notifications</div>
      <div className={`${CSSBlock}__content`}>
        {notifications.map((notification, i) =>
          [
            NotificationType.REFUND_REQUEST,
            NotificationType.REFUND_UPDATE,
          ].includes(notification.type) ? (
            <NotificationRefundRequest
              notification={notification}
              onNavigate={onNavigate}
              key={i}
            />
          ) : (
            <NotificationOrderNew
              notification={notification}
              onNavigate={onNavigate}
              key={i}
            />
          )
        )}
      </div>
    </div>
  );
};

export default NotificationsPanelContent;
