import {
  CollectionReference,
  DocumentData,
  collection,
} from 'firebase/firestore';
import { ReactNode, createContext, useContext } from 'react';

import { FSCollectionNames } from '@core/services/networkService/networkService';
import { FSCoupon } from '@models/coupons.model';
import { FSUserModel } from '@models/customer.model';
import {
  FSDemmiSupportChat,
  FSDemmiSupportMessage,
} from '@models/demmiSupport.model';
import { FSNotificationModel } from '@models/notifications.model';
import { FSOrder } from '@models/orders.model';
import { FSProduct, FSProductVariant } from '@models/product.model';
import { FSRefundRequest } from '@models/refundRequest.model';
import { FSOrderReview } from '@models/vendor-review.model';
import { FSVendors } from '@models/vendors.model';

import { db } from '../firebase';

export interface FirestoreProviderProps {
  children?: ReactNode;
}

export interface UserContextState {
  isAuthenticated: boolean;
  isLoading: boolean;
  id?: string;
}

export const UserStateContext = createContext<UserContextState>(
  {} as UserContextState
);

export const useUserContext = (): UserContextState => {
  return useContext(UserStateContext);
};

export interface FirestoreContextModel {
  vendorsCollection: CollectionReference<FSVendors>;
  productsCollection: CollectionReference<FSProduct>;
  variantsCollection: (
    pathSegments?: string[]
  ) => CollectionReference<FSProductVariant>;
}

export const FirestoreContext = createContext<FirestoreContextModel>(
  {} as FirestoreContextModel
);

export function useFirestore(): FirestoreContextModel {
  return useContext(FirestoreContext);
}

export const createCollection =
  <T = DocumentData,>(collectionName: string) =>
  (pathSegments: string[] = []) => {
    return collection(
      db,
      collectionName,
      ...pathSegments
    ) as CollectionReference<T>;
  };

export const FSCollections = {
  Categories: createCollection<FSOrder>(FSCollectionNames.CATEGORIES)(),
  Orders: createCollection<FSOrder>(FSCollectionNames.ORDERS)(),
  OrderReviews: createCollection<FSOrderReview>(FSCollectionNames.VENDORS),
  Vendors: createCollection<FSVendors>(FSCollectionNames.VENDORS)(),
  Coupons: createCollection<FSCoupon>(FSCollectionNames.COUPONS)(),
  Users: createCollection<FSUserModel>(FSCollectionNames.USERS)(),
  Products: createCollection<FSProduct>(FSCollectionNames.PRODUCTS)(),
  ProductVariants: createCollection<FSProductVariant>(
    FSCollectionNames.PRODUCTS
  ),
  RefundRequests: createCollection<FSRefundRequest>(
    FSCollectionNames.REFUND_REQUEST
  )(),
  Notifications: createCollection<FSNotificationModel>(
    FSCollectionNames.NOTIFICATIONS
  )(),
  DemmiSupport: createCollection<FSDemmiSupportChat>(
    FSCollectionNames.DEMMI_SUPPORT
  )(),
  DemmiSupportMessages: createCollection<FSDemmiSupportMessage>(
    FSCollectionNames.DEMMI_SUPPORT
  ),
};

export const FirestoreProvider = ({
  children,
}: FirestoreProviderProps): JSX.Element => {
  const values = {
    vendorsCollection: FSCollections.Vendors,
    productsCollection: FSCollections.Products,
    variantsCollection: FSCollections.ProductVariants,
  };

  return (
    <FirestoreContext.Provider value={values}>
      {children}
    </FirestoreContext.Provider>
  );
};
