import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { getFSDemmi } from '@core/services/networkService/functions/_demmi';
import { selectFSDemmi, setFSDemmi } from '@core/store/appSlice/appSlice';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import DemmiInput from '@demmi-ui/Input';
import { demmiRequest } from '@helpers/app.helper';
import { VendorReview } from '@models/vendor-review.model';

import ReviewsSidebarButton from './ReviewsSidebarButton';

interface Props {
  selectedReview?: VendorReview;
  onClick: (review: VendorReview) => void;
  onClickRefresh: () => void;
  reviews: VendorReview[];
}

const ReviewsSidebar: React.FC<Props> = ({
  selectedReview,

  onClickRefresh,
  reviews,
  onClick,
}) => {
  const CSSBlock = 'sidebar';
  const dispatch = useAppDispatch();
  const fsDemmi = useAppSelector(selectFSDemmi);
  const [filteredReviews, setFilteredReviews] = useState<VendorReview[]>([]);

  useEffect(() => {
    if (!fsDemmi) demmiRequest(getFSDemmi()).then(o => dispatch(setFSDemmi(o)));
  }, [fsDemmi]);

  useEffect(() => {
    filterReviews();
  }, [reviews]);

  const filterReviews = (filter?: string) => {
    const organised = reviews.reduce(
      (acc: VendorReview[], cur: VendorReview) => {
        if (filter && !cur.title.toLowerCase().includes(filter.toLowerCase()))
          return acc;
        else return [...acc, cur];
      },
      []
    );
    setFilteredReviews(organised);
  };
  return (
    <div className={`${CSSBlock} ${CSSBlock}--reviews`}>
      <DemmiCard darkOnly className={`${CSSBlock}__header`}>
        <div className={`${CSSBlock}__title`}>
          Reviews
          <div className={`${CSSBlock}__title-buttons`}>
            <DemmiIconButton
              faIcon={'fa-rotate-right'}
              onClick={onClickRefresh}
              size={IconButtonSize.SMALL}
            />
          </div>
        </div>
        <div className={`${CSSBlock}__search`}>
          <DemmiInput
            onChangeValue={val => filterReviews(val)}
            invert
            type="text"
            placeholder="Filter reviews by title"
          />
        </div>
      </DemmiCard>
      <div className={`${CSSBlock}__content`}>
        {filteredReviews.map((review, i) => (
          <ReviewsSidebarButton
            key={i}
            review={review}
            onClick={() => onClick(review)}
            active={selectedReview?.id === review.id}
          />
        ))}
      </div>
    </div>
  );
};

export default ReviewsSidebar;
