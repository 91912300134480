import React, { useState } from 'react';

interface DemmiToggleProps {
  text: string;
  isActive?: boolean;
  onToggle: (active: boolean) => void;
  disabled?: boolean;
}

const DemmiToggle: React.FC<DemmiToggleProps> = ({
  text,
  isActive,
  onToggle,
  disabled,
}) => {
  const CSSBlock = 'demmi-ui-toggle';
  const [active, setActive] = useState(!!isActive);

  return (
    <label
      className={`${CSSBlock} ${active ? `${CSSBlock}--active` : ``} ${
        disabled ? `${CSSBlock}--disabled` : ``
      }`}>
      <input
        type="checkbox"
        disabled={disabled}
        checked={active}
        onChange={() => {
          onToggle(!active);
          setActive(!active);
        }}
      />
      <span className={`${CSSBlock}__text`}>{text}</span>
      <span className={`${CSSBlock}__indicator`}></span>
    </label>
  );
};

export default DemmiToggle;
