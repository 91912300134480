import React from 'react';

interface Props {
  title: string;
  subtitle: string;
  value: string;
}

const DashboardBadge: React.FC<Props> = ({ title, subtitle, value }) => {
  const CSSBlock = 'dashboard-badge';

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__text`}>
        <div className={`${CSSBlock}__title`}>{title}</div>
        <div className={`${CSSBlock}__subtitle`}>{subtitle}</div>
      </div>
      <div className={`${CSSBlock}__value`}>{value}</div>
    </div>
  );
};

export default DashboardBadge;
