import { QueryDocumentSnapshot } from 'firebase/firestore';

import {
  DemmiSupportChat,
  DemmiSupportMessage,
  FSDemmiSupportChat,
  FSDemmiSupportMessage,
} from '@models/demmiSupport.model';

import { getDateFromFirestore } from '../networkService';

export const parseToDemmiSupportChat = (
  doc: QueryDocumentSnapshot<FSDemmiSupportChat>
): DemmiSupportChat => {
  const createdAt = getDateFromFirestore(doc.data()['createdAt']);
  return {
    id: doc.id,
    ...(doc.data() as FSDemmiSupportChat),
    createdAt,
  };
};

export const parseToDemmiSupportMessage = (
  doc: QueryDocumentSnapshot<FSDemmiSupportMessage>
): DemmiSupportMessage => {
  const timestamp = getDateFromFirestore(doc.data()['timestamp']);
  return {
    id: doc.id,
    ...(doc.data() as FSDemmiSupportMessage),
    timestamp,
  };
};
