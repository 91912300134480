import { MenuItem } from '@demmi-ui/Menu/MenuTypes';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Category, CategoryParent } from '@models/demmi.model';

export const CATEGORY_ID_OTHER = 'category_other';
export const getCategoryOther = (parentCategoryName: string): Category => ({
  id: CATEGORY_ID_OTHER,
  name: `Other (${parentCategoryName})`,
});

export const categoryToMenuItem = <T extends Category>(c: T): MenuItem<T> => {
  return {
    id: c.id,
    text: c.name,
    item: c,
  };
};

export const getMenuContent = <T extends Category>(
  categories: T[],
  optionalItems?: T[]
) => {
  return {
    sections: [
      {
        items: categories
          ? [
              ...categories
                .slice()
                .sort((a, b) =>
                  a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
                ),
              ...(optionalItems ?? []),
            ].map(c => categoryToMenuItem<T>(c))
          : [],
      },
    ],
  };
};

// Parent categories
const CATEGORY_ID_FRUITS = 'category_fruit';
const CATEGORY_ID_VEGETABLES = 'category_veg';
const CATEGORY_ID_SEAFOOD = 'category_fish';
const CATEGORY_ID_MEAT_POULTRY = 'category_meat';

// FRUIT
const CATEGORY_ID_FRUIT_MELONS_AND_PINEAPPLE = 'category_melons';
const CATEGORY_ID_FRUIT_BERRIES_AND_CHERRIES = 'category_berries';
const CATEGORY_ID_FRUIT_DATES_FIGS_NUTS = 'category_dates';
const CATEGORY_ID_FRUIT_BANANAS_GRAPES = 'category_banana';
const CATEGORY_ID_FRUIT_PEACHES_NECTARINES = 'category_peaches';
const CATEGORY_ID_FRUIT_RHUBARB = 'category_rhubarb';
const CATEGORY_ID_FRUIT_MANGO_KIWI_EXOTIC = 'category_mango';
const CATEGORY_ID_FRUIT_ORANGES_LEMON_CITRUS = 'category_oranges';
const CATEGORY_ID_FRUIT_DRIED_FRUIT = 'category_dried';
const CATEGORY_ID_FRUIT_APPLES_PEARS = 'category_apple';

// VEG
const CATEGORY_ID_VEG_PREPARED = 'category_prepared_veg';
const CATEGORY_ID_VEG_SALAD = 'category_salad_veg';
const CATEGORY_ID_VEG_ONIONS_LEEKS = 'category_onion';
const CATEGORY_ID_VEG_SPINACH_CABBAGE_GREENS = 'category_spinach';
const CATEGORY_ID_VEG_COURGETTE_AUBERGINE_SQUASH = 'category_courgette';
const CATEGORY_ID_VEG_CARROT_ROOT = 'category_carrot';
const CATEGORY_ID_VEG_BROCCOLI_CAULIFLOWER = 'category_broccoli';
const CATEGORY_ID_VEG_POTATOES = 'category_potato';
const CATEGORY_ID_VEG_EXOTIC = 'category_exotic_veg';
const CATEGORY_ID_VEG_PEAS_BEANS_SWEETCORN = 'category_peas';
const CATEGORY_ID_VEG_MUSHROOMS = 'category_mushrooms';

// SEAFOOD
const CATEGORY_ID_SEAFOOD_COD_HADDOCK_WHITE = 'category_cod';
const CATEGORY_ID_SEAFOOD_PRAWN_MUSSELS = 'category_prawn';
const CATEGORY_ID_SEAFOOD_SALMON_TUNA_TROUT = 'category_salmon';
const CATEGORY_ID_SEAFOOD_MACKEREL_SARDINE_HERRING = 'category_mackerel';

// MEAT
const CATEGORY_ID_MEAT_PORK = 'category_pork';
const CATEGORY_ID_MEAT_TURKEY = 'category_turkey';
const CATEGORY_ID_MEAT_CHICKEN = 'category_chicken';
const CATEGORY_ID_MEAT_BACON_GAMMON = 'category_bacon';
const CATEGORY_ID_MEAT_LAMB = 'category_lamb';
const CATEGORY_ID_MEAT_BEEF = 'category_beef';

export const getCategory = (
  categories: CategoryParent[] | undefined,
  categoryID: string
): { parent?: CategoryParent; child?: Category } | undefined => {
  if (!categories) return;
  let cat: { parent?: CategoryParent; child?: Category } = {};
  categories.find(parent => {
    if (parent.id === categoryID) {
      cat = { parent, child: getCategoryOther(parent.name) };
      return true;
    }
    parent.children.forEach(child => {
      if (child.id === categoryID) {
        cat = { parent, child };
      }
    });
    return false;
  });
  return cat;
};

export const getIconFromOnlyCategoryID = (categoryID: string) => {
  return _getIconFromID(categoryID);
};

export const getIconFromCategoryID = (
  categories: CategoryParent[] | undefined,
  categoryID?: string
): IconDefinition => {
  if (!categories) return icon({ name: 'question-circle', style: 'light' });
  if (categoryID === undefined)
    return icon({ name: 'question-circle', style: 'light' });
  let cat = getCategory(categories, categoryID);
  if (!cat) return icon({ name: 'question-circle', style: 'light' });

  return _getIconFromID(categoryID, cat?.parent?.id);
};

const _getIconFromID = (
  categoryID: string,
  parentID?: string
): IconDefinition => {
  // FRUITS
  if (categoryID == CATEGORY_ID_FRUIT_MELONS_AND_PINEAPPLE)
    return icon({ style: 'light', name: 'pineapple' });
  if (categoryID == CATEGORY_ID_FRUIT_BERRIES_AND_CHERRIES)
    return icon({ style: 'light', name: 'strawberry' });
  if (categoryID == CATEGORY_ID_FRUIT_DATES_FIGS_NUTS)
    return icon({ style: 'light', name: 'peanuts' });
  if (categoryID == CATEGORY_ID_FRUIT_BANANAS_GRAPES)
    return icon({ style: 'light', name: 'banana' });
  if (categoryID == CATEGORY_ID_FRUIT_PEACHES_NECTARINES)
    return icon({ style: 'light', name: 'peach' });
  // if (categoryID == CATEGORY_ID_FRUIT_RHUBARB) return FontAwesomeIcons.;
  if (categoryID == CATEGORY_ID_FRUIT_MANGO_KIWI_EXOTIC)
    return icon({ style: 'light', name: 'mango' });
  if (categoryID == CATEGORY_ID_FRUIT_ORANGES_LEMON_CITRUS)
    return icon({ style: 'light', name: 'lemon' });
  if (categoryID == CATEGORY_ID_FRUIT_DRIED_FRUIT)
    return icon({ style: 'light', name: 'melon-slice' });
  if (categoryID == CATEGORY_ID_FRUIT_APPLES_PEARS)
    return icon({ style: 'light', name: 'apple-whole' });

  // VEG
  if (categoryID == CATEGORY_ID_VEG_PREPARED)
    return icon({ style: 'light', name: 'salad' });
  if (categoryID == CATEGORY_ID_VEG_SALAD)
    return icon({ style: 'light', name: 'olive-branch' });
  if (categoryID == CATEGORY_ID_VEG_ONIONS_LEEKS)
    return icon({ style: 'light', name: 'onion' });
  if (categoryID == CATEGORY_ID_VEG_SPINACH_CABBAGE_GREENS)
    return icon({ style: 'light', name: 'leafy-green' });
  if (categoryID == CATEGORY_ID_VEG_COURGETTE_AUBERGINE_SQUASH)
    return icon({ style: 'light', name: 'eggplant' });
  if (categoryID == CATEGORY_ID_VEG_CARROT_ROOT)
    return icon({ style: 'light', name: 'carrot' });
  if (categoryID == CATEGORY_ID_VEG_BROCCOLI_CAULIFLOWER)
    return icon({ style: 'light', name: 'broccoli' });
  if (categoryID == CATEGORY_ID_VEG_POTATOES)
    return icon({ style: 'light', name: 'potato' });
  if (categoryID == CATEGORY_ID_VEG_EXOTIC)
    return icon({ style: 'light', name: 'pumpkin' });
  if (categoryID == CATEGORY_ID_VEG_PEAS_BEANS_SWEETCORN)
    return icon({ style: 'light', name: 'corn' });
  if (categoryID == CATEGORY_ID_VEG_MUSHROOMS)
    return icon({ style: 'light', name: 'mushroom' });

  // SEAFOOD
  if (categoryID == CATEGORY_ID_SEAFOOD_COD_HADDOCK_WHITE)
    return icon({ style: 'light', name: 'fish-fins' });
  if (categoryID == CATEGORY_ID_SEAFOOD_PRAWN_MUSSELS)
    return icon({ style: 'light', name: 'shrimp' });
  if (categoryID == CATEGORY_ID_SEAFOOD_SALMON_TUNA_TROUT)
    return icon({ style: 'light', name: 'fish-fins' });
  if (categoryID == CATEGORY_ID_SEAFOOD_MACKEREL_SARDINE_HERRING)
    return icon({ style: 'light', name: 'fish' });

  // MEAT
  if (categoryID == CATEGORY_ID_MEAT_PORK)
    return icon({ style: 'light', name: 'pig' });
  if (categoryID == CATEGORY_ID_MEAT_TURKEY)
    return icon({ style: 'light', name: 'turkey' });
  if (categoryID == CATEGORY_ID_MEAT_CHICKEN)
    return icon({ style: 'light', name: 'drumstick' });
  if (categoryID == CATEGORY_ID_MEAT_BACON_GAMMON)
    return icon({ style: 'light', name: 'bacon' });
  if (categoryID == CATEGORY_ID_MEAT_LAMB)
    return icon({ style: 'light', name: 'falafel' });
  if (categoryID == CATEGORY_ID_MEAT_BEEF)
    return icon({ style: 'light', name: 'steak' });

  if (categoryID == CATEGORY_ID_FRUITS || parentID == CATEGORY_ID_FRUITS)
    return icon({ name: 'strawberry', style: 'light' });
  if (
    categoryID == CATEGORY_ID_VEGETABLES ||
    parentID == CATEGORY_ID_VEGETABLES
  )
    return icon({ name: 'seedling', style: 'light' });
  if (categoryID == CATEGORY_ID_SEAFOOD || parentID == CATEGORY_ID_SEAFOOD)
    return icon({ name: 'fish', style: 'light' });
  if (
    categoryID == CATEGORY_ID_MEAT_POULTRY ||
    parentID == CATEGORY_ID_MEAT_POULTRY
  )
    return icon({ name: 'meat', style: 'light' });

  return icon({ name: 'question-circle', style: 'light' });
};
