import { useCallback, useEffect, useState } from 'react';

import DemmiInput from '@demmi-ui/Input';
import MenuStatic from '@demmi-ui/Menu/MenuStatic';
import { ValidRegions } from '@helpers/maps.helper';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';

const getMenuContent = (
  predictions: google.maps.places.AutocompletePrediction[]
) => {
  return {
    sections: [
      {
        items: predictions.map(p => ({
          id: p.place_id,
          text: p.description,
          item: p,
        })),
      },
    ],
  };
};

interface Props {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
}

export const AutocompleteCustom = ({ onPlaceSelect }: Props) => {
  const CSSBlock = 'gmap-autocomplete';
  const map = useMap();
  const places = useMapsLibrary('places');
  const [inputValue, setInputValue] = useState<string>('');

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteSessionToken
  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>();

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null);

  // https://developers.google.com/maps/documentation/javascript/reference/places-service
  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService | null>(null);

  const [predictionResults, setPredictionResults] = useState<
    Array<google.maps.places.AutocompletePrediction>
  >([]);

  useEffect(() => {
    if (!places || !map) return;
    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());
    return () => setAutocompleteService(null);
  }, [map, places]);

  const fetchPredictions = useCallback(
    async (value: string) => {
      if (!autocompleteService || !value) {
        setPredictionResults([]);
        return;
      }

      const request = {
        input: value,
        componentRestrictions: { country: ValidRegions },
        sessionToken,
      };
      const response = await autocompleteService.getPlacePredictions(request);
      setPredictionResults(response.predictions);
    },
    [autocompleteService, sessionToken]
  );

  const onInputChange = useCallback(
    (value: string) => {
      setInputValue(value);
      fetchPredictions(value);
    },
    [fetchPredictions]
  );

  const onPredictionClick = useCallback(
    (placeId: string) => {
      if (!places) return;

      const detailRequestOptions = {
        placeId,
        fields: ['geometry', 'name', 'formatted_address', 'address_components'],
        sessionToken,
      };

      const detailsRequestCallback = (
        placeDetails: google.maps.places.PlaceResult | null
      ) => {
        onPlaceSelect(placeDetails);
        setPredictionResults([]);
        setInputValue(placeDetails?.formatted_address ?? '');
        setSessionToken(new places.AutocompleteSessionToken());
      };

      placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
    },
    [onPlaceSelect, places, placesService, sessionToken]
  );

  return (
    <div className={CSSBlock}>
      <DemmiInput
        type="text"
        value={inputValue}
        onChangeValue={onInputChange}
        placeholder="Search for a place"
      />

      {predictionResults.length > 0 && (
        <MenuStatic
          content={getMenuContent(predictionResults)}
          onClick={pred => onPredictionClick(pred.place_id)}></MenuStatic>
      )}
    </div>
  );
};
