import { DemmiImageResource } from '@models/demmiImageResource.model';

export const IMAGE_FILE_EXT = '.jpeg';
export const IMAGE_EXT_MAIN = `_1024x1024${IMAGE_FILE_EXT}`;
export const IMAGE_EXT_THUMBNAIL = `_256x256${IMAGE_FILE_EXT}`;

export const generateProductImageName = (
  file: File,
  productID: string,
  vendorID: string
): string => {
  const extension = file.name.split(/\.(?=[^\.]+$)/);
  const filename = `${vendorID}-${productID}-${crypto.randomUUID()}.${
    extension[extension.length - 1]
  }`;
  return filename;
};

export const generateVendorImageName = (
  file: File,
  vendorID: string,
  vendorName: string
): string => {
  const extension = file.name.split(/\.(?=[^\.]+$)/);
  const filename = `${vendorID}-${vendorName}-${crypto.randomUUID()}.${
    extension[extension.length - 1]
  }`;
  return filename;
};

export const getImageResourceNames = (filename: string): DemmiImageResource => {
  const name = filename.substring(0, filename.lastIndexOf('.'));
  return {
    main: `${name}${IMAGE_EXT_MAIN}`,
    thumbnail: `${name}${IMAGE_EXT_THUMBNAIL}`,
  };
};
