import { Unsubscribe } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { listenToOrders } from '@core/services/networkService/orders/orders';
import { RoutingViews, routingPaths } from '@core/services/routingService';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiButton, { DemmiButtonSize } from '@demmi-ui/Button';
import { navigateTransitioned } from '@helpers/app.helper';
import {
  getIncomingOrders,
  sortOrdersByCollectionDate,
} from '@helpers/orders.helper';
import { Order } from '@models/orders.model';

import DashboardSection from '../DashboardSection';
import WidgetOrderItem from './WidgetOrderItem';

interface Props {}

const WidgetOrders: React.FC<Props> = ({}) => {
  const CSSBlock = 'widget-orders';

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const vendor = useAppSelector(selectVendor);
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    let snapshotListener: Unsubscribe | undefined;
    if (vendor) {
      const ordersCallback = (o: Order[]) => {
        const incomingOrders = getIncomingOrders(vendor.id, o);
        setOrders(sortOrdersByCollectionDate(incomingOrders));
        setIsLoading(false);
      };
      listenToOrders(vendor.id, ordersCallback).then(
        u => (snapshotListener = u)
      );
    }

    return () => {
      if (snapshotListener) snapshotListener();
    };
  }, [vendor]);

  return (
    <DashboardSection
      className={CSSBlock}
      isLoading={isLoading}
      title="Incoming Orders"
      titleButton={
        <DemmiButton
          text="View All"
          size={DemmiButtonSize.S}
          onClick={() => {
            navigateTransitioned({
              dispatch,
              navigate,
              to: routingPaths[RoutingViews.ORDERS_INCOMING],
            });
          }}
        />
      }>
      <div className={`${CSSBlock}__list`}>
        {orders.length > 0
          ? orders.map((order, i) => <WidgetOrderItem order={order} key={i} />)
          : isLoading === false && (
              <div style={{ textAlign: 'center', margin: '4rem auto' }}>
                No orders
              </div>
            )}
      </div>
    </DashboardSection>
  );
};

export default WidgetOrders;
