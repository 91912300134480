import { doc, getDoc } from 'firebase/firestore';

import { getIconFromOnlyCategoryID } from '@helpers/categories.helper';
import { FSDemmi, FSDemmiConstants } from '@models/demmi.model';

import { db } from '../../../../firebase';
import { FSCollectionNames } from '../networkService';

export const getFSDemmi = async (): Promise<FSDemmi | undefined> => {
  return getDoc(doc(db, FSCollectionNames.DEMMI, 'constants')).then(doc => {
    if (doc.exists()) {
      const fsDemmi: FSDemmi = {
        constants: doc.data() as FSDemmiConstants,
      };
      fsDemmi.constants.categories = fsDemmi.constants.categories.map(
        parent => ({
          ...parent,
          icon: getIconFromOnlyCategoryID(parent.id),
          children: parent.children.map(child => ({
            ...child,
            icon: getIconFromOnlyCategoryID(child.id),
          })),
        })
      );
      return fsDemmi;
    }
    return undefined;
  });
};
