import React, { useState } from 'react';

import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';

import SidebarButton from './SidebarButton';

interface SelfManaged extends Base {
  active: number;
}
interface Managed extends Base {}

interface Base {
  onClick: (id: string) => void;
  title: string;
  titleButton?: { faIcon: string; onClick: () => void };
  buttons: { id: string; text: string; faIcon?: string }[];
  initialIndex?: number;
}
type Props = Managed | SelfManaged;

const isSelfManaged = (props: Props): props is SelfManaged => {
  return 'active' in props;
};

const Sidebar: React.FC<Props> = props => {
  const CSSBlock = 'sidebar';
  const { title, titleButton, buttons, onClick, initialIndex } = props;

  const [active, setActive] = isSelfManaged(props)
    ? [props.active, () => {}]
    : useState(initialIndex ?? -1);

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__title`}>
        {title}
        {titleButton && (
          <DemmiIconButton
            faIcon={titleButton.faIcon}
            onClick={titleButton.onClick}
            size={IconButtonSize.SMALL}
          />
        )}
      </div>
      {buttons.map((button, i) => (
        <SidebarButton
          text={button.text}
          faIcon={button.faIcon}
          active={i === active}
          key={i}
          onClick={() => {
            onClick(button.id);
            if (!isSelfManaged(props)) {
              setActive(i);
            }
          }}
        />
      ))}
    </div>
  );
};

export default Sidebar;
