import React, { ReactElement } from 'react';

import { DemmiToastType } from './Toast';

interface Props {
  toasts: ReactElement<DemmiToastType> | Array<ReactElement<DemmiToastType>>;
}

const DemmiToastsArea: React.FC<Props> = ({ toasts }) => {
  const CSSBlock = 'demmi-ui-toasts-area';

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__list`}>{toasts}</div>
    </div>
  );
};

export default DemmiToastsArea;
