import React, { useEffect, useState } from 'react';

import ReactPortal from '@demmi-ui/ReactPortal';
import { DemmiSupportChat } from '@models/demmiSupport.model';

import DemmiSupportPanelChat from './partials/_chat';
import DemmiSupportPanelList from './partials/_list';
import DemmiSupportPanelNewChat from './partials/_newChat';

interface Props {
  chats: DemmiSupportChat[];
  selectedChatID: string | undefined;
  setSelectedChatID: (id: string | undefined) => void;
  onChatCreated: (id: string) => void;
  isOpen: boolean;
  onExited: () => void;
}

const DemmiSupportPanel: React.FC<Props> = ({
  chats,
  selectedChatID,
  setSelectedChatID,
  onChatCreated,
  isOpen,
  onExited,
}) => {
  const CSSBlock = 'demmi-support-panel';

  if (!isOpen) return null;
  const [modalClass, setModalClass] = useState('');

  useEffect(() => {
    requestAnimationFrame(() => setModalClass(`${CSSBlock}--visible`));
  }, []);

  useEffect(() => {
    const onKeyDown = (e: globalThis.KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, []);

  const onClose = () => {
    setModalClass('');
    setTimeout(() => onExited(), 300);
  };

  return (
    <ReactPortal wrapperId="demmi-support-panel">
      <div className={`${CSSBlock} ${modalClass}`}>
        <div className={`${CSSBlock}__backdrop`} onClick={onClose}></div>
        <div className={`${CSSBlock}__content`}>
          <div className={`${CSSBlock}__title`}>Chat with Demmi</div>
          <DemmiSupportPanelList
            selectedChatID={selectedChatID}
            chats={chats}
            onClickChat={chat => setSelectedChatID(chat.id)}
          />
          <DemmiSupportPanelNewChat onChatCreated={onChatCreated} />
          {selectedChatID && <DemmiSupportPanelChat chatID={selectedChatID} />}
        </div>
      </div>
    </ReactPortal>
  );
};

export default DemmiSupportPanel;
