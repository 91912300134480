import { query, where } from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';

import { FSSubCollectionNames } from '../networkService';

export const demmiSupportQuery = (vendorID: string) =>
  query(FSCollections.DemmiSupport, where('vendorID', '==', vendorID));

export const demmiSupportChatQuery = (chatID: string) =>
  // query(
  FSCollections.DemmiSupportMessages(
    [chatID, FSSubCollectionNames.DEMMI_SUPPORT_MESSAGES]
    // )
    // where('vendorID', '==', vendorID)
  );
