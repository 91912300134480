import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { updateVendorHours } from '@core/services/networkService/functions/_vendors';
import { selectVendor, setVendor } from '@core/store/appSlice/appSlice';
import DemmiButton from '@demmi-ui/Button';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiIconButton from '@demmi-ui/IconButton';
import InputTime from '@demmi-ui/InputTime/InputTime';
import { demmiRequest } from '@helpers/app.helper';
import {
  isVendorOpeningHoursValid,
  vendorOpeningHoursHasChanges,
} from '@helpers/vendors.helper';
import {
  EmptyOpeningHours,
  Vendor,
  VendorOpeningHours,
} from '@models/vendors.model';

import { CSSBLOCK_SETTINGS } from '../Settings';
import DaysList from './DaysList';
import Hours from './Hours';

interface Props {}

const SettingsCollectionSlots: React.FC<Props> = ({}) => {
  const CSSBlock = 'settings-collection-slots';
  const dispatch = useAppDispatch();
  const vendor = useAppSelector(selectVendor);
  const [hours, setHours] = useState<VendorOpeningHours>(EmptyOpeningHours);
  const [detectedChanges, setDetectedChanges] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [selectedDay, setSelectedDay] = useState(0);

  const setValues = (vendor: Vendor) => {
    setHours(vendor.shop?.openingHours);
  };

  useEffect(() => {
    if (vendor) setValues(vendor);
  }, [vendor]);

  useEffect(() => {
    if (vendor) {
      setDetectedChanges(
        vendorOpeningHoursHasChanges(vendor.shop.openingHours, hours)
      );
      setIsValid(isVendorOpeningHoursValid(hours));
    }
  }, [hours]);

  const discardChanges = () => {
    if (vendor) setValues(vendor);
  };

  const saveChanges = async () => {
    if (isValid && vendor) {
      const f: Vendor = {
        ...vendor,
        shop: {
          ...vendor.shop,
          openingHours: hours,
        },
      };
      demmiRequest(updateVendorHours(f)).then(() => dispatch(setVendor(f)));
    }
  };

  return (
    <div className={`${CSSBlock}`}>
      <DaysList selectedDay={selectedDay} onClickDay={setSelectedDay} />

      <DemmiCard rootClassName={`${CSSBlock}__details`}>
        <div className={`${CSSBlock}__slots`}>
          <div className={`${CSSBlock}__slot`}>
            <div className={`${CSSBlock}__slot-input-row`}>
              <div className={`${CSSBlock}__slot-input`}>
                <div className={`${CSSBlock}__slot-input-title`}>
                  Start Time
                </div>
                <InputTime value={540} onChange={val => {}} />
              </div>
              <div className={`${CSSBlock}__slot-input`}>
                <div className={`${CSSBlock}__slot-input-title`}>End Time</div>
                <InputTime value={600} onChange={val => {}} />
              </div>
            </div>
            <div className={`${CSSBlock}__slot-delete-button`}>
              <DemmiIconButton faIcon="fa-trash" variant="delete" />
            </div>
          </div>

          <div className={`${CSSBlock}__slot`}>
            <div className={`${CSSBlock}__slot-input-row`}>
              <div className={`${CSSBlock}__slot-input`}>
                <div className={`${CSSBlock}__slot-input-title`}>
                  Start Time
                </div>
                <InputTime value={540} onChange={val => {}} />
              </div>
              <div className={`${CSSBlock}__slot-input`}>
                <div className={`${CSSBlock}__slot-input-title`}>End Time</div>
                <InputTime value={600} onChange={val => {}} />
              </div>
            </div>
            <div className={`${CSSBlock}__slot-capacity`}></div>
            <div className={`${CSSBlock}__slot-delete-button`}>
              <DemmiIconButton faIcon="fa-trash" variant="delete" />
            </div>
          </div>
        </div>
        <DemmiButton text="Add Another Slot" faIcon="fa-plus" />

        <div className={`${CSSBlock}__buttons`}>
          <span style={{ alignSelf: 'center', display: 'flex', gap: '1rem' }}>
            <DemmiButton
              text={'Discard Changes'}
              faIcon="fa-ban"
              disabled={!detectedChanges}
              onClick={() => discardChanges()}
            />
            <DemmiButton
              text={'Save Changes'}
              faIcon="fa-regular fa-floppy-disk"
              disabled={!detectedChanges || !isValid}
              onClick={() => saveChanges()}
            />
          </span>
        </div>
      </DemmiCard>
    </div>
  );
};

export default SettingsCollectionSlots;
