import { Unsubscribe } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '@core/hooks/appHooks';
import {
  listenToOrder,
  listenToOrderReview,
} from '@core/services/networkService/orders/orders';
import { hasRefundRequest } from '@core/services/networkService/refundRequests/refundRequests';
import { SearchParams } from '@core/services/routingService';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiCard from '@demmi-ui/Card/Card';
import { demmiRequest } from '@helpers/app.helper';
import { getStatusFromOrder } from '@helpers/orderStatus.helper';
import { Order } from '@models/orders.model';
import { FSOrderReview } from '@models/vendor-review.model';
import { Vendor } from '@models/vendors.model';

import OrderItemCollectionInfo from './partials/_collection-info';
import OrderItemHeader from './partials/_header';
import OrderItemTabCustomer from './tabs/_tab-customer';
import OrderItemTabProducts from './tabs/_tab-products';
import OrderItemTabRefund from './tabs/_tab-refund/_tab-refund';
import OrderItemTabReview from './tabs/_tab-review';
import OrderItemTabTimeline from './tabs/_tab-timeline';

export enum OrdersPageTabs {
  TIMELINE = 'Timeline',
  PRODUCTS = 'Products',
  CUSTOMER_DETAILS = 'Customer Details',
  REVIEW = 'Review',
  REFUND_STATUS = 'Refund Status',
}

interface Props {
  orderDocID: string;
}

const OrderItem: React.FC<Props> = ({ orderDocID }) => {
  const CSSBlock = 'order-item';
  const vendor = useAppSelector(selectVendor);
  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState<Order>();
  const [orderReview, setOrderReview] = useState<FSOrderReview>();
  const [refundRequestID, setRefundRequestID] = useState<string>();
  const [status, setStatus] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  const tabTitles = [
    'Timeline',
    'Products',
    'Customer Details',
    'Review',
    'Refund Status',
    // 'Delivery Information',
    // 'Customer Information',
  ];
  const tabContents = [
    <OrderItemTabTimeline
      CSSBlock={CSSBlock}
      order={order!}
      vendorID={vendor!.id}
    />,
    <OrderItemTabProducts CSSBlock={CSSBlock} order={order!} />,
    <OrderItemTabCustomer CSSBlock={CSSBlock} order={order!} />,
    <OrderItemTabReview
      CSSBlock={CSSBlock}
      review={orderReview}
      vendorID={vendor!.id}
    />,

    <OrderItemTabRefund order={order!} refundRequestID={refundRequestID} />,
  ];

  const orderCallback = (vendor: Vendor) => (order?: Order) => {
    setOrder(order);
    if (order) setStatus(getStatusFromOrder(vendor.id, order));
  };
  const reviewCallback = (r?: FSOrderReview) => setOrderReview(r);

  useEffect(() => {
    let snapshotListeners: Unsubscribe[] = [];
    if (vendor) {
      listenToOrder(orderDocID, vendor.id, orderCallback(vendor)).then(u =>
        snapshotListeners.push(u)
      );
      listenToOrderReview(orderDocID, vendor.id, reviewCallback).then(u => {
        if (u) snapshotListeners.push(u);
      });
    }
    return () => snapshotListeners.forEach(snapshot => snapshot());
  }, [vendor, orderDocID]);

  useEffect(() => {
    if (searchParams.get(SearchParams.ACTIVE_TAB)) {
      const tabIndex = Object.values(OrdersPageTabs).findIndex(
        tab => tab === searchParams.get(SearchParams.ACTIVE_TAB)
      );
      if (tabIndex > -1) setActiveTab(tabIndex);
    } else {
      setActiveTab(0);
    }
  }, [searchParams]);

  useEffect(() => {
    if (order && vendor)
      demmiRequest(hasRefundRequest(order.id, vendor.id)).then(o =>
        setRefundRequestID(o)
      );
    else setRefundRequestID(undefined);
  }, [order, vendor]);

  const onClickTab = (i: number) => {
    searchParams.set(SearchParams.ACTIVE_TAB, Object.values(OrdersPageTabs)[i]);
    setSearchParams(searchParams);
    setActiveTab(i);
  };

  return (
    <div className={`${CSSBlock}__wrapper`}>
      {order && (
        <>
          <div className={CSSBlock}>
            <OrderItemHeader order={order} status={status} />
            <OrderItemCollectionInfo order={order} />
            {/* <OrderItemCosting
              CSSBlock={CSSBlock}
              order={order}
              status={status}
            /> */}
            <DemmiCard
              activeTab={activeTab}
              tabs={tabTitles}
              onClickTab={onClickTab}
              tabContents={tabContents}
              className={`${CSSBlock}__details`}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default OrderItem;
