import { AuthError, AuthErrorCodes } from 'firebase/auth';

import { Logger } from '@subhanhabib/demmilib';

export const prettifyAuthError = (error: AuthError): string => {
  Logger({ objs: { error } }, prettifyAuthError);
  if (error.code === AuthErrorCodes.INVALID_EMAIL) {
    return 'Your email address is not a valid email.';
  } else if (error.code === AuthErrorCodes.USER_DELETED) {
    // user not found
    return 'Your credentials cannot be found.';
  } else if (error.code === AuthErrorCodes.INVALID_PASSWORD) {
    return 'Your email/password do not match.';
  }
  return 'Something went wrong :(';
};
