import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { getOrders } from '@core/services/networkService/orders/orders';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { demmiRequest } from '@helpers/app.helper';
import {
  getIncomingOrders,
  sortOrdersByCollectionDate,
} from '@helpers/orders.helper';
import { Order } from '@models/orders.model';

import OrdersList from './OrdersList';

interface Props {}

const OrdersIncoming: React.FC<Props> = ({}) => {
  const vendor = useAppSelector(selectVendor);
  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    if (vendor) {
      demmiRequest(getOrders(vendor.id)).then(o => {
        const incomingOrders = getIncomingOrders(vendor.id, o);
        setOrders(sortOrdersByCollectionDate(incomingOrders));
      });
    }
  }, [vendor]);

  const onClickRefresh = () => {
    if (vendor?.id)
      demmiRequest(getOrders(vendor.id)).then(o => {
        const incomingOrders = getIncomingOrders(vendor.id, o);
        setOrders(sortOrdersByCollectionDate(incomingOrders));
      });
  };

  return <OrdersList orders={orders} onClickRefresh={onClickRefresh} />;
};

export default OrdersIncoming;
