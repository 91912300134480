import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { getFSDemmi } from '@core/services/networkService/functions/_demmi';
import {
  deleteProduct,
  getProducts,
} from '@core/services/networkService/functions/_product';
import { SearchParams } from '@core/services/routingService';
import {
  selectFSDemmi,
  selectVendor,
  setFSDemmi,
} from '@core/store/appSlice/appSlice';
import { demmiRequest } from '@helpers/app.helper';
import { CSSVARS, setCSSVar } from '@helpers/theme.helper';
import { Product } from '@models/product.model';

import InventorySidebar from './Sidebar';
import ProductDetails from './productDetails/ProductDetails';
import ProductVariants from './productVariants/ProductVariants';

interface Props {}

const Inventory: React.FC<Props> = ({}) => {
  const CSSBlock = 'inventory';
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const vendor = useAppSelector(selectVendor);
  const fsDemmi = useAppSelector(selectFSDemmi);
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>();
  const [livePreview, setLivePreview] = useState(false);

  useEffect(() => {
    if (!fsDemmi) demmiRequest(getFSDemmi()).then(o => dispatch(setFSDemmi(o)));
  }, [fsDemmi]);

  useEffect(() => {
    setProducts([]);
    if (vendor) {
      setCSSVar(CSSVARS.PHONE_PREVIEW_HUE, vendor.themeHue.toString());
      demmiRequest(getProducts(vendor.id)).then(f => {
        setProducts(f);
        if (searchParams.get(SearchParams.PRODUCT_ID)) {
          setSelectedProduct(
            f.find(p => p.id === searchParams.get(SearchParams.PRODUCT_ID))
          );
        } else {
          setSelectedProduct(undefined);
        }
      });
    }
  }, [vendor]);

  const onCreated = async (id: string) => {
    if (vendor) {
      await demmiRequest(getProducts(vendor.id)).then(products => {
        setProducts(products);
        setSelectedProduct(products.find(p => p.id === id));
      });
    }
  };

  const onClickRefresh = () => {
    if (vendor) {
      demmiRequest(getProducts(vendor.id)).then(f => setProducts(f));
      setSelectedProduct(undefined);
    }
  };

  const onClickProduct = (id: string) => {
    setSelectedProduct(undefined);
    setSearchParams({ [SearchParams.PRODUCT_ID]: id });
    setTimeout(() => {
      const product = products.find(p => p.id === id);
      if (product) setSelectedProduct(product);
    }, 50);
  };

  const onDelete = (id: string) => {
    demmiRequest(deleteProduct(id)).then(() => {
      setProducts([]);
      if (vendor)
        demmiRequest(getProducts(vendor.id)).then(products => {
          setProducts(products);
          setSelectedProduct(undefined);
        });
    });
  };

  return (
    <div
      className={`${CSSBlock} ${
        livePreview && selectedProduct ? `${CSSBlock}--live-preview` : ''
      }`}>
      <InventorySidebar
        active={selectedProduct?.id ?? ''}
        products={products}
        onClick={onClickProduct}
        onClickRefresh={onClickRefresh}
        onCreate={onCreated}
        onClickPreview={() => setLivePreview(!livePreview)}
      />
      <div className={`${CSSBlock}__details`}>
        {fsDemmi && selectedProduct && (
          <>
            <ProductDetails
              product={selectedProduct}
              onDelete={onDelete}
              fsDemmi={fsDemmi}
            />
            <ProductVariants product={selectedProduct} />
          </>
        )}
      </div>
    </div>
  );
};

export default Inventory;
