import React, { useRef, useState } from 'react';

import DemmiButton from '@demmi-ui/Button';
import DemmiCardArea from '@demmi-ui/Modal/ModalArea';
import DemmiCardButtonArea from '@demmi-ui/Modal/ModalButtonArea';
import DemmiModalCore, { DemmiModalCoreRef } from '@demmi-ui/Modal/ModalCore';

interface ModalDeleteProps {
  name: string;
  onClose: () => void;
  onConfirm: () => void;
}

const ModalDelete: React.FC<ModalDeleteProps> = ({
  name,
  onClose,
  onConfirm,
}) => {
  const CSSBlock = 'modal-delete';
  const modalRef = useRef<DemmiModalCoreRef>(null);
  const [isOpen, setIsOpen] = useState(false);

  const gracefulExit = (postExit: () => void) => {
    modalRef.current ? modalRef.current.gracefulClose(postExit) : postExit();
    setTimeout(() => {
      setIsOpen(false);
    }, 500);
  };

  const buttons = [
    <DemmiButton
      key={0}
      text="No"
      outlined
      onClick={() => gracefulExit(onClose)}
    />,
    <DemmiButton
      key={1}
      text="Yes"
      variant="delete"
      onClick={() => gracefulExit(() => onConfirm())}
    />,
  ];

  return (
    <>
      <DemmiButton
        text={'Delete'}
        faIcon="fa-trash"
        variant="delete"
        onClick={() => setIsOpen(true)}
      />
      <DemmiModalCore
        ref={modalRef}
        isOpen={isOpen}
        onExited={() => {
          setIsOpen(false);
          onClose();
        }}
        contentClassName={CSSBlock}
        allowBackdropClose={false}>
        <DemmiCardArea isDark>
          <div className={`${CSSBlock}__text`}>
            Are you sure you want to delete
          </div>
          <div className={`${CSSBlock}__name`}>{name}</div>
          <div className={`${CSSBlock}__description`}>
            Warning: This action cannot be undone.
          </div>
        </DemmiCardArea>
        <DemmiCardButtonArea buttons={buttons} />
      </DemmiModalCore>
    </>
  );
};

export default ModalDelete;
