import { QueryDocumentSnapshot } from 'firebase/firestore';

import { getNotificationType } from '@helpers/notification.helper';
import {
  FSNotificationDoc,
  FSNotificationModel,
} from '@models/notifications.model';

import { getDateFromFirestore } from '../networkService';

export const parseToNotification = (
  doc: QueryDocumentSnapshot<FSNotificationModel>
): FSNotificationDoc => {
  const timestamp = getDateFromFirestore(doc.data()['timestamp']);
  const type = getNotificationType(doc.data().type);

  return {
    docID: doc.id,
    ...(doc.data() as FSNotificationModel),
    timestamp,
    type,
  };
};
