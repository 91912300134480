import { Order } from '@models/orders.model';

/**
 * Order Status and Order Vendor Status
 */

export enum OrderStatus {
  CREATED = 'created',
  PAYMENT_SUCCESSFUL = 'payment-successful',
  PAYMENT_FAILED = 'payment-failed',
  READY = 'ready',
  COMPLETED = 'completed',
  CANCELLED_USER = 'cancelled-user',
  CANCELLED_AUTO = 'cancelled-auto',
  UNKNOWN = '-',
}

export enum OrderVendorStatus {
  CREATED = 'created',
  IN_PROGRESS = 'in-progress',
  READY_FOR_COLLECTION = 'ready-for-collection',
  COLLECTED = 'collected',
  CANCELLED = 'cancelled',
  REFUNDED = 'refunded',
  UNKNOWN = '-',
}

export const getOrderVendorStatusText = (status: OrderVendorStatus) => {
  switch (status) {
    case OrderVendorStatus.CREATED:
      return 'Created';
    case OrderVendorStatus.IN_PROGRESS:
      return 'In Progress';
    case OrderVendorStatus.READY_FOR_COLLECTION:
      return 'Ready for Collection';
    case OrderVendorStatus.COLLECTED:
      return 'Collected';
    case OrderVendorStatus.CANCELLED:
      return 'Cancelled';
    case OrderVendorStatus.REFUNDED:
      return 'Refunded';
    default:
      return '-';
  }
};

export const getOrderStatus = (status?: string): OrderStatus | undefined => {
  const match = Object.entries(OrderStatus).find(([_, val]) => val === status);
  return match ? match[1] : undefined;
};

export const getOrderVendorStatus = (
  status?: string
): OrderVendorStatus | undefined => {
  const match = Object.entries(OrderVendorStatus).find(
    ([_, val]) => val === status
  );
  return match ? match[1] : undefined;
};

export const getStatusFromOrder = (vendorID: string, order: Order) => {
  const status = order.timeline.findLast(
    update => !!update.statusUpdate && vendorID === update.vendorID
  )?.statusUpdate;
  return status ? getOrderVendorStatusText(status) : 'Status Unknown';
};
