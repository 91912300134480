import React, { PropsWithChildren } from 'react';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';

interface Props extends PropsWithChildren {
  className?: string;
  title?: string;
  titleButton?: JSX.Element;
  isLoading?: boolean;
}

const DashboardSection: React.FC<Props> = ({
  children,
  className,
  title,
  titleButton,
  isLoading,
}) => {
  const CSSBlock = 'dashboard-section';

  return (
    <div
      className={`${CSSBlock} ${className ? className : ''} ${
        isLoading ? `${CSSBlock}--loading` : ``
      }`}>
      <span className={`${CSSBlock}__content`}>
        {title && (
          <div className={`${CSSBlock}__title`}>
            {title}
            {titleButton && titleButton}
          </div>
        )}
        {children}
      </span>
      <ElementLoadingIndicator isLoading={isLoading ?? false} />
    </div>
  );
};

export default DashboardSection;
