import { FSOrderVendor, Order } from '@models/orders.model';

import { OrderVendorStatus } from './orderStatus.helper';

export const hasCoupon = (vendor: FSOrderVendor) =>
  vendor.coupon &&
  !!vendor.coupon.amount &&
  !!vendor.coupon.code &&
  !!vendor.coupon.type;

/**
 * Orders filtering
 */

export const _filterByVendorStatus = (
  vendorID: string,
  orders: Order[],
  statuses: OrderVendorStatus[]
): Order[] =>
  orders.filter(order => {
    const statusUpdate = order.timeline.findLast(
      update => !!update.statusUpdate && vendorID === update.vendorID
    )?.statusUpdate;
    return statusUpdate ? statuses.includes(statusUpdate) : false;
  });

export const getIncomingOrders = (vendorID: string, orders: Order[]): Order[] =>
  _filterByVendorStatus(vendorID, orders, [
    OrderVendorStatus.CREATED,
    OrderVendorStatus.IN_PROGRESS,
    OrderVendorStatus.READY_FOR_COLLECTION,
  ]);

export const getCompletedOrders = (
  vendorID: string,
  orders: Order[]
): Order[] =>
  _filterByVendorStatus(vendorID, orders, [OrderVendorStatus.COLLECTED]);

export const getTotalOrderCost = (orderVendor: FSOrderVendor) => {
  return orderVendor.products.reduce((acc, cur) => acc + cur.price, 0);
};

/**
 * Orders sorting
 */
export enum SORT_ORDERS_BY {
  COLLECTION_TIME_ASC = 'Collection Time (Asc)',
  COLLECTION_TIME_DESC = 'Collection Time (Desc)',
}

export const sortOrders = (
  orders: Order[],
  sortBy: SORT_ORDERS_BY = SORT_ORDERS_BY.COLLECTION_TIME_ASC
) => {
  return sortBy === SORT_ORDERS_BY.COLLECTION_TIME_ASC
    ? sortOrdersByCollectionDate(orders)
    : sortOrdersByCollectionDate(orders).reverse();
};

export const sortOrdersByCollectionDate = (orders: Order[]) => {
  return orders.sort((a, b) => {
    const dateA = a.vendor.collectionDate;
    const dateB = b.vendor.collectionDate;
    const timeA = a.vendor.collectionTime;
    const timeB = a.vendor.collectionTime;
    if (!dateA || !dateB) return 0;

    if (
      new Date(dateA.year, dateA.month - 1, dateA.day) ===
      new Date(dateB.year, dateB.month - 1, dateB.day)
    ) {
      if (!timeA || !timeB) return 0;
      return timeA < timeB ? -1 : 1;
    }

    return new Date(dateA.year, dateA.month - 1, dateA.day) <
      new Date(dateB.year, dateB.month - 1, dateB.day)
      ? -1
      : 1;
  });
};
