import {
  DocumentData,
  QueryDocumentSnapshot,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
} from 'firebase/firestore';

import { addDoc } from '@firebase/firestore';
import { FSProductVariant, ProductVariant } from '@models/product.model';
import { FSCollections } from '@providers/firestoreProvider';
import { Logger } from '@subhanhabib/demmilib';

import { FSSubCollectionNames } from '../networkService';

export const createProductVariant = async (
  productID: string,
  variant: FSProductVariant
): Promise<string> => {
  Logger({ objs: { productID, variant } }, createProductVariant);
  const docRef = await addDoc(
    FSCollections.ProductVariants([productID, FSSubCollectionNames.VARIANTS]),
    variant
  );
  return docRef.id;
};

export const getProductVariants = async (
  productID: string
): Promise<ProductVariant[]> => {
  Logger({ objs: { productID } }, getProductVariants);
  const querySnapshot = await getDocs(
    FSCollections.ProductVariants([productID, FSSubCollectionNames.VARIANTS])
  );
  const variants: ProductVariant[] = [];
  querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
    variants.push({
      id: doc.id,
      productID,
      ...(doc.data() as FSProductVariant),
    });
  });
  return variants;
};

export const updateProductVariant = async (
  variant: ProductVariant
): Promise<any> => {
  Logger({ objs: { variant } }, updateProductVariant);
  const docRef = doc(
    FSCollections.ProductVariants([
      variant.productID,
      FSSubCollectionNames.VARIANTS,
    ]),
    variant.id
  );
  return updateDoc(docRef, {
    name: variant.name,
    availableQuantity: variant.availableQuantity,
    minQuantity: variant.minQuantity,
    increments: variant.increments,
    pricePerUnit: variant.pricePerUnit,
    unit: variant.unit,
    ...(variant.weightPerItem ? { weightPerItem: variant.weightPerItem } : {}),
    ...(variant.unitPerItem ? { unitPerItem: variant.unitPerItem } : {}),
  });
};

export const deleteProductVariant = async (
  productID: string,
  variantID: string
): Promise<any> => {
  Logger({ objs: { productID, variantID } }, deleteProductVariant);
  const docRef = doc(
    FSCollections.ProductVariants([productID, FSSubCollectionNames.VARIANTS]),
    variantID
  );
  return deleteDoc(docRef);
};
