import { DateTime } from 'luxon';

import { Coupon } from '@models/coupons.model';
import { FSOrderVendorCoupon } from '@models/orders.model';
import { Vendor } from '@models/vendors.model';

/**
 * TYPES
 */

export enum CouponTypes {
  coupon_discount_fixed = 'Fixed Discount',
  coupon_discount_percent = 'Percentage Discount',
  coupon_service_charge = 'No Service Fee',
  invalid = 'Invalid Coupon Type',
}

export const getCouponType = (type: string): CouponTypes => {
  switch (type) {
    case 'coupon_discount_fixed':
      return CouponTypes.coupon_discount_fixed;
    case 'coupon_discount_percent':
      return CouponTypes.coupon_discount_percent;
    case 'coupon_service_charge':
      return CouponTypes.coupon_service_charge;
    default:
      return CouponTypes.invalid;
  }
};

export const getCouponTypeKey = (type: CouponTypes): string => {
  return Object.keys(CouponTypes)[Object.values(CouponTypes).indexOf(type)];
};

export const getCouponAmount = (
  currencySymbol: string,
  coupon: Coupon
): string => {
  switch (coupon.type) {
    case CouponTypes.coupon_discount_fixed:
      return `${currencySymbol}${coupon.amount}`;
    case CouponTypes.coupon_discount_percent:
      return `${coupon.amount * 100}%`;
    case CouponTypes.coupon_service_charge:
      return CouponTypes.coupon_service_charge;
    default:
      return coupon.amount.toString();
  }
};

export const getOrderCouponAmount = (
  currencySymbol: string,
  coupon: FSOrderVendorCoupon
) => {
  if (coupon.type == CouponTypes.coupon_discount_percent) {
    return `${coupon.amount * 100}%`;
  } else if (coupon.type == CouponTypes.coupon_discount_fixed) {
    return `${currencySymbol}${Number(coupon.amount).toFixed(2)}`;
  } else {
    return coupon.amount;
  }
};

export const generateCouponCodePrefix = (vendor: Vendor) => {
  const name = vendor.name.replace(/\B\w+/g, '').replace(/\s/g, '');
  return `${name}-`;
};

/**
 * STATUS
 */

export enum CouponStatus {
  enabled = 'Active',
  disabled = 'Inactive',
  expired = 'Expired',
  not_started = 'Not Started',
}

export const getCouponStatus = (coupon: Coupon) => {
  if (
    coupon.expirationDate &&
    DateTime.fromJSDate(coupon.expirationDate) < DateTime.now()
  ) {
    return CouponStatus.expired;
  } else if (
    coupon.startDate &&
    DateTime.fromJSDate(coupon.startDate) > DateTime.now()
  ) {
    return CouponStatus.not_started;
  } else if (coupon.enabled == false) {
    return CouponStatus.disabled;
  }
  return CouponStatus.enabled;
};

/**
 * VALIDATION
 */

export const isCouponDetailsValid = (
  code: string,
  amount: number,
  minSpend: number,
  type: CouponTypes,
  startDate?: string,
  expirationDate?: string
) => {
  return (
    code.length > 3 &&
    amount > 0 &&
    minSpend >= 0 &&
    getCouponTypeKey(type) !== undefined &&
    isCouponDatesValid(startDate, expirationDate)
  );
};

export const isCouponDatesValid = (
  startDate?: string,
  expirationDate?: string
) => {
  return (
    (startDate != null ? !isNaN(Date.parse(startDate)) : true) &&
    (expirationDate != null ? !isNaN(Date.parse(expirationDate)) : true) &&
    (startDate != null && expirationDate != null
      ? Date.parse(startDate) < Date.parse(expirationDate)
      : true)
  );
};
