import React from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import DemmiInput from '@demmi-ui/Input';
import DemmiTextArea from '@demmi-ui/TextArea';

interface Props {
  CSSBlock: string;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  shortDescription: string;
  setShortDescription: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
}

const Description: React.FC<Props> = ({
  CSSBlock,
  name,
  setName,
  shortDescription,
  setShortDescription,
  description,
  setDescription,
}) => {
  return (
    <DemmiCard title="Details" className={`${CSSBlock}__details-description`}>
      <DemmiInput
        type="text"
        placeholder="Product Name"
        value={name}
        onChangeValue={val => setName(val)}
      />
      <DemmiInput
        type="text"
        placeholder="Short Description"
        value={shortDescription}
        onChangeValue={val => setShortDescription(val)}
      />
      <DemmiTextArea
        placeholder="Product Description"
        value={description}
        maxLength={1000}
        onChange={val => setDescription(val)}
      />
    </DemmiCard>
  );
};

export default Description;
