import { isPossiblePhoneNumber } from 'react-phone-number-input/input';

import {
  OpeningHours,
  Vendor,
  VendorAddress,
  VendorOpeningHours,
} from '@models/vendors.model';

import { areObjectsIdentical } from './object.helper';

export const vendorDetailsHasChanges = (
  vendor: Vendor,
  updated: Omit<Vendor, 'id' | 'shop' | 'themeHue' | 'address'>
) => {
  return !(
    vendor.name === updated.name &&
    vendor.contactNumber === updated.contactNumber &&
    vendor.shortDescription === updated.shortDescription &&
    vendor.longDescription.join('\n') === updated.longDescription.join('\n') &&
    vendor.images.join() === updated.images.join()
  );
};

export const vendorOpeningHoursHasChanges = (
  prev?: VendorOpeningHours,
  updated?: VendorOpeningHours
): boolean => {
  return prev !== undefined && updated !== undefined
    ? Object.entries(prev).reduce((acc, [key, val]) => {
        return acc
          ? acc
          : _vendorOpeningHoursDayHasChanges(
              val,
              updated[key as keyof VendorOpeningHours]
            );
      }, false)
    : prev !== updated;
};

const _vendorOpeningHoursDayHasChanges = (
  prev: OpeningHours,
  updated: OpeningHours
) => {
  return !(prev.open == updated.open && prev.close == updated.close);
};

export const isVendorDetailsValid = (
  vendor: Omit<Vendor, 'id' | 'shop' | 'themeHue' | 'address'>
) => {
  return (
    vendor.name.length > 0 &&
    isContactNumberValid(vendor.contactNumber) &&
    vendor.shortDescription.length > 0 &&
    vendor.longDescription.join().replaceAll(' ', '').length > 0 &&
    vendor.images.find(
      image => image.main.length === 0 && image.thumbnail.length === 0
    ) === undefined
  );
};

export const vendorAddressHasChanges = (
  prevAddress: VendorAddress,
  updated: VendorAddress
) => {
  return (
    prevAddress.geo?.geohash !== updated.geo?.geohash ||
    prevAddress.geo?.geopoint.latitude !== updated.geo?.geopoint.latitude ||
    prevAddress.geo?.geopoint.longitude !== updated.geo?.geopoint.longitude ||
    !areObjectsIdentical(prevAddress.address, updated.address)
  );
};

export const isVendorAddressValid = (address: VendorAddress) => {
  return (
    // address.addressLine1.length > 0 &&
    // address.town.length > 0 &&
    // address.county.length > 0 &&
    // address.postCode.length > 0 &&
    !!address.geo?.geohash &&
    address.geo?.geohash.length > 0 &&
    !!address.geo.geopoint
  );
};

const isContactNumberValid = (num: string) => isPossiblePhoneNumber(num); // /^\d{11}$/.test(num);

export const isVendorOpeningHoursValid = (hours: VendorOpeningHours) => {
  return true;
};
