import React from 'react';

interface SignInErrorProps {
  error?: string;
}

const SignInError: React.FC<SignInErrorProps> = ({ error }) => {
  const CSSBlock = 'login-card-error';

  return (
    <div className={`${CSSBlock} ${error ? `${CSSBlock}--visible` : ''}`}>
      <div className={`${CSSBlock}__error`}>{error ?? ''}</div>
    </div>
  );
};

export default SignInError;
