import React, { useEffect, useState } from 'react';

import ReactPortal from '@demmi-ui/ReactPortal';
import { FSNotificationDoc } from '@models/notifications.model';

import NotificationsPanelContent from './NotificationsPanelContent';

interface Props {
  isOpen: boolean;
  onExited: () => void;
  notifications: FSNotificationDoc[];
}

const NotificationsPanel: React.FC<Props> = ({
  isOpen,
  onExited,
  notifications,
}) => {
  const CSSBlock = 'notifications-panel';

  if (!isOpen) return null;
  const [modalClass, setModalClass] = useState('');

  useEffect(() => {
    requestAnimationFrame(() => setModalClass(`${CSSBlock}--visible`));
  }, []);

  const onClose = () => {
    setModalClass('');
    setTimeout(() => onExited(), 300);
  };

  useEffect(() => {
    const onKeyDown = (e: globalThis.KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, []);

  return (
    <ReactPortal wrapperId="notifications-panel">
      <div className={`${CSSBlock} ${modalClass}`}>
        <div
          className={`${CSSBlock}__backdrop`}
          onClick={() => onClose()}></div>
        <NotificationsPanelContent
          onNavigate={() => onClose()}
          notifications={notifications}
        />
      </div>
    </ReactPortal>
  );
};

export default NotificationsPanel;
