import React from 'react';

import DaysListItem from './DaysListItem';

interface Props {
  selectedDay: number;
  onClickDay: (day: number) => void;
}

const DaysList: React.FC<Props> = ({ selectedDay, onClickDay }) => {
  const CSSBlock = 'days-list';

  const days = [
    {
      index: 0,
      name: 'Monday',
    },
    {
      index: 1,
      name: 'Tuesday',
    },
    {
      index: 2,
      name: 'Wednesday',
    },
    {
      index: 3,
      name: 'Thursday',
    },
    {
      index: 4,
      name: 'Friday',
    },
    {
      index: 5,
      name: 'Saturday',
    },
    {
      index: 6,
      name: 'Sunday',
    },
  ];

  return (
    <div className={`${CSSBlock}`}>
      {days.map((day, i) => (
        <DaysListItem
          active={selectedDay === day.index}
          name={day.name}
          key={i}
          index={i}
          onClick={onClickDay}
        />
      ))}
    </div>
  );
};

export default DaysList;
