import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { RoutingViews, routingPaths } from '@core/services/routingService';
import { selectFSDemmi } from '@core/store/appSlice/appSlice';
import DemmiButton, { DemmiButtonSize } from '@demmi-ui/Button';
import { navigateTransitioned } from '@helpers/app.helper';

import DashboardSection from '../DashboardSection';

interface Props {
  isLoading: boolean;
}

const WidgetAnalytics: React.FC<Props> = ({ isLoading }) => {
  const CSSBlock = 'widget-analytics';

  const dispatch = useAppDispatch();
  const fsDemmi = useAppSelector(selectFSDemmi);
  const navigate = useNavigate();

  return (
    <DashboardSection
      className={CSSBlock}
      title="Analytics"
      titleButton={
        <DemmiButton
          text="Show More"
          size={DemmiButtonSize.S}
          onClick={() => {
            navigateTransitioned({
              dispatch,
              navigate,
              to: routingPaths[RoutingViews.ANALYTICS],
            });
          }}
        />
      }>
      <div className={`${CSSBlock}__coming-soon`}>Coming Soon</div>
    </DashboardSection>
  );
};

export default WidgetAnalytics;
