import React, { useEffect, useState } from 'react';

import ModalCreateProduct from '@components/modals/CreateProduct/ModalCreateProduct';
import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { getFSDemmi } from '@core/services/networkService/functions/_demmi';
import { selectFSDemmi, setFSDemmi } from '@core/store/appSlice/appSlice';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import DemmiInput from '@demmi-ui/Input';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { demmiRequest } from '@helpers/app.helper';
import { getCategory, getIconFromCategoryID } from '@helpers/categories.helper';
import { Product } from '@models/product.model';

import InventorySidebarButton from './SidebarButton';

interface ProductCategory {
  icon: IconDefinition;
  categoryName: string;
  products: Product[];
}

interface CategorisedProducts {
  [key: string]: ProductCategory;
}

interface Props {
  active: string;
  onClick: (id: string) => void;
  onClickRefresh: () => void;
  onClickPreview: () => void;
  onCreate: (id: string) => void;
  products: Product[];
}

const InventorySidebar: React.FC<Props> = ({
  onCreate,
  onClickRefresh,
  onClickPreview,
  products,
  onClick,
  active,
}) => {
  const CSSBlock = 'sidebar';
  const dispatch = useAppDispatch();
  const fsDemmi = useAppSelector(selectFSDemmi);
  const [filteredProducts, setFilteredProducts] = useState<CategorisedProducts>(
    {}
  );

  useEffect(() => {
    if (!fsDemmi) demmiRequest(getFSDemmi()).then(o => dispatch(setFSDemmi(o)));
  }, [fsDemmi]);

  useEffect(() => {
    organiseProducts();
  }, [products]);

  const organiseProducts = (filter?: string) => {
    const organised = products.reduce(
      (acc: CategorisedProducts, cur: Product) => {
        if (filter && !cur.name.toLowerCase().includes(filter.toLowerCase()))
          return acc;
        if (!!acc[cur.categoryID]) {
          return {
            ...acc,
            [cur.categoryID]: {
              ...{ ...acc[cur.categoryID] },
              products: [...acc[cur.categoryID].products, cur],
            },
          };
        } else {
          const cat = getCategory(
            fsDemmi?.constants.categories,
            cur.categoryID
          );
          return {
            ...acc,
            [cur.categoryID]: {
              icon:
                cat?.child?.icon ||
                cat?.parent?.icon ||
                getIconFromCategoryID(
                  fsDemmi?.constants.categories,
                  cur.categoryID
                ),
              categoryName: cat?.child?.name || cat?.parent?.name || '',
              products: [cur],
            },
          };
        }
      },
      {}
    );
    setFilteredProducts(organised);
  };

  return (
    <div className={`${CSSBlock} ${CSSBlock}--inventory`}>
      <DemmiCard darkOnly className={`${CSSBlock}__header`}>
        <div className={`${CSSBlock}__title`}>
          Products
          <div className={`${CSSBlock}__title-buttons`}>
            <DemmiIconButton
              faIcon={'fa-rotate-right'}
              onClick={onClickRefresh}
              size={IconButtonSize.SMALL}
            />
            <ModalCreateProduct onClose={() => {}} onConfirm={onCreate} />
            <DemmiIconButton
              disabled={active.length === 0}
              faIcon={'fa-magnifying-glass'}
              onClick={onClickPreview}
              size={IconButtonSize.SMALL}
            />
          </div>
        </div>
        <div className={`${CSSBlock}__search`}>
          <DemmiInput
            onChangeValue={val => organiseProducts(val)}
            invert
            type="text"
            placeholder="Filter products"
          />
        </div>
      </DemmiCard>
      <div className={`${CSSBlock}__content`}>
        {Object.values(filteredProducts).map((category, i) => (
          <DemmiCard darkOnly key={i} rootClassName={`${CSSBlock}__category`}>
            <div className={`${CSSBlock}__category-title`}>
              <FontAwesomeIcon icon={category.icon} />
              {category.categoryName}
            </div>
            {category.products.map((product, i) => (
              <InventorySidebarButton
                product={product}
                active={product.id === active}
                key={i}
                onClick={() => {
                  onClick(product.id);
                  // if (!isSelfManaged(props)) {
                  //   setActive(i);
                  // }
                }}
              />
            ))}
          </DemmiCard>
        ))}
      </div>
    </div>
  );
};

export default InventorySidebar;
