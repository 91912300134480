import { DateTime } from 'luxon';
import React from 'react';

import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VendorReview } from '@models/vendor-review.model';

export interface Props {
  review: VendorReview;
  active?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

const ReviewsSidebarButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ review, active, disabled, onClick }, ref) => {
    const CSSBlock = 'sidebar-button--review';

    return (
      <button
        className={`${CSSBlock}  ${active ? `${CSSBlock}--active` : ``}`}
        disabled={disabled}
        onClick={onClick}
        ref={ref}>
        <div className={`${CSSBlock}__top`}>
          <div className={`${CSSBlock}__customer-name`}>
            {review.customer.title} {review.customer.firstName}{' '}
            {review.customer.lastName.substring(0, 1)}
          </div>
          <div className={`${CSSBlock}__rating`}>
            {[...Array(5)].map((_, i) => (
              <FontAwesomeIcon
                key={i}
                icon={
                  review.rating < i
                    ? icon({ style: 'light', name: 'star' })
                    : icon({ style: 'solid', name: 'star' })
                }
              />
            ))}
          </div>
        </div>
        <div className={`${CSSBlock}__content`}>
          <div className={`${CSSBlock}__title`}>{review.title}</div>
          <div className={`${CSSBlock}__body`}>{review.body}</div>
        </div>
        <div className={`${CSSBlock}__date`}>
          Reviewed on:{' '}
          {review.timestamp
            ? DateTime.fromJSDate(review.timestamp).toLocaleString()
            : '-'}
        </div>
      </button>
    );
  }
);

export default ReviewsSidebarButton;
