import React from 'react';

import DemmiIconButton from '@demmi-ui/IconButton';

interface Props {
  collapsible: boolean;
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  subtitle?: string;
  titleButtons?: JSX.Element[];
  titleExtras?: JSX.Element[];
}

const DemmiCardTitleArea: React.FC<Props> = ({
  collapsible,
  collapsed,
  setCollapsed,
  title,
  subtitle,
  titleButtons,
  titleExtras,
}) => {
  const CSSBlock = 'demmi-ui-card';

  return (
    <div className={`${CSSBlock}__title`}>
      <div className={`${CSSBlock}__title-text`}>
        {collapsible && (
          <DemmiIconButton
            key={'edit'}
            className={`${CSSBlock}__button-collapse`}
            variant="transparent"
            faIcon="fa-caret-up"
            onClick={() => setCollapsed(!collapsed)}
          />
        )}
        {subtitle ? (
          <span className={`${CSSBlock}__title-with-subtitle`}>
            {title}
            <span className={`${CSSBlock}__subtitle`}>{subtitle}</span>
          </span>
        ) : (
          <>{title}</>
        )}
      </div>
      {(titleButtons || titleExtras) && !collapsed && (
        <div className={`${CSSBlock}__extras`}>
          {titleExtras && titleExtras}
          {titleButtons && (
            <div className={`${CSSBlock}__control-buttons`}>{titleButtons}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default DemmiCardTitleArea;
