import { QueryDocumentSnapshot } from 'firebase/firestore';

import {
  getRefundRequestUpdatedBy,
  getRefundStatus,
  getRefundType,
} from '@helpers/refundRequest.helper';
import { FSRefundRequest, RefundRequest } from '@models/refundRequest.model';

import { getDateFromFirestore } from '../networkService';

export const parseToRefundRequest = (
  doc: QueryDocumentSnapshot<FSRefundRequest>
): RefundRequest => {
  const timestamp = getDateFromFirestore(doc.data()['timestamp']);
  const status = getRefundStatus(doc.data().status);
  const type = getRefundType(doc.data().type);

  return {
    id: doc.id,
    ...(doc.data() as FSRefundRequest),
    timestamp,
    status,
    type,
    updates: doc.data().updates.map(update => ({
      ...update,
      timestamp: getDateFromFirestore(update.timestamp)!,
      statusUpdate: getRefundStatus(update.statusUpdate),
      updatedBy: getRefundRequestUpdatedBy(update.updatedBy),
    })),
  };
};
