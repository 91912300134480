import {
  DocumentData,
  QueryDocumentSnapshot,
  Timestamp,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';

import { addDoc } from '@firebase/firestore';
import { DemmiImageResource } from '@models/demmiImageResource.model';
import { FSProduct, Product } from '@models/product.model';
import { FSCollections } from '@providers/firestoreProvider';
import { DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { db, storage } from '../../../../firebase';
import {
  FSCollectionNames,
  StoragePaths,
  getDateFromFirestore,
} from '../networkService';

export const createProduct = async (product: FSProduct): Promise<string> => {
  Logger({ objs: { product } }, createProduct);
  const docRef = await addDoc(FSCollections.Products, {
    ...product,
    isArchived: product.isArchived === undefined ? false : product.isArchived,
    createdAt: Timestamp.fromDate(new Date()),
    updatedAt: Timestamp.fromDate(new Date()),
  });
  return docRef.id;
};

export const getProducts = async (vendorID: string): Promise<Product[]> => {
  Logger({ objs: { vendorID } }, getProducts);
  const q = query(FSCollections.Products, where('vendorID', '==', vendorID));
  const querySnapshot = await getDocs(q);
  const products: Product[] = [];
  querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
    products.push({
      id: doc.id,
      ...(doc.data() as FSProduct),
    });
  });
  return products;
};

export const getProduct = async (
  productID: string
): Promise<Product | undefined> => {
  Logger({ objs: { productID } }, getProduct);
  const docSnap = await getDoc(doc(db, FSCollectionNames.PRODUCTS, productID));
  if (docSnap.exists()) {
    const createdAt = getDateFromFirestore(docSnap.data()['createdAt']);
    const updatedAt = getDateFromFirestore(docSnap.data()['updatedAt']);
    return {
      id: docSnap.id,
      ...(docSnap.data() as FSProduct),
      createdAt,
      updatedAt,
    };
  } else {
    Logger(
      {
        messages: ['No such document!'],
        objs: { productID },
        type: DemmiLogType.error,
      },
      getProduct
    );
  }
  return undefined;
};

export const updateProduct = async (product: Product): Promise<any> => {
  Logger({ objs: { product } }, updateProduct);
  const docRef = doc(FSCollections.Products, product.id);
  return updateDoc(docRef, {
    name: product.name,
    categoryID: product.categoryID,
    shortDescription: product.shortDescription,
    longDescription: product.longDescription,
    images: product.images,
    tags: product.tags,
    updatedAt: Timestamp.fromDate(new Date()),
  });
};

export const updateProductPublished = async (
  pID: string,
  isPublished: boolean
): Promise<any> => {
  Logger({ objs: { pID, isPublished } }, updateProductPublished);
  const docRef = doc(FSCollections.Products, pID);
  return updateDoc(docRef, {
    isPublished: isPublished,
    updatedAt: Timestamp.fromDate(new Date()),
  });
};

export const updateProductImages = async (
  id: string,
  images: DemmiImageResource[]
): Promise<any> => {
  Logger({ objs: { id, images } }, updateProductImages);
  const docRef = doc(FSCollections.Products, id);
  return updateDoc(docRef, {
    images,
    updatedAt: Timestamp.fromDate(new Date()),
  });
};

export const checkProductImageExists = async (
  vendorDocID: string,
  imageURL: string
) => {
  const pathReference = ref(
    storage,
    `/${vendorDocID}/${StoragePaths.PRODUCT_IMAGES}/${imageURL}`
  );
  return getDownloadURL(pathReference)
    .then(_ => true)
    .catch(_ => false);
};

export const deleteProduct = async (pID: string): Promise<any> => {
  Logger({ objs: { pID } }, deleteProduct);
  const docRef = doc(FSCollections.Products, pID);
  return deleteDoc(docRef);
};
