import React, { useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectVendor } from '@core/store/appSlice/appSlice';

import Badges from './Badges/Badges';
import WidgetAnalytics from './WidgetAnalytics/WidgetAnalytics';
import WidgetIncomingSchedule from './WidgetIncomingSchedule/WidgetIncomingSchedule';
import WidgetOrders from './WidgetOrders/WidgetOrders';
import WidgetReviews from './WidgetReviews/WidgetReviews';

interface HomeProps {}

const Home: React.FC<HomeProps> = ({}) => {
  const CSSBlock = 'home';

  const vendor = useAppSelector(selectVendor);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={CSSBlock}>
      {vendor && <WidgetIncomingSchedule vendor={vendor} />}
      <WidgetOrders />
      <WidgetReviews />
      <WidgetAnalytics isLoading={isLoading} />
      <Badges />
    </div>
  );
};

export default Home;
