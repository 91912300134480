import React, { useState } from 'react';

import AsyncImage from '@components/asyncImage/AsyncImage';
import ModalAddImage from '@components/modals/AddImage/ModalAddImage';
import { useAppSelector } from '@core/hooks/appHooks';
import {
  deleteVendorImage,
  uploadVendorImage,
} from '@core/services/networkService/functions/_storage';
import {
  checkVendorImageExists,
  updateVendorImages,
} from '@core/services/networkService/functions/_vendors';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiIconButton from '@demmi-ui/IconButton';
import DemmiInput from '@demmi-ui/Input';
import { demmiRequest } from '@helpers/app.helper';
import { generateVendorImageName } from '@helpers/image.helper';
import { DemmiImageResource } from '@models/demmiImageResource.model';
import { DemmiLogType, Logger } from '@subhanhabib/demmilib';

interface Props {
  images: DemmiImageResource[];
  setImages: React.Dispatch<React.SetStateAction<DemmiImageResource[]>>;
}

const Images: React.FC<Props> = ({ images, setImages }) => {
  const CSSBlock = 'settings-images';
  const vendor = useAppSelector(selectVendor);
  const [isDoingRequest, setIsDoingRequest] = useState(false);

  const onClickDelete = (i: number) => {
    if (vendor) {
      setIsDoingRequest(true);
      demmiRequest(deleteVendorImage(vendor.id, i)).then(res => {
        if (res === false) {
          // TODO: show error toast
        } else {
          const updatedImages = [...images.slice(0, i), ...images.slice(i + 1)];
          setImages(updatedImages);
        }
        setIsDoingRequest(false);
      });
    }
  };

  const checkImageExistsWithRetry = async (
    vendorID: string,
    imageResource: string
  ): Promise<boolean> => {
    for (let attempt = 0; attempt < 5; attempt++) {
      const exists = await checkVendorImageExists(vendorID, imageResource);
      if (exists) return true;
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
    Logger(
      {
        messages: ['Image failed to be found after 5 attempts'],
        objs: { imageResource },
        type: DemmiLogType.error,
      },
      checkImageExistsWithRetry
    );
    return false;
  };

  const onImageConfirm = (file: File) => {
    if (vendor) {
      const filename = generateVendorImageName(file, vendor.id, vendor.name);
      Logger({ objs: { file, filename } }, onImageConfirm);

      setIsDoingRequest(true);
      demmiRequest(uploadVendorImage(vendor.id, file, filename)).then(
        imageResource => {
          if (imageResource) {
            demmiRequest(
              updateVendorImages(vendor.id, [...images, imageResource])
            ).then(async () => {
              await demmiRequest(
                checkImageExistsWithRetry(vendor.id, imageResource.main)
              );
              setImages([...images, imageResource]);
              setIsDoingRequest(false);
            });
          } else {
            // TODO: Add toast.
            setIsDoingRequest(false);
          }
        }
      );
    }
  };

  return (
    <DemmiCard
      title="Images"
      className={CSSBlock}
      titleButtons={[
        <ModalAddImage onClose={() => {}} onConfirm={onImageConfirm} />,
      ]}>
      <div className={`${CSSBlock}__grid`}>
        {images.map((image, i) => (
          <div className={`${CSSBlock}__image-item`} key={i}>
            <div className={`${CSSBlock}__image-wrapper`}>
              <AsyncImage src={image.main} isVendor />
            </div>
            <div className={`${CSSBlock}__image-details`}>
              <DemmiInput
                key={i}
                type="text"
                value={image.main}
                disabled
                onChangeValue={val => {}}
              />
              <DemmiIconButton
                faIcon="fa-regular fa-trash-can"
                onClick={() => onClickDelete(i)}
              />
            </div>
          </div>
        ))}
      </div>
    </DemmiCard>
  );
};

export default Images;
