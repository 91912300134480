import { doc, orderBy, query, where } from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';

export const toastNotificationsQuery = (vendorID: string, startingDate: Date) =>
  query(
    FSCollections.Notifications,
    where('vendorID', '==', vendorID),
    where('dismissed', '==', false),
    where('timestamp', '>', startingDate),
    orderBy('timestamp', 'desc')
  );

export const notificationsQuery = (vendorID: string) =>
  query(
    FSCollections.Notifications,
    where('vendorID', '==', vendorID),
    where('dismissed', '==', false),
    orderBy('timestamp', 'desc')
  );

export const notificationsIncDismissedQuery = (vendorID: string) =>
  query(
    FSCollections.Notifications,
    where('vendorID', '==', vendorID),
    orderBy('timestamp', 'desc')
  );

export const notificationQuery = (notificationID: string) =>
  doc(FSCollections.Notifications, notificationID);
