import { GeoPoint } from 'firebase/firestore';
import Geohash from 'latlon-geohash';

import { VendorAddress, VendorGeo } from '@models/vendors.model';
import { DemmiLogType, Logger } from '@subhanhabib/demmilib';

import {
  regionAddressFields_GB,
  regionAddressFields_US,
} from './address.helper';

export const ValidRegions = ['GB'];
// export const ValidRegions = ['GB', 'US', 'CA', 'DE'];

export const isValidRegion = (countryCode: string) =>
  ValidRegions.includes(countryCode);

export const DefaultLatLng = [52.1988421, 0.1199872]; // Cambridge

const gApiPlacesFields_GB = {
  [regionAddressFields_GB.ADDRESS_LINE_1]: ['street_number', 'route'],
  [regionAddressFields_GB.ADDRESS_LINE_2]: [],
  [regionAddressFields_GB.TOWN]: ['postal_town'],
  [regionAddressFields_GB.COUNTY]: ['administrative_area_level_2'],
  [regionAddressFields_GB.POSTCODE]: ['postal_code'],
};
const gApiPlacesFields_US = {
  [regionAddressFields_US.ADDRESS_LINE_1]: ['street_number', 'route'],
  [regionAddressFields_US.ADDRESS_LINE_2]: [],
  [regionAddressFields_US.CITY]: ['locality'],
  [regionAddressFields_US.STATE]: ['administrative_area_level_1'],
  [regionAddressFields_US.ZIP]: ['postal_code'],
};

/**
 *
 */
export const mapGAPIPlaceToCountryCode = (
  addressComponents: google.maps.GeocoderAddressComponent[]
) => {
  return addressComponents.find(comp => comp.types.includes('country'))
    ?.short_name;
};

/**
 *
 */
export const mapGAPIPlaceToRegionAddress = (
  countryCode: string,
  addressComponents: google.maps.GeocoderAddressComponent[]
) => {
  if (countryCode == 'US')
    return _mapGAPIPlaceToRegionAddress(gApiPlacesFields_US, addressComponents);
  if (countryCode == 'GB')
    return _mapGAPIPlaceToRegionAddress(gApiPlacesFields_GB, addressComponents);
};
const _mapGAPIPlaceToRegionAddress = (
  gApiPlacesFields: { [key: string]: string[] },
  addressComponents: google.maps.GeocoderAddressComponent[]
) => {
  const res = Object.entries(gApiPlacesFields).reduce((acc, [key, value]) => {
    const component = value
      .map(v => {
        const comp = addressComponents.find(comp => comp.types.includes(v));
        return comp ? comp.long_name : '';
      })
      .join(' ');
    return { ...acc, [key]: component };
  }, {});
  return res;
};

/**
 *
 */
const _getGeoPointFromViewport = (viewport: google.maps.LatLngBounds) => {
  const lowLat = viewport.getSouthWest().lat();
  const lowLng = viewport.getSouthWest().lng();
  const highLat = viewport.getNorthEast().lat();
  const highLng = viewport.getNorthEast().lng();
  Logger(
    { objs: { lowLat, lowLng, highLat, highLng } },
    _getGeoPointFromViewport
  );
  if (!lowLat || !lowLat || !highLat || !highLng) return;

  const lat = (lowLat + highLat) / 2;
  const lng = (lowLng + highLng) / 2;
  return new GeoPoint(lat, lng);
};

/**
 *
 */
const _getGeoHashFromGeoPoint = (geopoint: GeoPoint) => {
  return Geohash.encode(geopoint.latitude, geopoint.longitude, 8);
};

/**
 *
 */
export const getVendorGeoFromViewport = (
  viewport: google.maps.LatLngBounds
): VendorGeo | undefined => {
  const geopoint = _getGeoPointFromViewport(viewport);
  if (geopoint) {
    const geohash = _getGeoHashFromGeoPoint(geopoint);
    return { geohash, geopoint };
  } else {
    Logger(
      {
        messages: ['Failed to parse geopoint from viewport'],
        objs: {
          viewport,
          getSouthWest: viewport.getSouthWest(),
          getNorthEast: viewport.getNorthEast(),
        },
        type: DemmiLogType.error,
      },
      getVendorGeoFromViewport
    );
    return;
  }
};

/**
 * From a Google Maps Places API result, parse to a VendorAddress object
 */
export const mapPlaceToAddress = (
  countryCode: string,
  selectedPlace: google.maps.places.PlaceResult
) => {
  const viewport = selectedPlace.geometry?.viewport;
  if (!viewport) {
    Logger(
      {
        messages: ['Failed to get viewport'],
        objs: { selectedPlace },
        type: DemmiLogType.error,
      },
      mapPlaceToAddress
    );
    return;
  }

  const components = selectedPlace.address_components;
  if (!components) {
    Logger(
      {
        messages: ['Failed to get address components'],
        objs: { selectedPlace },
        type: DemmiLogType.error,
      },
      mapPlaceToAddress
    );
    return;
  }

  const parsedAddress = mapGAPIPlaceToRegionAddress(countryCode, components);
  if (!parsedAddress) {
    Logger(
      {
        messages: ['Failed to get parse address components'],
        objs: { selectedPlace },
        type: DemmiLogType.error,
      },
      mapPlaceToAddress
    );
    return;
  }

  const itemCountryCode = mapGAPIPlaceToCountryCode(components);
  if (!itemCountryCode || itemCountryCode != countryCode) {
    Logger(
      {
        messages: ['Failed to match country code'],
        objs: { selectedPlace },
        type: DemmiLogType.error,
      },
      mapPlaceToAddress
    );
    return;
  }

  const vendorGeo = getVendorGeoFromViewport(viewport);
  if (!vendorGeo) {
    Logger(
      {
        messages: ['Failed to get vendor geo'],
        objs: { selectedPlace },
        type: DemmiLogType.error,
      },
      mapPlaceToAddress
    );
    return;
  }

  const updatedVendorAddress: VendorAddress = {
    countryCode: itemCountryCode,
    geo: vendorGeo,
    address: parsedAddress,
  };

  Logger({ objs: { updatedVendorAddress } }, mapPlaceToAddress);
  return updatedVendorAddress;
};
