import React, { useEffect, useState } from 'react';

import ModalDelete from '@components/modals/ModalDelete';
import { useAppSelector } from '@core/hooks/appHooks';
import { updateProductVariant } from '@core/services/networkService/functions/_productVariants';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiIconButton from '@demmi-ui/IconButton';
import { demmiRequest } from '@helpers/app.helper';
import { getCurrencySymbolFromCountryCode } from '@helpers/currency.helper';
import {
  NEW_PRODUCT_VARIANT_TEMP_ID,
  isProductVariantValid,
  variantHasChanges,
} from '@helpers/products.helper';
import { WeightUnit } from '@helpers/units.helper';
import { ProductVariant } from '@models/product.model';

import ProductVariantInputs from './ProductVariantInputs';

interface Props {
  variant: ProductVariant;
  onUpdate: (variant: ProductVariant) => void;
  onClickDelete: () => void;
}

const ProductVariantFC: React.FC<Props> = ({
  variant,
  onUpdate,
  onClickDelete,
}) => {
  const CSSBlock = 'product-variant';
  const vendor = useAppSelector(selectVendor);
  const [name, setName] = useState<string>('');
  const [minQuantity, setMinQuantity] = useState<number>(0);
  const [availableQuantity, setAvailableQuantity] = useState<number>(0);
  const [pricePerUnit, setPricePerUnit] = useState<number>(0);
  const [increments, setIncrements] = useState<number>(1);
  const [unit, setUnit] = useState<WeightUnit>(WeightUnit.kilogram);
  // if unit === "weight_each"
  const [weightPerItem, setWeightPerItem] = useState<number>(0);
  const [unitPerItem, setUnitPerItem] = useState<WeightUnit>(
    WeightUnit.undefined
  );

  const [detectedChanges, setDetectedChanges] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const setValues = (variant: ProductVariant) => {
    setName(variant.name);
    setMinQuantity(variant.minQuantity);
    setAvailableQuantity(variant.availableQuantity);
    setPricePerUnit(variant.pricePerUnit);
    setIncrements(variant.increments);
    setUnit(variant.unit);
    if (variant.weightPerItem) setWeightPerItem(variant.weightPerItem);
    if (variant.unitPerItem) setUnitPerItem(variant.unitPerItem);
  };

  useEffect(() => {
    setValues(variant);
  }, [variant]);

  useEffect(() => {
    setDetectedChanges(
      variantHasChanges(variant, {
        name,
        availableQuantity,
        minQuantity,
        increments,
        pricePerUnit,
        unit,
        weightPerItem,
        unitPerItem,
      })
    );
    setIsValid(
      isProductVariantValid({
        name,
        availableQuantity,
        minQuantity,
        increments,
        pricePerUnit,
        unit,
        weightPerItem,
        unitPerItem,
      })
    );
  }, [
    variant,
    name,
    availableQuantity,
    minQuantity,
    increments,
    pricePerUnit,
    unit,
    weightPerItem,
    unitPerItem,
  ]);

  const discardChanges = () => setValues(variant);

  const saveChanges = async () => {
    if (name.length > 0) {
      const v: ProductVariant = {
        id: variant.id,
        productID: variant.productID,
        name,
        minQuantity,
        availableQuantity,
        pricePerUnit,
        increments,
        unit,
        weightPerItem,
        unitPerItem,
      };

      demmiRequest(updateProductVariant(v)).then(() => onUpdate(v));
    }
  };

  return (
    <DemmiCard className={`${CSSBlock}`}>
      <ProductVariantInputs
        currencyCode={getCurrencySymbolFromCountryCode(
          vendor?.address?.countryCode ?? ''
        )}
        name={name}
        setName={setName}
        minQuantity={minQuantity}
        setMinQuantity={setMinQuantity}
        availableQuantity={availableQuantity}
        setAvailableQuantity={setAvailableQuantity}
        pricePerUnit={pricePerUnit}
        setPricePerUnit={setPricePerUnit}
        increments={increments}
        setIncrements={setIncrements}
        unit={unit}
        setUnit={setUnit}
        weightPerItem={weightPerItem}
        setWeightPerItem={setWeightPerItem}
        unitPerItem={unitPerItem}
        setUnitPerItem={setUnitPerItem}
      />
      <div className={`${CSSBlock}__buttons-row`}>
        <ModalDelete
          onClose={() => {}}
          onConfirm={onClickDelete}
          name={variant.name}
        />
        <DemmiIconButton
          faIcon="fa-ban"
          disabled={
            variant.id !== NEW_PRODUCT_VARIANT_TEMP_ID && detectedChanges
          }
          onClick={discardChanges}
        />
        <DemmiIconButton
          faIcon="fa-regular fa-floppy-disk"
          disabled={detectedChanges || !isValid}
          onClick={saveChanges}
        />
      </div>
    </DemmiCard>
  );
};

export default ProductVariantFC;
