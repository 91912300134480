import React, { PropsWithChildren } from 'react';
import { Navigate, useOutlet } from 'react-router-dom';

import AppTheme from '@core/AppTheme';
import { RoutingViews, routingPaths } from '@core/services/routingService';
import { useAuth } from '@providers/authProvider';

const UnprotectedRoute: React.FC = () => {
  const { user } = useAuth();
  const outlet = useOutlet();
  return user ? (
    <Navigate to={routingPaths[RoutingViews.HOME]} />
  ) : (
    <React.Fragment>
      <AppTheme isLogin={true} />
      {outlet}
    </React.Fragment>
  );
};
export default UnprotectedRoute;
