import React, { useEffect, useState } from 'react';

import DemmiCard from '@demmi-ui/Card/Card';

interface Props {
  name: string;
  index: number;
  active: boolean;
  onClick: (day: number) => void;
}

const DaysListItem: React.FC<Props> = ({ name, active, index, onClick }) => {
  const CSSBlock = 'days-list-item';
  return (
    <DemmiCard
      rootClassName={`${CSSBlock} ${active ? `${CSSBlock}--active` : ''}`}>
      <div onClick={() => onClick(index)}>
        <div className={`${CSSBlock}__name`}>{name}</div>
      </div>
    </DemmiCard>
  );
};

export default DaysListItem;
