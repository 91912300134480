import React from 'react';

import { formatCollectionDate, timeToString } from '@helpers/date.helper';
import { Order } from '@models/orders.model';

interface Props {
  order: Order;
}

const OrderItemCollectionInfo: React.FC<Props> = ({ order }) => {
  const CSSBlock = 'order-item-collection';

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__title`}>Collection Code</div>
      <div className={`${CSSBlock}__code`}>
        {order.collectionCode.split('').map((e, i) => (
          <span key={i}>{e}</span>
        ))}
      </div>
      <div className={`${CSSBlock}__details`}>
        <div className={`${CSSBlock}__key-val`}>
          <div className={`${CSSBlock}__key`}>Collection Date</div>
          <div className={`${CSSBlock}__value`}>
            {order.vendor.collectionDate
              ? formatCollectionDate(order.vendor.collectionDate)
              : 'No date given'}
          </div>
        </div>
        <div className={`${CSSBlock}__key-val`}>
          <div className={`${CSSBlock}__key`}>Collection Time</div>
          <div className={`${CSSBlock}__value`}>
            {order.vendor.collectionTime
              ? `${timeToString(order.vendor.collectionTime)} - ${timeToString(
                  order.vendor.collectionTime + 30
                )}`
              : 'No time given'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderItemCollectionInfo;
