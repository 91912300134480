import React from 'react';

import AsyncImage from '@components/asyncImage/AsyncImage';
import { useAppSelector } from '@core/hooks/appHooks';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCurrencySymbolFromCountryCode } from '@helpers/currency.helper';

interface Props {
  productImages: string[];
}

const DisplayShop: React.FC<Props> = ({ productImages }) => {
  const CSSBlock = 'display-shop';
  const vendor = useAppSelector(selectVendor);

  return (
    <div className={`${CSSBlock}__app`}>
      <div className={`${CSSBlock}__header`}>
        <div className={`${CSSBlock}__title`}>
          <span className={`${CSSBlock}__icon`}>
            <FontAwesomeIcon
              icon={icon({ style: 'solid', name: 'chevron-left' })}
            />
          </span>
          Field & Flowers
          <span className={`${CSSBlock}__icon`}>
            <FontAwesomeIcon
              icon={icon({ style: 'solid', name: 'basket-shopping' })}
            />
          </span>
        </div>
        <div className={`${CSSBlock}__buttons-row`}>
          <div className={`${CSSBlock}__buttons-row-button`}>
            <span className={`${CSSBlock}__icon`}>
              <FontAwesomeIcon
                icon={icon({ style: 'regular', name: 'face-awesome' })}
              />
            </span>
          </div>
          <div className={`${CSSBlock}__buttons-row-button`}>
            <span className={`${CSSBlock}__icon`}>
              <FontAwesomeIcon
                icon={icon({ style: 'regular', name: 'phone' })}
              />
            </span>
          </div>
          <div className={`${CSSBlock}__buttons-row-button`}>
            <span className={`${CSSBlock}__icon`}>
              <FontAwesomeIcon
                icon={icon({ style: 'regular', name: 'circle-info' })}
              />
            </span>
          </div>
          <div className={`${CSSBlock}__buttons-row-button`}>
            <span className={`${CSSBlock}__icon`}>
              <FontAwesomeIcon
                icon={icon({ style: 'regular', name: 'heart' })}
              />
            </span>
          </div>
        </div>
        <div className={`${CSSBlock}__location`}>
          <div className={`${CSSBlock}__location-top`}>
            <span className={`${CSSBlock}__icon`}>
              <FontAwesomeIcon
                icon={icon({ style: 'solid', name: 'location-dot' })}
              />
            </span>
            6.17 KM AWAY
          </div>
          <div className={`${CSSBlock}__location-address`}>
            {vendor?.address.addressLine1}, {vendor?.address.addressLine2},{' '}
            {vendor?.address.county}, {vendor?.address.postCode}
          </div>
        </div>
        <div className={`${CSSBlock}__search`}>
          <span className={`${CSSBlock}__icon`}>
            <FontAwesomeIcon
              icon={icon({ style: 'solid', name: 'magnifying-glass' })}
            />
          </span>
          Search for something
        </div>
      </div>
      <div className={`${CSSBlock}__categories`}>
        <div className={`${CSSBlock}__category`}>
          <span className={`${CSSBlock}__icon`}>
            <FontAwesomeIcon
              icon={icon({ style: 'light', name: 'broccoli' })}
            />
          </span>
          Category Name
        </div>
        <div className={`${CSSBlock}__category`}>
          <span className={`${CSSBlock}__icon`}>
            <FontAwesomeIcon icon={icon({ style: 'light', name: 'carrot' })} />
          </span>
          Category Name
        </div>
        <div className={`${CSSBlock}__category`}>
          <span className={`${CSSBlock}__icon`}>
            <FontAwesomeIcon icon={icon({ style: 'light', name: 'steak' })} />
          </span>
          Category Name
        </div>
      </div>
      <div className={`${CSSBlock}__section-title`}>Product Highlights</div>
      <div className={`${CSSBlock}__product-list`}>
        <div className={`${CSSBlock}__product`}>
          <div className={`${CSSBlock}__product-image`}>
            {productImages.length > 0 && (
              <AsyncImage
                src={
                  productImages[
                    Math.floor(Math.random() * productImages.length)
                  ]
                }
              />
            )}
          </div>
          <div className={`${CSSBlock}__product-name`}>Product Name</div>
          <div className={`${CSSBlock}__product-price`}>
            <span>
              {getCurrencySymbolFromCountryCode(
                vendor?.address?.countryCode ?? ''
              )}
            </span>
            0.30<span>/ each</span>
          </div>
          <div className={`${CSSBlock}__product-variant`}>4 variants</div>
        </div>
        <div className={`${CSSBlock}__product`}>
          <div className={`${CSSBlock}__product-image`}>
            {productImages.length > 0 && (
              <AsyncImage
                src={
                  productImages[
                    Math.floor(Math.random() * productImages.length)
                  ]
                }
              />
            )}
          </div>
          <div className={`${CSSBlock}__product-name`}>Product Name</div>
          <div className={`${CSSBlock}__product-price`}>
            <span>
              {getCurrencySymbolFromCountryCode(
                vendor?.address?.countryCode ?? ''
              )}
            </span>
            9.99<span>/ each</span>
          </div>
          <div className={`${CSSBlock}__product-variant`}>2 variants</div>
        </div>
        <div className={`${CSSBlock}__product`}>
          <div className={`${CSSBlock}__product-image`}>
            {productImages.length > 0 && (
              <AsyncImage
                src={
                  productImages[
                    Math.floor(Math.random() * productImages.length)
                  ]
                }
              />
            )}
          </div>
          <div className={`${CSSBlock}__product-name`}>Product Name</div>
          <div className={`${CSSBlock}__product-price`}>
            <span>
              {getCurrencySymbolFromCountryCode(
                vendor?.address?.countryCode ?? ''
              )}
            </span>
            9.99<span>/ each</span>
          </div>
          <div className={`${CSSBlock}__product-variant`}>
            85 grams per item
          </div>
        </div>
      </div>
      <div className={`${CSSBlock}__section-title`}>Reviews</div>
      <div className={`${CSSBlock}__reviews-list`}>
        <div className={`${CSSBlock}__review`}>
          <div className={`${CSSBlock}__review-title`}>Review Title</div>
          <div className={`${CSSBlock}__review-date`}>01/01/2023</div>
          <div className={`${CSSBlock}__review-rating`}>
            <span className={`${CSSBlock}__review-rating-icon`}>
              <FontAwesomeIcon
                icon={icon({ style: 'regular', name: 'face-awesome' })}
              />
            </span>
          </div>
          <div className={`${CSSBlock}__review-description`}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at
            dapibus nulla. Integer cursus justo quam, ac sagittis orci fringilla
            vitae onsectetur adipiscing elit. Mauris at dapibus
          </div>
        </div>
      </div>
      {/* <div className={`${CSSBlock}__button`}>View All Reviews</div> */}
    </div>
  );
};

export default DisplayShop;
