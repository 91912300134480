import React from 'react';

import AsyncImage from '@components/asyncImage/AsyncImage';
import { Product } from '@models/product.model';

export interface Props {
  product: Product;
  active?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

const InventorySidebarButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ product, active, disabled, onClick }, ref) => {
    const CSSBlock = 'sidebar-button';

    return (
      <button
        className={`${CSSBlock} ${CSSBlock}--inventory ${
          active ? `${CSSBlock}--active` : ``
        }`}
        disabled={disabled}
        onClick={onClick}
        ref={ref}>
        <span className={`${CSSBlock}__image`}>
          {product.images.length > 0 && (
            <AsyncImage src={product.images[0].thumbnail} />
          )}
        </span>
        <span className={`${CSSBlock}__text`}>{product.name}</span>
      </button>
    );
  }
);

export default InventorySidebarButton;
