import React from 'react';

import DemmiTimeline, { DemmiTimelineItem } from '@demmi-ui/Timeline';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getOrderVendorStatusText } from '@helpers/orderStatus.helper';
import { getOrderTimelineUpdatedByText } from '@helpers/orderTimeline.helper';
import { Order } from '@models/orders.model';

import OrderItemTabTimelineUpdate from './_tab-timeline-update';

interface Props {
  CSSBlock: string;
  order: Order;
  vendorID: string;
}

const OrderItemTabTimeline: React.FC<Props> = ({
  CSSBlock,
  vendorID,
  order,
}) => {
  const timelineItems: DemmiTimelineItem[] = order.timeline.map(update => ({
    ...(update.statusUpdate
      ? {
          title: `Order ${getOrderVendorStatusText(
            update.statusUpdate
          ).toLowerCase()}`,
        }
      : {}),
    date: update.timestamp,
    ...(update.text
      ? {
          text: update.text,
          textBadge: getOrderTimelineUpdatedByText(update.updatedBy),
        }
      : {}),
  }));

  return (
    <div className={`${CSSBlock}__timeline`}>
      <div className={`${CSSBlock}__title`}>
        <FontAwesomeIcon
          size={'lg'}
          icon={icon({ style: 'regular', name: 'timeline-arrow' })}
        />
        Order Timeline
      </div>
      <DemmiTimeline items={timelineItems} />
      <OrderItemTabTimelineUpdate
        CSSBlock={CSSBlock}
        vendorID={vendorID}
        order={order}
      />
    </div>
  );
};

export default OrderItemTabTimeline;
