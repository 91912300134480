import { format } from 'date-fns';
import React from 'react';

import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DemmiSupportChat,
  DemmiSupportMessage,
} from '@models/demmiSupport.model';

interface Props {
  CSSBlock: string;
  active: boolean;
  chat: DemmiSupportChat;
  onClickChat: (chat: DemmiSupportChat) => void;
  lastMessage?: DemmiSupportMessage;
}

const DemmiSupportPanelListItem: React.FC<Props> = ({
  CSSBlock,
  active,
  chat,
  onClickChat,
  lastMessage,
}) => {
  return (
    <div
      className={`${CSSBlock}__item ${
        active ? `${CSSBlock}__item--active` : ``
      }`}
      onClick={() => onClickChat(chat)}>
      <div className={`${CSSBlock}__item-text`}>{chat.title}</div>
      <div className={`${CSSBlock}__item-message`}>
        <FontAwesomeIcon
          size={'lg'}
          icon={icon({ style: 'regular', name: 'check-double' })}
        />
        {lastMessage ? lastMessage.text : '-'}
      </div>
      <div className={`${CSSBlock}__item-time`}>
        {lastMessage?.timestamp ? format(lastMessage.timestamp, 'HH:mm') : '-'}
      </div>
    </div>
  );
};

export default DemmiSupportPanelListItem;
