import React from 'react';

import { Order } from '@models/orders.model';
import { Vendor } from '@models/vendors.model';

import WidgetIncomingScheduleSlotItem from './WidgetIncomingScheduleSlotItem';

interface Props {
  vendor: Vendor;
  slot: TimeSlot;
  hasPassed?: boolean;
}
interface TimeSlot {
  value: number;
  name: string;
  orders: Order[];
}

const WidgetIncomingScheduleSlot: React.FC<Props> = ({
  vendor,
  slot,
  hasPassed,
}) => {
  const CSSBlock = 'incoming-schedule-slot';

  return (
    <div
      className={`${CSSBlock} ${hasPassed ? `${CSSBlock}--has-passed` : ''}`}>
      <div className={`${CSSBlock}__time`}>
        <div className={`${CSSBlock}__time-text`}>{slot.name}</div>
        <div className={`${CSSBlock}__time-line`}></div>
      </div>
      <div className={`${CSSBlock}__orders-list`}>
        {slot.orders.map((o, i) => (
          <WidgetIncomingScheduleSlotItem
            order={o}
            key={i}
            vendorID={vendor.id}
          />
        ))}
      </div>
    </div>
  );
};

export default WidgetIncomingScheduleSlot;
