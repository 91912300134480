import { Unsubscribe } from 'firebase/auth';
import React, { ReactElement, useEffect, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { listenToToastNotifications } from '@core/services/networkService/notifications/notifications';
import { selectVendor } from '@core/store/appSlice/appSlice';
import ReactPortal from '@demmi-ui/ReactPortal';
import DemmiToast, { DemmiToastType } from '@demmi-ui/Toasts/Toast';
import DemmiToastsArea from '@demmi-ui/Toasts/ToastsArea';
import { notificationToToast } from '@helpers/notification.helper';
import { FSNotificationDoc } from '@models/notifications.model';

interface Props {}

const ToastsArea: React.FC<Props> = ({}) => {
  const vendor = useAppSelector(selectVendor);
  const [notifications, setNotifications] = useState<FSNotificationDoc[]>([]);

  useEffect(() => {
    let snapshotListener: Unsubscribe | undefined;
    if (vendor) {
      const cb = (r: FSNotificationDoc[]) => {
        setNotifications(n => n.concat(r));
      };
      listenToToastNotifications(vendor.id, new Date(), cb).then(
        u => (snapshotListener = u)
      );
    }

    return () => {
      if (snapshotListener) snapshotListener();
    };
  }, [vendor]);

  const parseToToasts = (notifications: FSNotificationDoc[]) => {
    let toasts: Array<ReactElement<DemmiToastType>> = [];
    notifications.map(n => {
      const t = notificationToToast(n);
      if (t)
        toasts.push(
          <DemmiToast
            key={n.docID}
            id={n.docID}
            onDismissed={() => {
              setNotifications(notifications =>
                notifications.filter(c => c.docID !== n.docID)
              );
            }}
            title={t.title}
            body={t.body}
            autoDismiss={false}
          />
        );
    });
    return toasts;
  };

  return (
    <ReactPortal wrapperId="toasts-area">
      <DemmiToastsArea toasts={parseToToasts(notifications)}></DemmiToastsArea>
    </ReactPortal>
  );
};

export default ToastsArea;
