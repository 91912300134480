import React from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import DemmiInput from '@demmi-ui/Input';
import { stringToTime, timeToString } from '@helpers/date.helper';
import { VendorOpeningHours } from '@models/vendors.model';

import InputTime from '../../../demmi-ui/InputTime/InputTime';

interface Props {
  CSSBlock: string;
  hours: VendorOpeningHours;
  setHours: React.Dispatch<React.SetStateAction<VendorOpeningHours>>;
}

const Hours: React.FC<Props> = ({ CSSBlock, hours, setHours }) => {
  const TimeInput: React.FC<{
    value: number;
    onChange: (val: number) => void;
  }> = ({ value, onChange }) => {
    return (
      <DemmiInput
        type="time"
        placeholder="Close"
        value={timeToString(value)}
        onChangeValue={val => {
          onChange(stringToTime(val));
        }}
      />
    );
  };

  const Day = <K extends keyof VendorOpeningHours>(title: string, day: K) => (
    <DemmiCard className={`${CSSBlock}__details-item`}>
      <div className={`${CSSBlock}__details-title`}>{title}</div>
      <div className={`${CSSBlock}__details-hours`}>
        <div className={`${CSSBlock}__details-hours-item`}>
          <div className={`${CSSBlock}__details-hours-item-title`}>Open</div>

          <InputTime
            value={hours[day].open}
            onChange={val => {
              setHours({
                ...hours,
                [day]: {
                  ...hours[day],
                  open: val,
                },
              });
            }}
          />
        </div>
        <div className={`${CSSBlock}__details-hours-item`}>
          <div className={`${CSSBlock}__details-hours-item-title`}>Close</div>
          <InputTime
            value={hours[day].close}
            onChange={val => {
              setHours({
                ...hours,
                [day]: {
                  ...hours[day],
                  close: val,
                },
              });
            }}
          />
          {/* <TimeInput
            value={hours[day].close}
            onChange={val =>
              setHours({
                ...hours,
                [day]: {
                  ...hours[day],
                  close: val,
                },
              })
            }
          /> */}
        </div>
      </div>
    </DemmiCard>
  );

  return (
    <div className={`${CSSBlock}__days`}>
      {Day('Monday', 'mon')}
      {Day('Tuesday', 'tue')}
      {Day('Wednesday', 'wed')}
      {Day('Thursday', 'thu')}
      {Day('Friday', 'fri')}
      {Day('Saturday', 'sat')}
      {Day('Sunday', 'sun')}
    </div>
  );
};

export default Hours;
