import { DocumentData, Timestamp } from 'firebase/firestore';

export enum FSCollectionNames {
  PRODUCTS = 'products',
  VENDORS = 'vendors',
  VENDOR_USERS = 'vendor_users',
  USERS = 'users',
  ORDERS = 'orders',
  COUPONS = 'coupons',
  DEMMI = 'demmi',
  CATEGORIES = 'categories',
  REFUND_REQUEST = 'refund_requests',
  DEMMI_SUPPORT = 'demmi_support',
  NOTIFICATIONS = 'notifications_vendor',
}

export enum FSSubCollectionNames {
  VARIANTS = 'variants',
  REVIEWS = 'reviews',
  DEMMI_SUPPORT_MESSAGES = 'messages',
}

export const getDateFromFirestore = (
  jsonTimestamp?: DocumentData
): Date | undefined => {
  if (!jsonTimestamp) return;
  const date = new Timestamp(jsonTimestamp.seconds, jsonTimestamp.nanoseconds);
  return date.toDate();
};

export enum StorageBuckets {
  DEV_VENDOR_USERS = 'demmi-dev-vendors',
  DEV_PUBLIC = 'demmi-dev-public',
  PROD_VENDOR_USERS = 'demmi-prod-vendors',
  PROD_PUBLIC = 'demmi-prod-public',
}

export const getStorageBucketVendors = () =>
  process.env.IS_PRODUCTION
    ? StorageBuckets.PROD_VENDOR_USERS
    : StorageBuckets.DEV_VENDOR_USERS;
export const getStorageBucketPublic = () =>
  process.env.IS_PRODUCTION
    ? StorageBuckets.PROD_PUBLIC
    : StorageBuckets.DEV_PUBLIC;

export enum StoragePaths {
  PRODUCT_IMAGES = 'product_images',
  VENDOR_IMAGES = 'vendor_images',
}
