import { UserCredential } from 'firebase/auth';

import { VendorUser } from '@models/vendorUser.model';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface UserState {
  cred: UserCredential | undefined;
  vendorUser: VendorUser | undefined;
  offlineMode: boolean;
}

const initialState: UserState = {
  cred: undefined,
  vendorUser: undefined,
  offlineMode: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: builder => {
    builder.addCase('LOGOUT', state => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    clearCred: state => {},
    storeCred: (state, action: PayloadAction<UserCredential>) => {},
    setOfflineMode: (state, action: PayloadAction<boolean>) => {
      state.offlineMode = action.payload;
    },
    setVendorUser: (state, action: PayloadAction<VendorUser | undefined>) => {
      state.vendorUser = action.payload;
    },
  },
});

export const { clearCred, storeCred, setOfflineMode, setVendorUser } =
  userSlice.actions;

export const selectUserCred = (state: RootState) => state.user.cred;
export const selectOfflineMode = (state: RootState) => state.user.offlineMode;
export const selectVendorUser = (state: RootState) => state.user.vendorUser;

export default userSlice.reducer;
