import { DateTime } from 'luxon';
import React, { useEffect } from 'react';

import ModalEditCoupon from '@components/modals/EditCoupon/ModalEditCoupon';
import ModalDelete from '@components/modals/ModalDelete';
import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { getFSDemmi } from '@core/services/networkService/functions/_demmi';
import { selectFSDemmi, setFSDemmi } from '@core/store/appSlice/appSlice';
import { demmiRequest } from '@helpers/app.helper';
import { getCouponAmount, getCouponStatus } from '@helpers/coupon.helper';
import { getCurrencySymbolFromCountryCode } from '@helpers/currency.helper';
import { Coupon } from '@models/coupons.model';
import { Vendor } from '@models/vendors.model';

interface Props {
  vendor: Vendor;
  coupon: Coupon;
  onClickDelete: (code: string) => void;
  onUpdated: (coupon: Coupon) => void;
}

const CouponDetail: React.FC<Props> = ({
  vendor,
  coupon,
  onClickDelete,
  onUpdated,
}) => {
  const CSSBlock = 'coupon-detail';
  const dispatch = useAppDispatch();
  const fsDemmi = useAppSelector(selectFSDemmi);

  useEffect(() => {
    if (!fsDemmi) demmiRequest(getFSDemmi()).then(o => dispatch(setFSDemmi(o)));
  }, [fsDemmi]);

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__wrapper`}>
        <div className={`${CSSBlock}__wrapper-background`}></div>
        <div className={`${CSSBlock}__content`}>
          <div className={`${CSSBlock}__code`}>{coupon.code}</div>
          <div className={`${CSSBlock}__content-details`}>
            <div className={`${CSSBlock}__type`}>{coupon.type}</div>
            <div className={`${CSSBlock}__detail-split`}>
              {coupon.amount && (
                <div className={`${CSSBlock}__detail`}>
                  <div className={`${CSSBlock}__detail-key`}>
                    Discount Amount
                  </div>
                  <div className={`${CSSBlock}__detail-value`}>
                    {getCouponAmount(
                      getCurrencySymbolFromCountryCode(
                        vendor.address.countryCode
                      ),
                      coupon
                    )}
                  </div>
                </div>
              )}
              {coupon.minSpend !== null && coupon.minSpend > 0 && (
                <div className={`${CSSBlock}__detail`}>
                  <div className={`${CSSBlock}__detail-key`}>Minimum Spend</div>
                  <div className={`${CSSBlock}__detail-value`}>
                    {getCurrencySymbolFromCountryCode(
                      vendor.address.countryCode
                    )}
                    {coupon.minSpend}
                  </div>
                </div>
              )}
            </div>
            <div className={`${CSSBlock}__detail-split`}>
              {coupon.expirationDate && (
                <div className={`${CSSBlock}__detail`}>
                  <div className={`${CSSBlock}__detail-key`}>
                    Expiration Date
                  </div>
                  <div className={`${CSSBlock}__detail-value`}>
                    {DateTime.fromJSDate(
                      coupon.expirationDate
                    ).toLocaleString()}
                  </div>
                </div>
              )}
              {coupon.startDate && (
                <div className={`${CSSBlock}__detail`}>
                  <div className={`${CSSBlock}__detail-key`}>Start Date</div>
                  <div className={`${CSSBlock}__detail-value`}>
                    {DateTime.fromJSDate(coupon.startDate).toLocaleString()}
                  </div>
                </div>
              )}
            </div>
            <div className={`${CSSBlock}__status`}>
              {getCouponStatus(coupon)}
            </div>
          </div>
        </div>
      </div>
      <div className={`${CSSBlock}__buttons`}>
        <ModalEditCoupon
          onClose={() => {}}
          onConfirm={c => {
            // update details
            onUpdated(c);
          }}
          coupon={coupon}
        />

        <ModalDelete
          onClose={() => {}}
          onConfirm={() => onClickDelete(coupon.code)}
          name={coupon.code}
        />
      </div>
    </div>
  );
};

export default CouponDetail;
