import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SearchParams } from '@core/services/routingService';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import Menu from '@demmi-ui/Menu/Menu';
import MenuTriggerButton from '@demmi-ui/Menu/MenuTriggerButton';
import { SORT_ORDERS_BY, sortOrders } from '@helpers/orders.helper';
import { Order } from '@models/orders.model';

import OrderListItem from './OrderListItem';
import OrderItem from './orderItem/OrderItem';

interface Props {
  orders: Order[];
  onClickRefresh: () => void;
}

const OrdersList: React.FC<Props> = ({ orders, onClickRefresh }) => {
  const CSSBlock = 'orders-list';

  const [searchParams, setSearchParams] = useSearchParams();
  const [sortBy, setSortBy] = useState(SORT_ORDERS_BY.COLLECTION_TIME_ASC);
  const [sortedOrders, setSortedOrders] = useState(sortOrders(orders, sortBy));
  const [selectedOrder, setSelectedOrder] = useState<Order | undefined>();

  useEffect(() => {
    if (searchParams.get(SearchParams.ORDER_ID)) {
      setSelectedOrder(
        orders.find(o => o.id === searchParams.get(SearchParams.ORDER_ID))
      );
    } else {
      setSelectedOrder(undefined);
    }
  }, [orders, searchParams]);

  useEffect(() => {
    setSortedOrders(sortOrders(orders, sortBy));
  }, [orders, sortBy]);

  const getMenuContent = () => {
    return {
      sections: [
        {
          items: (Object.values(SORT_ORDERS_BY) as Array<SORT_ORDERS_BY>).map(
            c => ({
              id: c,
              text: c,
              item: c,
            })
          ),
        },
      ],
    };
  };

  const onClick = (order: Order) => {
    setSearchParams({ [SearchParams.ORDER_ID]: order.id });
    setSelectedOrder(order);
  };

  return (
    <>
      <div className={`${CSSBlock}`}>
        <div className={`${CSSBlock}__sort`}>
          <div className={`${CSSBlock}__sort-dropdown`}>
            <Menu<SORT_ORDERS_BY>
              content={getMenuContent()}
              triggerEl={<MenuTriggerButton title="Sort By" text={sortBy} />}
              onClick={sortBy => setSortBy(sortBy)}
            />
          </div>
          <DemmiIconButton
            faIcon={'fa-rotate-right'}
            onClick={onClickRefresh}
            size={IconButtonSize.LARGE}
          />
        </div>
        {sortedOrders.map((order, i) => (
          <OrderListItem
            active={selectedOrder?.id === order.id}
            order={order}
            key={i}
            onClick={onClick}
          />
        ))}
      </div>
      {selectedOrder && <OrderItem orderDocID={selectedOrder.id} />}
    </>
  );
};

export default OrdersList;
