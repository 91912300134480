import React, { useRef, useState } from 'react';

import DemmiButton from '@demmi-ui/Button';
import DemmiCardArea from '@demmi-ui/Modal/ModalArea';
import DemmiCardButtonArea from '@demmi-ui/Modal/ModalButtonArea';
import DemmiModalCore, { DemmiModalCoreRef } from '@demmi-ui/Modal/ModalCore';

interface Props {
  isPublished: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ModalPublish: React.FC<Props> = ({ isPublished, onClose, onConfirm }) => {
  const CSSBlock = 'modal-publish';
  const modalRef = useRef<DemmiModalCoreRef>(null);
  const [isOpen, setIsOpen] = useState(false);

  const gracefulExit = (postExit: () => void) => {
    modalRef.current ? modalRef.current.gracefulClose(postExit) : postExit();
    setTimeout(() => {
      setIsOpen(false);
    }, 500);
  };

  const buttons = [
    <DemmiButton
      key={0}
      text="Cancel"
      outlined
      onClick={() => gracefulExit(onClose)}
    />,
    <DemmiButton
      key={1}
      text={isPublished ? 'Unpublish' : 'Publish'}
      variant="save"
      onClick={() => gracefulExit(() => onConfirm())}
    />,
  ];

  return (
    <>
      {isPublished ? (
        <DemmiButton
          text="Unpublish"
          faIcon="fa-eye-slash"
          variant="delete"
          onClick={() => setIsOpen(true)}
        />
      ) : (
        <DemmiButton
          text="Publish"
          faIcon="fa-upload"
          variant="save"
          onClick={() => setIsOpen(true)}
        />
      )}
      <DemmiModalCore
        ref={modalRef}
        isOpen={isOpen}
        onExited={() => {
          setIsOpen(false);
          onClose();
        }}
        contentClassName={CSSBlock}
        allowBackdropClose={false}>
        <DemmiCardArea isDark>
          <div className={`${CSSBlock}__text`}>
            Are you sure you want to {isPublished ? 'unpublish' : 'publish'}{' '}
            this product?
          </div>
          <div className={`${CSSBlock}__description`}>
            Warning:{' '}
            {isPublished
              ? 'This product will no longer be visible to your customers.'
              : 'This product will become visible to your customers.'}
            .
          </div>
        </DemmiCardArea>
        <DemmiCardButtonArea buttons={buttons} />
      </DemmiModalCore>
    </>
  );
};

export default ModalPublish;
