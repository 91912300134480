import { Order } from '@models/orders.model';
import { RefundRequest } from '@models/refundRequest.model';

import { CouponTypes } from './coupon.helper';

/**
 * Refund Type
 */

export enum RefundType {
  FULL = 'full',
  FULL_VENDOR = 'full-vendor',
  PARTIAL_VENDOR = 'partial-vendor',
  UNKNOWN = '',
}

export const getRefundType = (type: string): RefundType => {
  const match = Object.entries(RefundType).find(([_, val]) => val === type);
  return match ? match[1] : RefundType.UNKNOWN;
};

export const getRefundTypeText = (type: RefundType): string => {
  switch (type) {
    case RefundType.FULL:
    case RefundType.FULL_VENDOR:
      return 'Full';
    default:
      return 'Partial';
  }
};

/**
 * Refund Statuses
 */

export enum RefundStatus {
  VENDOR_INITIATED = 'vendor-initiated',
  REQUESTED = 'requested',
  ACCEPTED = 'accepted',
  PROCESSED = 'processed',
  REJECTED = 'rejected',
  DISPUTED = 'disputed',
  UNKNOWN = '',
}

export const getRefundStatus = (status?: string): RefundStatus => {
  const match = Object.entries(RefundStatus).find(([_, val]) => val === status);
  return match ? match[1] : RefundStatus.UNKNOWN;
};

export const getRefundStatusText = (status: RefundStatus): string => {
  switch (status) {
    case RefundStatus.VENDOR_INITIATED:
      return 'Initiated by Vendor';
    case RefundStatus.REQUESTED:
      return 'Requested by Customer';
    case RefundStatus.ACCEPTED:
      return 'Accepted';
    case RefundStatus.PROCESSED:
      return 'Processed';
    case RefundStatus.REJECTED:
      return 'Rejected';
    case RefundStatus.DISPUTED:
      return 'Disputed';
    default:
      return '-';
  }
};

/**
 * Refund Updated By
 */

export enum RefundRequestUpdatedBy {
  CUSTOMER = 'customer',
  VENDOR_USER = 'vendor',
  DEMMI = 'demmi',
  UNKNOWN = '',
}

export const getRefundRequestUpdatedBy = (
  status?: string
): RefundRequestUpdatedBy => {
  const match = Object.entries(RefundRequestUpdatedBy).find(
    ([_, val]) => val === status
  );
  return match ? match[1] : RefundRequestUpdatedBy.UNKNOWN;
};

export const getRefundRequestUpdatedByText = (
  status: RefundRequestUpdatedBy
): string => {
  switch (status) {
    case RefundRequestUpdatedBy.CUSTOMER:
      return 'Customer';
    case RefundRequestUpdatedBy.VENDOR_USER:
      return 'You';
    case RefundRequestUpdatedBy.DEMMI:
      return 'Demmi';
    default:
      return 'Unknown';
  }
};

/**
 * Calculations
 */

interface RefundRequestCalculationBase {
  coupon?: {
    type: CouponTypes;
    amount: number;
  };
  totalRefund: number;
}
interface RefundRequestCalculationFull extends RefundRequestCalculationBase {
  products?: { [uID: string]: number }[];
}

interface RefundRequestCalculationPartial extends RefundRequestCalculationBase {
  products?: { [uID: string]: number }[];
}

type RefundRequestCalculation =
  | RefundRequestCalculationPartial
  | RefundRequestCalculationFull;

export const calculateRefundRequest = (
  request: RefundRequest,
  order: Order
) => {
  let value = 0;
  order.vendor.products.forEach(product => {
    value += product.price;
  });
  return `${value.toFixed(2)}`;
};

// export const calculateRefundVendorCoupon = (
//   vendor: FSOrderVendor,
//   selectedProducts: string[]
// ) => {
//   if (!hasCoupon(vendor)) return 0.0;
//   const coupon = vendor.coupon!;
//   if (coupon.type === CouponTypes.coupon_discount_fixed) {
//     return coupon.amount!;
//   }
//   if (coupon.type === CouponTypes.coupon_discount_percent) {
//     let value = 0.0;
//     selectedProducts.forEach(uID => {
//       const product = vendor.products.find(product => product.uID == uID);
//       if (product) value += product.price * coupon.amount!;
//     });
//     return value;
//   }
//   return 0.0;
// };

// double calculateRefundVendorCoupon(
//   OrderVendorModel orderVendor,
//   List<String> selectedProducts,
//   Function(String?) onUpdateMessage,
// ) {
//   if (!orderVendor.hasCoupon()) return 0.0;

//   OrderCoupon coupon = orderVendor.getCoupon()!;
//   if (coupon.getType() == CouponTypes.discount_fixed) {
//     onUpdateMessage('A fixed-rate discount was applied to this order.\nThis will be deducted automatically.');
//     return coupon.getAmount()!;
//     // if (selectedProducts.length == orderVendor.getProducts().length) {
//     //   // onUpdateMessage(null);
//     //   return coupon.getAmount()!;
//     // } else {
//     //   // onUpdateMessage('This deduction has been calculated as a percentage of your refund.');
//     //   return 0.0;
//     // }
//   }
//   if (coupon.getType() == CouponTypes.discount_percent) {
//     double value = 0.0;
//     selectedProducts.forEach((uID) {
//       OrderProductModel? product = orderVendor.getProducts().firstWhereOrNull((product) => product.getUID() == uID);
//       if (product != null) value += product.getPrice() * coupon.getAmount()!;
//     });
//     onUpdateMessage(null);
//     return value;
//   }
//   onUpdateMessage(null);
//   return 0.0;
// }
