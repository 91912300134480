import React, { useState } from 'react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from 'react-router-dom';

import LoadingIntro from '@components/loadingIntro/LoadingIntro';
import NotFound404 from '@pages/404/NotFound404';
import Analytics from '@pages/analytics/Analytics';
import Coupons from '@pages/coupons/Coupons';
import Inventory from '@pages/inventory/Inventory';
import Login from '@pages/login/Login';
import Orders from '@pages/orders/Orders';
import OrdersAll from '@pages/orders/OrdersAll';
import OrdersCompleted from '@pages/orders/OrdersCompleted';
import OrdersIncoming from '@pages/orders/OrdersIncoming';
import OrdersRefunded from '@pages/orders/OrdersRefunded';
import Reviews from '@pages/reviews/Reviews';
import SettingsCollectionSlots from '@pages/settings/CollectionSlots/SettingsCollectionSlots';
import SettingsDeliveryDays from '@pages/settings/DeliveryDays/SettingsDeliveryDays';
import SettingsDetails from '@pages/settings/Details/SettingsDetails';
import SettingsImages from '@pages/settings/Images/SettingsImages';
import SettingsLocation from '@pages/settings/Location/SettingsLocation';
import SettingsOpeningHours from '@pages/settings/OpeningHours/SettingsOpeningHours';
import Settings from '@pages/settings/Settings';
import SettingsTheme from '@pages/settings/Theme/SettingsTheme';

import { auth } from '../firebase';
import Home from '../pages/home/Home';
import AuthLayout from './AuthLayout';
import ProtectedRoute from './routeGuards/ProtectedRoute';
import UnprotectedRoute from './routeGuards/UnprotectedRoute';
import { RoutingViews } from './services/routingService';

const AppCore: React.FC = () => {
  const [isLoadingIntro, setIsLoadingIntro] = useState(true);
  const [isLoadingContent, setIsLoadingContent] = useState(false);

  // Necessary because auth is always null before initialising,
  // and Firebase doesn't return a promise for initialisation.
  const getUser = () =>
    new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
      }, reject);
    });

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        element={<AuthLayout />}
        loader={() => defer({ user: getUser() })}
        errorElement={<NotFound404 />}>
        <Route element={<UnprotectedRoute />}>
          <Route path="/" element={<Login />} />
        </Route>

        <Route path="/" element={<ProtectedRoute />}>
          <Route path={RoutingViews.HOME} element={<Home />} />
          <Route path={RoutingViews.INVENTORY} element={<Inventory />} />
          <Route path={RoutingViews.ANALYTICS} element={<Analytics />} />
          <Route path={RoutingViews.REVIEWS} element={<Reviews />} />
          <Route path={RoutingViews.COUPONS} element={<Coupons />} />

          <Route path={RoutingViews.ORDERS} element={<Orders />}>
            <Route
              path={RoutingViews.ORDERS_INCOMING}
              element={<OrdersIncoming />}
            />
            <Route
              path={RoutingViews.ORDERS_COMPLETED}
              element={<OrdersCompleted />}
            />
            <Route
              path={RoutingViews.ORDERS_REFUNDS}
              element={<OrdersRefunded />}
            />
            <Route path={RoutingViews.ORDERS_ALL} element={<OrdersAll />} />
          </Route>

          <Route path={RoutingViews.SETTINGS} element={<Settings />}>
            <Route
              path={RoutingViews.SETTINGS_DETAILS}
              element={<SettingsDetails />}
            />
            <Route
              path={RoutingViews.SETTINGS_IMAGES}
              element={<SettingsImages />}
            />
            <Route
              path={RoutingViews.SETTINGS_LOCATION}
              element={<SettingsLocation />}
            />
            <Route
              path={RoutingViews.SETTINGS_OPENING_HOURS}
              element={<SettingsOpeningHours />}
            />
            <Route
              path={RoutingViews.SETTINGS_COLLECTION_SLOTS}
              element={<SettingsCollectionSlots />}
            />
            <Route
              path={RoutingViews.SETTINGS_DELIVERY_SLOTS}
              element={<SettingsDeliveryDays />}
            />
            <Route
              path={RoutingViews.SETTINGS_THEME}
              element={<SettingsTheme />}
            />
          </Route>
        </Route>
      </Route>
    )
  );

  return (
    <>
      <RouterProvider router={router} />

      {/* Main app loading */}
      {/* <LoadingIntro
        onAnimStart={() => setIsLoadingContent(true)}
        onAnimEnd={() => {}}
      /> */}
      {isLoadingIntro && (
        <LoadingIntro
          onAnimStart={() => setIsLoadingContent(true)}
          onAnimEnd={() => setIsLoadingIntro(false)}
        />
      )}
    </>
  );
};

export default AppCore;
