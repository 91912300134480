import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiInput from '@demmi-ui/Input';
import DemmiModalArea from '@demmi-ui/Modal/ModalArea';
import DemmiTextArea from '@demmi-ui/TextArea';
import { NEW_PRODUCT_TEMP_ID } from '@helpers/products.helper';
import { Product } from '@models/product.model';
import ProductCategory from '@pages/inventory/productDetails/partials/Category';

import ModalCreateProductImage from './ModalCreateProductImage';

interface Props {
  CSSBlock: string;
  setProduct: React.Dispatch<React.SetStateAction<Product | undefined>>;
  selectedImage: File | undefined;
  setSelectedImage: React.Dispatch<React.SetStateAction<File | undefined>>;
}

const ModalCreateProductContent: React.FC<Props> = ({
  CSSBlock,
  setProduct,
  selectedImage,
  setSelectedImage,
}) => {
  const vendor = useAppSelector(selectVendor);
  const [name, setName] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [description, setDescription] = useState('');
  const [categoryID, setCategoryID] = useState<string | undefined>();

  useEffect(() => {
    setProduct(createProductModel());
  }, [name, categoryID, shortDescription, description]);

  const createProductModel = (): Product => ({
    id: NEW_PRODUCT_TEMP_ID,
    vendorID: vendor!.id,
    name,
    categoryID: categoryID ?? '',
    shortDescription,
    longDescription: description.split('\n'),
    images: [],
    tags: [],
    isArchived: false,
    isPublished: false,
  });

  return (
    <>
      <DemmiModalArea isDark>
        <div className={`${CSSBlock}__title`}>Create a new product</div>
        <div className={`${CSSBlock}__content`}>
          <DemmiCard>
            <DemmiInput
              type="text"
              placeholder="Product Name"
              value={name}
              onChangeValue={val => setName(val)}
            />
            <DemmiInput
              type="text"
              placeholder="Short Description"
              value={shortDescription}
              onChangeValue={val => setShortDescription(val)}
            />
            <DemmiTextArea
              placeholder="Product Description"
              value={description}
              onChange={val => setDescription(val)}
            />
          </DemmiCard>
        </div>
      </DemmiModalArea>
      <DemmiModalArea isDark>
        <div className={`${CSSBlock}__content`}>
          <ProductCategory
            categoryID={categoryID}
            setCategoryID={setCategoryID}
          />
        </div>
      </DemmiModalArea>
      <DemmiModalArea isDark>
        <div className={`${CSSBlock}__content`}>
          <ModalCreateProductImage
            CSSBlock={CSSBlock}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
          />
        </div>
      </DemmiModalArea>
    </>
  );
};

export default ModalCreateProductContent;
