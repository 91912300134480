import React, { HTMLInputTypeAttribute, forwardRef } from 'react';
import { DefaultInputComponentProps } from 'react-phone-number-input';

interface DemmiInputProps extends DefaultInputComponentProps {
  placeholder?: string;
  type: HTMLInputTypeAttribute;
  value?: string;
  disabled?: boolean;
  invert?: boolean;
  // error?: boolean;
  onChangeValue: (val: string) => void;
}

const DemmiInput: React.FC<DemmiInputProps> = forwardRef<
  any,
  DefaultInputComponentProps
>(
  (
    {
      placeholder,
      type,
      value,
      disabled,
      invert,
      // error,
      onChangeValue,
      ...props
    },
    ref
  ) => {
    const CSSBlock = 'demmi-ui-input';

    return (
      <input
        {...props}
        className={`${CSSBlock} ${invert ? `${CSSBlock}--invert` : ''}`}
        type={type}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        min={0}
        ref={ref}
        onChange={e => onChangeValue(e.target.value)}
      />
    );
  }
);

export default DemmiInput;
