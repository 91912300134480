import { FormatRelativeOptions, FormatRelativeToken, format } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';

import { FSCollectionDate } from '@models/orders.model';

export const timeToString = (time: number) => {
  if (time < 1440 && time > 0) {
    // const hour =
    //   Math.round(time / 60) < 10
    //     ? `0${Math.round(time / 60)}`
    //     : Math.round(time / 60);
    // const min = time % 60 < 10 ? `0${time % 60}` : time % 60;
    return `${formateTimeSegment(timeToHour(time))}:${formateTimeSegment(
      timeToMinute(time)
    )}`;
  } else return '00:00';
};

export const timeToHour = (time: number): number => {
  if (time < 1440 && time > 0) {
    return Math.floor(time / 60);
  }
  return 0;
};
export const timeToMinute = (time: number): number => {
  if (time < 1440 && time > 0) {
    return time % 60;
  }
  return 0;
};

export const formateTimeSegment = (time: number): string => {
  return time < 10 ? `0${time}` : time.toString();
};

export const stringToTime = (time: string) => {
  const [hours, mins] = time.split(':');
  return parseInt(hours) * 60 + parseInt(mins);
};

export const formatCollectionDate = (date: FSCollectionDate) => {
  return format(
    new Date(date.year, date.month - 1, date.day),
    'EE do MMM yyyy'
  );
};

const _formatRelativeLocale: { [key in FormatRelativeToken]: string } = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday at' HH:mm",
  today: "'Today at' HH:mm",
  tomorrow: "'Tomorrow' HH:mm",
  nextWeek: "'Next' eeee",
  other: 'dd MMM yyyy, HH:mm:ss',
};
// 'dd MMM yyyy, HH:mm:ss'

export const formatRelativeOptions: FormatRelativeOptions = {
  // eslint-disable-next-line
  locale: {
    ...enGB,
    formatRelative: (token: FormatRelativeToken): string =>
      _formatRelativeLocale[token],
  },
};
