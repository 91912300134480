import React from 'react';

interface Props {
  tabs: string[];
  activeTab: number;
  // setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  setActiveTab: (i: number) => void;
}
const DemmiCardTabs: React.FC<Props> = ({ tabs, activeTab, setActiveTab }) => {
  const CSSBlock = 'demmi-ui-card-tabs';

  return (
    <div className={`${CSSBlock}`}>
      {tabs.map((tab, i) => (
        <div
          className={`${CSSBlock}__tab ${
            activeTab === i ? `${CSSBlock}__tab--active` : ``
          }`}
          key={i}
          onClick={() => setActiveTab(i)}>
          {tab}
        </div>
      ))}
    </div>
  );
};

export default DemmiCardTabs;
