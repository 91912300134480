import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { getProducts } from '@core/services/networkService/functions/_product';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { demmiRequest } from '@helpers/app.helper';
import { Product } from '@models/product.model';

import DisplayProduct from './DisplayProduct';
import DisplayShop from './DisplayShop';

interface Props {
  hue?: number;
  product?: Product;
  app?: number;
}

let dateInterval: NodeJS.Timer;

const FakePhone: React.FC<Props> = ({ hue, product, app = 0 }) => {
  const CSSBlock = 'fake-phone';
  const vendor = useAppSelector(selectVendor);
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState('13:32');
  const [mod, setMod] = useState('');
  const [productImages, setProductImages] = useState<string[]>([]);

  useEffect(() => {
    setProductImages([]);
    if (vendor) {
      demmiRequest(getProducts(vendor.id)).then(f =>
        setProductImages(
          f.reduce((acc: string[], cur) => {
            return [
              ...acc,
              ...(cur.images.length > 0 ? [cur.images[0].main] : []),
            ];
          }, [])
        )
      );
    }
  }, [vendor]);

  useEffect(() => {
    setMod(`${CSSBlock}--animate`);
    setTimeout(() => setMod(''), 400);
  }, [hue]);

  return (
    <>
      <div
        className={`${CSSBlock} ${mod} ${CSSBlock}--isDarkMode ${
          hue && hue >= 60 && hue <= 180 ? `${CSSBlock}--light` : ''
        }`}>
        <div className={`${CSSBlock}__display`}>
          <div className={`${CSSBlock}__status-bar`}>
            <div className={`${CSSBlock}__status-bar-left`}>
              {time}
              <span className={`${CSSBlock}__icon`}>
                <FontAwesomeIcon
                  icon={icon({ style: 'brands', name: 'whatsapp' })}
                />
              </span>
              <span className={`${CSSBlock}__icon`}>
                <FontAwesomeIcon
                  icon={icon({ style: 'brands', name: 'instagram' })}
                />
              </span>
            </div>
            <div className={`${CSSBlock}__status-bar-right`}>
              <span className={`${CSSBlock}__icon`}>
                <FontAwesomeIcon
                  icon={icon({ style: 'solid', name: 'wifi' })}
                />
              </span>
              <span className={`${CSSBlock}__icon`}>
                <FontAwesomeIcon
                  icon={icon({ style: 'solid', name: 'signal' })}
                />
              </span>
              <span className={`${CSSBlock}__icon`}>
                <FontAwesomeIcon
                  icon={icon({ style: 'solid', name: 'battery-half' })}
                />
              </span>
              55%
            </div>
          </div>
          <div className={`${CSSBlock}__app`}>
            {app == 0 && <DisplayShop productImages={productImages} />}
            {app == 1 && (
              <DisplayProduct
                productImages={productImages}
                product={product}
                vendor={vendor!}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FakePhone;
