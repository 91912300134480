import React from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VendorReview } from '@models/vendor-review.model';

interface Props {
  review: VendorReview;
}

const WidgetReviewItem: React.FC<Props> = ({ review }) => {
  const CSSBlock = 'widget-review-item';

  return (
    <DemmiCard>
      <div className={CSSBlock}>
        <div className={`${CSSBlock}__header`}>
          <div className={`${CSSBlock}__timestamp`}>
            Reviewed on:
            <span>{review.timestamp?.toDateString()}</span>
          </div>
          <div className={`${CSSBlock}__rating`}>
            {[...Array(5)].map((_, i) => (
              <FontAwesomeIcon
                key={i}
                icon={
                  review!.rating * 5 < i
                    ? icon({ style: 'regular', name: 'star' })
                    : icon({ style: 'solid', name: 'star' })
                }
              />
            ))}
          </div>
        </div>

        <div className={`${CSSBlock}__review`}>
          <div className={`${CSSBlock}__review-title`}>{review.title}</div>
          <div className={`${CSSBlock}__review-description`}>
            {review.body.map((paragraph, i) => (
              <div
                className={`${CSSBlock}__review-description-paragraph`}
                key={i}>
                {paragraph}
              </div>
            ))}
          </div>
        </div>
      </div>
    </DemmiCard>
  );
};

export default WidgetReviewItem;
