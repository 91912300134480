import {
  DocumentData,
  QueryDocumentSnapshot,
  Unsubscribe,
  arrayUnion,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';

import { FSOrder, Order, OrderTimelineUpdate } from '@models/orders.model';
import {
  FSOrderReview,
  FSOrderReviewResponse,
} from '@models/vendor-review.model';
import { FSCollections } from '@providers/firestoreProvider';
import { DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { FSSubCollectionNames, getDateFromFirestore } from '../networkService';
import { hasRefundRequest } from '../refundRequests/refundRequests';
import { parseToOrder } from './_helper';
import {
  orderQuery,
  orderReviewsVendorQuery,
  ordersVendorQuery,
} from './_queries';

export const listenToOrders = async (
  vendorID: string,
  callback: (orders: Order[]) => void
): Promise<Unsubscribe> => {
  return onSnapshot(ordersVendorQuery(vendorID), querySnapshot => {
    let orders: Order[] = [];
    querySnapshot.forEach((doc: QueryDocumentSnapshot<FSOrder>) => {
      orders.push(parseToOrder(vendorID, doc));
    });
    callback(orders);
  });
};

export const getOrders = async (vendorID: string): Promise<Order[]> => {
  Logger({ objs: { vendorID } }, getOrders);
  const querySnapshot = await getDocs(ordersVendorQuery(vendorID));
  let orders: Order[] = [];

  querySnapshot.forEach(async (doc: QueryDocumentSnapshot<FSOrder>) => {
    orders.push(parseToOrder(vendorID, doc));
  });

  return orders;
};

export const getOrdersWithRefunds = async (
  vendorID: string
): Promise<Order[]> => {
  const querySnapshot = await getDocs(ordersVendorQuery(vendorID));
  let orders: Order[] = [];
  const refundRequests: { [key: string]: Promise<string | undefined> } = {};

  querySnapshot.forEach((doc: QueryDocumentSnapshot<FSOrder>) => {
    refundRequests[doc.id] = hasRefundRequest(doc.id, vendorID);
  });

  await Promise.all(Object.values(refundRequests)).then(refunds => {
    querySnapshot.forEach(async (doc: QueryDocumentSnapshot<FSOrder>) => {
      const refundIndex = Object.keys(refundRequests).indexOf(doc.id);
      if (refunds[refundIndex]) {
        orders.push(parseToOrder(vendorID, doc));
      }
    });
  });

  return orders;
};

export const listenToOrder = async (
  orderDocID: string,
  vendorID: string,
  callback: (order?: Order) => void
): Promise<Unsubscribe> => {
  return onSnapshot(orderQuery(orderDocID), async querySnapshot => {
    if (querySnapshot.exists()) {
      callback(parseToOrder(vendorID, querySnapshot));
    } else {
      Logger(
        {
          messages: ['No such document!'],
          objs: { orderDocID, vendorID },
          type: DemmiLogType.error,
        },
        listenToOrder
      );
      callback(undefined);
    }
  });
};

export const listenToOrderReview = async (
  orderID: string,
  vendorID: string,
  callback: (review?: FSOrderReview) => void
): Promise<Unsubscribe | undefined> => {
  const docSnap = await getDoc(doc(FSCollections.Orders, orderID));
  if (!docSnap.exists()) {
    return;
  }
  return onSnapshot(
    orderReviewsVendorQuery(vendorID, docSnap.data().orderID),
    async querySnapshot => {
      if (querySnapshot.empty) {
        callback(undefined);
      } else {
        const review = querySnapshot.docs[0].data();
        review.id = querySnapshot.docs[0].id;
        review.timestamp = getDateFromFirestore(
          querySnapshot.docs[0].data()['timestamp'] as DocumentData
        );
        callback(review);
      }
    }
  );
};

export const updateOrderReviewResponse = async (
  vendorID: string,
  reviewID: string,
  response: FSOrderReviewResponse
): Promise<any> => {
  const docRef = doc(
    FSCollections.OrderReviews([vendorID, FSSubCollectionNames.REVIEWS]),
    reviewID
  );
  return updateDoc(docRef, {
    response: response,
  });
};

export const updateOrderTimeline = async (
  orderID: string,
  update: OrderTimelineUpdate
): Promise<void> => {
  const order = await getDoc(orderQuery(orderID));
  if (!order) {
    Logger(
      {
        messages: ['Failed to find order to push update.'],
        objs: { orderID, update },
        type: DemmiLogType.error,
      },
      updateOrderTimeline
    );
    return;
  }

  const docRef = doc(FSCollections.Orders, orderID);
  return updateDoc(docRef, {
    timeline: arrayUnion(update),
  });
};
