import React from 'react';

import { Order } from '@models/orders.model';

import OrderProductItem from '../../partials/_order-product-item';

interface Props {
  CSSBlock: string;
  productIDs: string[];
  order: Order;
}

const OrderItemTabRefundProducts: React.FC<Props> = ({
  CSSBlock,
  productIDs,
  order,
}) => {
  return (
    <div className={`${CSSBlock}__products-list`}>
      {productIDs.map((id, i) => {
        const orderProduct = order.vendor.products.find(p => p.uID === id);
        return orderProduct ? (
          <OrderProductItem product={orderProduct} key={i} size="S" listView />
        ) : null;
      })}
    </div>
  );
};

export default OrderItemTabRefundProducts;
