import { Unsubscribe } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { listenToNotifications } from '@core/services/networkService/notifications/notifications';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiIconButton from '@demmi-ui/IconButton';
import { FSNotificationDoc } from '@models/notifications.model';
import { Logger } from '@subhanhabib/demmilib';

import NotificationsPanel from './NotificationsPanel';

const Notifications: React.FC = () => {
  const CSSBlock = 'notifications';

  const vendor = useAppSelector(selectVendor);

  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState<FSNotificationDoc[]>([]);

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    let snapshotListener: Unsubscribe | undefined;
    if (vendor) {
      const cb = (notifications: FSNotificationDoc[]) => {
        setNotifications(notifications);
        Logger({ objs: { notifications } }, Notifications);
      };
      listenToNotifications(vendor.id, cb).then(u => (snapshotListener = u));
    }

    return () => {
      if (snapshotListener) snapshotListener();
    };
  }, [vendor]);

  return (
    <>
      <span className={`${CSSBlock}__icon`}>
        <DemmiIconButton
          faIcon="fa-regular fa-bell"
          onClick={() => setIsOpen(true)}
        />
        {notifications.filter(n => !n.read).length > 0 && (
          <span className={`${CSSBlock}__icon-indicator`}>
            {notifications.filter(n => !n.read).length}
          </span>
        )}
      </span>
      <NotificationsPanel
        isOpen={isOpen}
        notifications={notifications}
        onExited={() => {
          setIsOpen(false);
          // onClose();
        }}
      />
    </>
  );
};

export default Notifications;
