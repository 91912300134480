import React, { useRef, useState } from 'react';

import DemmiButton from '@demmi-ui/Button';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import DemmiCardButtonArea from '@demmi-ui/Modal/ModalButtonArea';
import DemmiModalCore, { DemmiModalCoreRef } from '@demmi-ui/Modal/ModalCore';

import ModalAddImageContent from './ModalAddImageContent';

interface Props {
  disabled?: boolean;
  onClose: () => void;
  onConfirm: (file: File) => void;
}

const ModalAddImage: React.FC<Props> = ({ disabled, onClose, onConfirm }) => {
  const CSSBlock = 'modal-image';
  const modalRef = useRef<DemmiModalCoreRef>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | undefined>();

  const gracefulExit = (postExit: () => void) => {
    modalRef.current ? modalRef.current.gracefulClose(postExit) : postExit();
    setTimeout(() => {
      setIsOpen(false);
      setSelectedImage(undefined);
    }, 500);
  };

  const buttons = [
    <DemmiButton
      key={0}
      text="Cancel"
      outlined
      onClick={() => gracefulExit(onClose)}
    />,
    <DemmiButton
      key={1}
      text="Confirm"
      disabled={!selectedImage}
      onClick={() => gracefulExit(() => onConfirm(selectedImage!))}
    />,
  ];

  return (
    <>
      <DemmiIconButton
        disabled={disabled}
        faIcon="fa-plus"
        size={IconButtonSize.SMALL}
        onClick={() => setIsOpen(true)}
      />
      <DemmiModalCore
        ref={modalRef}
        isOpen={isOpen}
        onExited={() => {
          setIsOpen(false);
          onClose();
        }}
        contentClassName={CSSBlock}
        allowBackdropClose={false}>
        <ModalAddImageContent
          CSSBlock={CSSBlock}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
        />
        <DemmiCardButtonArea buttons={buttons} />
      </DemmiModalCore>
    </>
  );
};

export default ModalAddImage;
