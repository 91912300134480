import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectVendor } from '@core/store/appSlice/appSlice';

interface Props {}

const Analytics: React.FC<Props> = ({}) => {
  const CSSBlock = 'analytics';
  const vendor = useAppSelector(selectVendor);

  return <div className={`${CSSBlock}`}></div>;
};

export default Analytics;
