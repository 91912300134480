import {
  DocumentData,
  QueryDocumentSnapshot,
  Unsubscribe,
  getDocs,
  onSnapshot,
} from 'firebase/firestore';

import { FSUserDoc } from '@models/customer.model';
import { FSOrderReview, VendorReview } from '@models/vendor-review.model';

import { FSCustomer } from '../customer/_customer';
import { getReviewCustomerFromRequests, parseToVendorReview } from './_helper';
import { vendorReviewsQuery, vendorReviewsQueryLimited } from './_queries';

export const listenToReviews = async (
  vendorID: string,
  callback: (reviews: VendorReview[]) => void,
  limit?: number
): Promise<Unsubscribe> => {
  const ref =
    limit === undefined
      ? vendorReviewsQuery(vendorID)
      : vendorReviewsQueryLimited(vendorID, limit);

  return onSnapshot(ref, async querySnapshot => {
    let reviews: VendorReview[] = [];
    const customerRequests: { [key: string]: Promise<FSUserDoc | undefined> } =
      {};

    querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
      if (
        !customerRequests[doc.data()['customerID']] &&
        doc.data()['customerID']
      ) {
        customerRequests[doc.data()['customerID']] = FSCustomer.doc.getByDocID(
          doc.data()['customerID']
        );
      }
    });

    await Promise.all(Object.values(customerRequests)).then(customers => {
      querySnapshot.forEach(
        async (doc: QueryDocumentSnapshot<FSOrderReview>) => {
          reviews.push(
            parseToVendorReview(
              doc,
              getReviewCustomerFromRequests(
                customerRequests,
                customers,
                doc.data()['customerID']
              )
            )
          );
        }
      );
    });

    callback(reviews);
  });
};

export const getAllReviews = async (
  vendorID: string
): Promise<VendorReview[]> => {
  const querySnapshot = await getDocs(
    vendorReviewsQuery(vendorID)
    // FSCollections.OrderReviews([vendorID, FSSubCollectionNames.REVIEWS]) // only if this vendor exists
  );

  let reviews: VendorReview[] = [];
  const customerRequests: { [key: string]: Promise<FSUserDoc | undefined> } =
    {};

  querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
    if (
      !customerRequests[doc.data()['customerID']] &&
      doc.data()['customerID']
    ) {
      customerRequests[doc.data()['customerID']] = FSCustomer.doc.getByDocID(
        doc.data()['customerID']
      );
    }
  });

  await Promise.all(Object.values(customerRequests)).then(customers => {
    querySnapshot.forEach(async (doc: QueryDocumentSnapshot<FSOrderReview>) => {
      reviews.push(
        parseToVendorReview(
          doc,
          getReviewCustomerFromRequests(
            customerRequests,
            customers,
            doc.data()['customerID']
          )
        )
      );
    });
  });
  return reviews;
};
