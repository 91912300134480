import React, { useEffect, useState } from 'react';

import AsyncImage from '@components/asyncImage/AsyncImage';
import { useAppSelector } from '@core/hooks/appHooks';
import { selectFSDemmi } from '@core/store/appSlice/appSlice';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIconFromCategoryID } from '@helpers/categories.helper';
import { getCurrencySymbolFromCountryCode } from '@helpers/currency.helper';
import { Category } from '@models/demmi.model';
import { Product } from '@models/product.model';
import { Vendor } from '@models/vendors.model';

const fakeProduct: Product = {
  id: '',
  vendorID: '',
  name: 'Product Name',
  categoryID: '',
  shortDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at dapibus nulla. Integer cursus justo quam, ac sagittis orci fringilla vitae.`,
  longDescription: [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at dapibus nulla. Integer cursus justo quam, ac sagittis orci fringilla vitae. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at dapibus nulla. Integer cursus justo quam, ac sagittis orci fringilla vitae.',
    'Mauris at dapibus nulla. Integer cursus justo quam, ac sagittis orci fringilla vitae. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at dapibus nulla. Integer cursus justo quam, ac sagittis orci fringilla vitae.',
  ],
  images: [],
  tags: [],
};

const getVendorAddress = (vendor: Vendor) => {
  let address = '';
  if (vendor.address.addressLine1) address += vendor.address.addressLine1;
  if (vendor.address.addressLine2)
    address += ', ' + vendor.address.addressLine2;
  if (vendor.address.town) address += ', ' + vendor.address.town;
  if (vendor.address.county) address += ', ' + vendor.address.county;
  if (vendor.address.postCode) address += ', ' + vendor.address.postCode;
  return address;
};

interface Props {
  productImages: string[];
  product?: Product;
  vendor: Vendor;
}

const DisplayProduct: React.FC<Props> = ({
  productImages,
  product,
  vendor,
}) => {
  const CSSBlock = 'display-product';
  const fsDemmi = useAppSelector(selectFSDemmi);
  const [visibleProduct, setVisibleProduct] = useState(product ?? fakeProduct);
  const [categoryIcon, setCategoryIcon] = useState<IconDefinition>(
    getIconFromCategoryID(fsDemmi?.constants.categories, product?.categoryID)
  );

  useEffect(() => {
    setVisibleProduct(product ?? fakeProduct);
  }, [product]);

  useEffect(() => {
    setCategoryIcon(
      getIconFromCategoryID(fsDemmi?.constants.categories, product?.categoryID)
    );
  }, [product, fsDemmi]);

  const getCategory = () => {
    if (fsDemmi?.constants.categories) {
      let cat: Category | undefined;
      fsDemmi.constants.categories.forEach(c =>
        c.children?.forEach((x: Category) => {
          if (x.id === product?.categoryID) cat = x;
        })
      );
      if (cat) return cat.name;
    }
    return 'Product Category';
  };

  return (
    <div className={`${CSSBlock}__app`}>
      <div className={`${CSSBlock}__header`}>
        <div className={`${CSSBlock}__title`}>
          <span className={`${CSSBlock}__icon`}>
            <FontAwesomeIcon
              icon={icon({ style: 'solid', name: 'chevron-left' })}
            />
          </span>
          {visibleProduct.name}
          <span className={`${CSSBlock}__icon`}>
            <FontAwesomeIcon
              icon={icon({ style: 'solid', name: 'basket-shopping' })}
            />
          </span>
        </div>
      </div>
      <div className={`${CSSBlock}__content`}>
        <div className={`${CSSBlock}__gallery`}>
          <div className={`${CSSBlock}__gallery-images`}>
            <div className={`${CSSBlock}__gallery-image`}>
              {productImages.length > 0 && (
                <AsyncImage
                  src={
                    visibleProduct.images.length > 0
                      ? visibleProduct.images[0].main
                      : productImages[
                          Math.floor(Math.random() * productImages.length)
                        ]
                  }
                />
              )}
            </div>
          </div>
          <div className={`${CSSBlock}__gallery-toolbar`}>
            <div
              className={`${CSSBlock}__gallery-toolbar-indicator`}
              style={{
                width: `${
                  visibleProduct.images.length > 0
                    ? (1 / visibleProduct.images.length) * 100
                    : 24
                }%`,
              }}></div>
          </div>
        </div>
        <div className={`${CSSBlock}__card ${CSSBlock}__card-location`}>
          {getVendorAddress(vendor)}
          <span className={`${CSSBlock}__icon`}>
            <FontAwesomeIcon
              icon={icon({ style: 'solid', name: 'location-dot' })}
            />
          </span>
        </div>
        <div className={`${CSSBlock}__card`}>
          <div className={`${CSSBlock}__category`}>
            <span className={`${CSSBlock}__icon`}>
              <FontAwesomeIcon icon={categoryIcon} />
            </span>
            {getCategory()}
          </div>
          <div className={`${CSSBlock}__card-description`}>
            {visibleProduct.shortDescription}
          </div>
        </div>
        <div className={`${CSSBlock}__card`}>
          <div className={`${CSSBlock}__card-title`}>About This Product</div>
          <div className={`${CSSBlock}__card-description`}>
            {visibleProduct.longDescription.map((desc, i) => (
              <span key={i}>{desc}</span>
            ))}
          </div>
        </div>
        <div className={`${CSSBlock}__button-wrapper`}>
          <div className={`${CSSBlock}__button`}>
            <div className={`${CSSBlock}__button-left`}>
              <span>
                {getCurrencySymbolFromCountryCode(
                  vendor?.address?.countryCode ?? ''
                )}
              </span>
              1.19
              <span>per unit</span>
            </div>
            <div className={`${CSSBlock}__button-right`}>Add to basket</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayProduct;
