import React, { useRef } from 'react';

import DemmiModalArea from '@demmi-ui/Modal/ModalArea';

interface Props {
  CSSBlock: string;
  selectedImage: File | undefined;
  setSelectedImage: React.Dispatch<React.SetStateAction<File | undefined>>;
}

const ModalAddImageContent: React.FC<Props> = ({
  CSSBlock,
  selectedImage,
  setSelectedImage,
}) => {
  const previewRef = useRef<HTMLImageElement>(null);

  return (
    <DemmiModalArea isDark>
      <div className={`${CSSBlock}__upload-area`}>
        <div
          className={`${CSSBlock}__image-wrapper ${
            selectedImage ? `${CSSBlock}__image-wrapper--active` : ''
          }`}>
          <img ref={previewRef} src="#" />
        </div>
        <div
          className={`${CSSBlock}__selected ${
            selectedImage ? `${CSSBlock}__selected--active` : ''
          }`}>
          {selectedImage ? selectedImage.name : 'No image selected'}
        </div>
        <label className={`demmi-ui-input-file-button`}>
          Select a File
          <input
            type="file"
            onChange={event => {
              if (event.target.files) {
                setSelectedImage(event.target.files[0]);
                previewRef.current!.src = URL.createObjectURL(
                  event.target.files[0]
                );
              }
            }}
          />
        </label>
      </div>
    </DemmiModalArea>
  );
};

export default ModalAddImageContent;
