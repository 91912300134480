import React, { useEffect, useState } from 'react';

import DemmiInput from '@demmi-ui/Input';
import DemmiCardArea from '@demmi-ui/Modal/ModalArea';
import { useAuth } from '@providers/authProvider';

interface RegisterCardProps {}

const RegisterCard: React.FC<RegisterCardProps> = ({}) => {
  const CSSBlock = 'authentication';
  const { signIn, createUser } = useAuth();
  const [isVisible, setIsVisible] = useState(false);

  const email = 'subreh@gmail.com';
  const password = '123456';

  const onSubmit = () => {
    try {
      signIn(email, password);
      // storeCred(cred);
    } catch (err) {}
  };

  useEffect(() => {
    setIsVisible(true);
    return () => setIsVisible(false);
  }, []);

  return (
    <div className={`${CSSBlock}`}>
      <DemmiCardArea>
        <div className={`${CSSBlock}__content`}>
          <div className={`${CSSBlock}__title`}>Demmi Registration</div>
          <div className={`${CSSBlock}__description`}>
            Enter your credentials below.
          </div>
          {/* <DemmiInput type="email" placeholder="Enter your email" />
            <DemmiInput type="password" placeholder="Enter your password" />
            <DemmiInput type="password" placeholder="Confirm your password" /> */}
        </div>
      </DemmiCardArea>
    </div>
  );
};

export default RegisterCard;
